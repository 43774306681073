import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { retry } from 'rxjs/operators';
import { environment } from '../../../environments/environment';


import { ApiServiceService } from '../api-service.service';

@Injectable({
  providedIn: 'root'
})
export class OwnedDocsService {
  createdBy: any;
  workSpaceId: any;

  public baseURL=environment.baseUrl;
  public baseOrigin = environment.baseOrigin;
  constructor(private apiService:ApiServiceService,
    private http: HttpClient) { 
    this.workSpaceId = localStorage.getItem('localwsId')
    this.createdBy = localStorage.getItem('localloginId')
    }

  
  public showEdit:boolean;
  public showDownload:boolean;
  public showDelete:boolean;
  public showView: boolean;
  public showPrint: boolean;  


  
  // Access view, edit etc on the basis of roles
  viewUserWiseRoleAccess(data){
    return this.apiService.post( '/api/GetUserRoleAccess/UserWiseRoleAccess', data);
  }

  // Document format service

  documentFormatService(data) {
    let headers = new Headers();
  
    headers.append('Content-type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Access-Control-Allow-Origin', this.baseOrigin);
    headers.append('Access-Control-Allow-Credentials', 'true');
    headers.append('GET', 'POST');
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': this.baseOrigin }) };
    return this.http.get(this.baseURL + '/api/RandomDocumentUpload/GetDocFormat ').pipe(
      retry(3)
    );
  }
  getSharedWithModalDataForView(data) {
    return this.apiService.post('/api/GetUserDetailWithGroup/GetUserDetailWithGroup', data);
  }
  deleteAccessMultipleService(data) {
    return this.apiService.post('/api/GetMultipleUserRoleAccess/UserMultipleRoleAccess', data);
  }

  SizeTrashStatusService(data) {
    return this.apiService.post('/api/Trash/TrashSizeCount', data);
  }

    //Api for empty trash

    updateTrashService(data) {
      return this.apiService.post('/api/Trash/UpdateDocTrashStatus', data);
    }

    getaccessrights(data) {
      return this.apiService.post('/api/Workspace/MenuRights', data);
    }

  getFilterList(data) {
    return this.apiService.post('/api/Workspace/GetFilterList', data);
  }
    // For transfer ownership
  transferOwnershipService(data) {
    return this.apiService.post('/api/OwnedByMe/TransferDocOwner', data);
  }

  // Get table docs List
  getOwnedByMeDocListData(data) {
    return this.apiService.post('/api/OwnedByMe/OwnedByMeDocList', data);
  }

  //  UploadRightsUserList start here 
  UploadRightsUserListService(data) {
    return this.apiService.post('/api/RandomDocumentUpload/UploadRightsUserList', data);
  }
  tagTemplateService(data) {
    return this.apiService.post('/api/DocumentVerification/TemplateList', data);
  }
  getDocket(data) {
    return this.apiService.post('/api/docItTypeMaster/GetdocItTypeMaster', data);
  }
    // Get table docs List
    GetResponseList(data) {
      return this.apiService.post('/api/docItTypeMaster/GetResponseList', data);
    }
    CreateResponse(data) {
      return this.apiService.post('/api/docItTypeMaster/CreateResponse', data);
     }
     CreateIntegrationApi(data) {
      return this.apiService.post('/api/Workspace/CreateIntegrationApi', data);
     }
     GetIntegrationApiList(data) {
      return this.apiService.post('/api/Workspace/GetIntegrationApiList', data);
     }  
     UserMappingIntegrationApi(data) {
      return this.apiService.post('/api/Workspace/UserBulkMappingIntegrationApi', data);
     }
     searchAllUsersService(data) {
      return this.apiService.post('/api/AllUsers/Search', data);
     }
     
  /**
 * method  for view Docs AuditLog
 */
  viewDocsAuditLog(data) {
    return this.apiService.post('/api/Auditlog/InsertAuditLog', data);
  }
       /**
 * method  for elastic Update
 */
       elasticUpdate(data) {
        return this.apiService.post('/api/DocumentVerification/AfterVerificationElasticUpdateDocDetails', data);
       }
       /**
 *  method For list of all shared docs 
 */
allWsDocListService(data) {
  return this.apiService.post('/api/AllWorkspaceDocument/AllWSDocit', data);
}

/**
 *  method For unique docket name
 */
createUniqueDocketName(data) {
   return this.apiService.post('/api/Dockettype/CreateDocketUniquename',data);
}

/**
 *  method to check docket's document external link 
 */

checkDocketDoclink(data){
  return this.apiService.post('/api/Dockettype/CheckDocketDoclink', data);
}
}
 