/*************************************************************************
 *
 * PRIVET VENTURES - CONFIDENTIAL
 * __________________
 *
 * [2020] - [2022] Privet Ventures Pvt Ltd, India
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Privet Ventures Pvt Ltd and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Privet Ventures Pvt Ltd
 * and its suppliers and may be covered by Indian and Foreign Patents,
 * patents in process, Indian Patents Act 1970, and are protected by secret
 * trade or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Privet Ventures Pvt Ltd.
 */
///-----------------------------------------------------------------
///  Class      : response  Component
///  Description: connect with d_list component
///  Owner      : Sumeet Goel
///  Author     : Piyush Ranjan
///  Created On : 13-12-2022
///  Notes      : <Notes>
///  Revision History:
///  Name                Date.            Description
///
///------------------------------------------------------------------->
import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  ElementRef,
  Renderer2,
  HostListener,
  Injectable,
  Input,
  ChangeDetectorRef,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ApiIntegrationService } from "../../pages/api-integration.service";
import { DetailAndActivityService } from "../../../app/@core/utils/detail-and-activity.service";
import { SharedService } from "../../../app/@core/utils/shared.service";
import * as mdl from "@aspnet/signalr";
import { VerifyDocumentService } from "../../@core/verify-document.service";
import { NbDialogRef, NbDialogService,NbToastrService } from "@nebular/theme";
import { DatePipe, Location } from "@angular/common";
import { Router } from "@angular/router";
import { FormControl } from "@angular/forms";
import { of } from "rxjs";
import { SharedAccessComponent } from "../../../../src/app/shared/shared-access/shared-access.component";
import { UploadComponent } from "../../../../src/app/pages/random-upload/upload/upload.component";
import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { BulkTagComponent } from "../../shared/bulk-tag/bulk-tag.component";
import { SharedServiceService } from "../../shared-service.service";
import { DetailAndActivityComponent } from "../../shared/detail-and-activity/detail-and-activity.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { AllWorkspaceService } from "../../../app/@core/utils/all-workspace.service";
import { RandomUploadService } from "../../@core/random-upload.service";
import { DocPreviewComponent } from "../../../../src/app/shared/doc-preview/doc-preview.component";
import { DeleteDocsService } from "../../@core/utils/delete-docs.service";
import { SignInService } from "../../../../src/app/@core/sign-in.service";
import { CookieService } from "ngx-cookie-service";
import { Title } from "@angular/platform-browser";
import { NGXLogger } from "ngx-logger";
import { catchError } from "rxjs/operators";
import { OwnedDocsService } from "../../../../src/app/@core/utils/owned-docs.service";
import { UpgradeModalComponent } from "../../../../src/app/shared/upgrade-modal/upgrade-modal.component";
import { SharedAccessService } from "../../../../src/app/@core/utils/shared-access.service";
import { DateTransformPipe } from "../../pages/date-transform.pipe";
import { SharedDocsService } from "../../../app/@core/utils/shared-docs.service";
import { NetworkService } from "../../../app/pages/random-upload/networks.service";
import { NormalSearchService } from "../../../app/@core/utils/normal-search.service";

@Injectable({
  providedIn: "root",
})
@Component({
  selector: "ngx-response",
  templateUrl: "./response.component.html",
  styleUrls: ["./response.component.scss"],
})
export class ResponseComponent implements OnInit {
  /**
   * Stores workspace ID
   */
  workSpaceId: any;
  /**
   * Stores document owner email ID.
   */
  documentOwnerEmail: any;
  /**
   * Stores documnent Modified Name
   */
  docModifiedEditedName: any;
  /**
   * Stores local Login user ID
   */
  createdBy: any;
  /**
   * To check If Guest Rights available to the logged in user
   */
  GuestFlag: string;
  /**
   * To check if parser (doc-handler) rights are available to the logged in user
   */
  parserflagdata: string;
  /**
   * variable to store share msg fpor sharing of docket
   */
  shareMsg = "One or more document(s) don't have the same sharing settings as this Docket";

  /**
   * Check if Guest Access rights are available to the user.
   */
  guestAccess: string;
  /**
   * Stores workspace name
   */
  wsName: string;
  /**
   * disableUploading of other card
   */
  disableUploadinCard = false;
  /**
   * Stores Workspace GUID
   */
  getguid: string;
  /**
   * Stores workspace date format
   */
  dateFormat: string;
  /**
   * uidInputValue variable
   */
  uidInputValue="";
  /**
   * showUidSaveBtn variable
   */
  showUidSaveBtn=false;
  /**
   * showUidSaveBtndisable variable
   */
  showUidSaveBtndisable=false;
  /**
   * showDuplicateUidAlert variable
   */
  showDuplicateUidAlert=false;
  /**
   * Stores Workspace susbscription plan
   */
  subscriptionPlan: string;
  selectedDoclength: number = 0;
  selectedDoclengthShare: number = 0;
  /**
   * To check if edit rights are available to the logged in user
   */
  editrights: any;
  /**
   * To check if digitization is available for the workspace
   */
  digiRights: any;
  /**
   * To check if docket is to be shared with all users
   */
  isCheckedAllUsers: boolean;
  /**
   * Stores document owner name
   */
  documentOwnerName: any;
  /**
   * Stores document owner email ID
   */
  ownerEmailID: any;
  /**
   * To check if document is to be shared with all users
   */
  isAllUserShare: boolean;
  /**
   * Stores workspace display name
   */
  WsdisplayName: any;
  /**
   * Check if docket has been touched (for editing)
   */
  docsVerification: any;
  /**
   * Stores tag template result
   */
  templateInput: any;
  /**
   * Check if form is custom
   */
  customform: any;
  /**
   * Stores ID of Template selected from template dropdown
   */
  tagTemplateId: any;
  /**
   * Stores data from API `TemplateList`
   */
  selectedShowCase = [];
   /**
   * Stores docket owner name;
   */
  docketOwnerName: string;
  /**
   * Stores Docket Response ID
   */
  docidResponse = [];
  /**
   * Stores response's verifier email ID
   */
  verifierEmailidResponse = [];
  /**
   * Stores response's owner Email ID
   */
  ownerEmailIdResponse = [];
  /**
   * Stores response's document GUID
   */
  docGuidResponse = [];
  /**
   * Stores data from API `TemplateDetailsById`
   */
  pendingRequest: any;
  /**
   * editTemplateInput Variable
   */
  editTemplateInput: any;
  /**
   * arrayUniqueByKeyResponse Variable
   */
  arrayUniqueByKeyResponse: unknown[];
  /**
   * emptyPage Variable
   */
  emptyPage: any;
  /**
   * tagNote Variable
   */
  tagNote: any = "";
  /**
   * showBox Variable
   */
  showBox: boolean;
  /**
   * deleteField Variable
   */
  deleteField: boolean;
  /**
   * userInputSingleTag Variable
   */
  userInputSingleTag: string;
  /**
   * getUserDataSingleTag Variable
   */
  getUserDataSingleTag: string;
  /**
   * _hubConnecton Variable
   */
  public _hubConnecton?: mdl.HubConnection;
  /**
   * singleTagsListResponse Variable
   */
  singleTagsListResponse = [];
  /**
   * tagLabelsResponse Variable
   */
  tagLabelsResponse = [];
  /**
   * tagValuesResponse Variable
   */
  tagValuesResponse = [];
  /**
   * isText Variable
   */
  isText: boolean = false;
  /**
   * isDate Variable
   */
  isDate: boolean = false;
  /**
   * isOption Variable
   */
  isOption: boolean = false;
  /**
   * textFieldArrayResponse Variable
   */
  textFieldArrayResponse: any = [];
  /**
   * datefieldArrayResponse Variable
   */
  datefieldArrayResponse: any = [];
  /**
   * dropdownArrayResponse Variable
   */
  dropdownArrayResponse: any = [];
  /**
   * showBox1 Variable
   */
  showBox1: boolean;
  /**
   * blankState Variable
   */
  blankState: boolean = false;
  /**
   * multipleDocError Variable
   */
  multipleDocError: boolean;
  /**
   * ShowDateResponse Variable
   */
  ShowDateResponse = [];
  /**
   * showValue Variable
   */
  showValue: boolean = false;
  /**
   * verifyFlag Variable
   */
  verifyFlag = false;
  /**
   * getSelectedDocTagData Variable
   */
  getSelectedDocTagData: any;
  /**
   * tagDetailsResponse Variable
   */
  tagDetailsResponse = [];
  /**
   * tagList Variable
   */
  tagList: string;
  /**
   * uidType Variable
   */
  uidType: string;
  /**
   * singleTagFlag Variable
   */
  singleTagFlag: boolean;
  /**
   * selectedPagesIncrease Variable
   */
  selectedPagesIncrease: any;
  /**
   * checkBulkTagHeader Variable
   */
  checkBulkTagHeader: boolean;
  /**
   * isInputEnterorSpace Variable
   */
  isInputEnterorSpace: boolean;
  /**
   * selectedDocumentsResponse Variable
   */
  selectedDocumentsResponse = [];
  /**
   * checklabelResponse Variable
   */
  checklabelResponse: boolean[] = [];
  /**
   * checkvaluesResponse Variable
   */
  checkvaluesResponse: boolean[] = [];
  /**
   * dialogRef Variable
   */
  dialogRef: NbDialogRef<any>;
  /**
   * isModalHidden Variable
   */
  isModalHidden = false;
  /**
   * islabelResponse Variable
   */
  islabelResponse: string[] = [];
  /**
   * isvalueResponse Variable
   */
  isvalueResponse: string[] = [];
  /**
   * dropdownValueResponse Variable
   */
  dropdownValueResponse = [];
  /**
   * selectedResponse Variable
   */
  selectedResponse = [];
  /**
   * userInput Variable
   */
  userInput: any;
  /**
   * getUserData Variable
   */
  getUserData: any;
  /**
   * selectedTemplate Variable
   */
  selectedTemplate;
  /**
   * tagLabel Variable
   */
  tagLabel: string;
  /**
   * tagvalue Variable
   */
  tagvalue: string;
  /**
   * flagValue Variable
   */
  flagValue = "";
  /**
   * customArrayResponse Variable
   */
  customArrayResponse = [];
  /**
   * items Variable
   */
  items: {
      tagControlId: any;
      docId?: any;
      tempTagId?: number;
      tagLabel?: any;
      tagValue?: any;
      createdBy?: string;
      optionValue?: string;
  };
  /**
   * uploadedByDisplayNameResponse Variable
   */
  uploadedByDisplayNameResponse: string[] = [];
  /**
   * copyLabelResponse Variable
   */
  copyLabelResponse: string[] = [];
  /**
   * copyValueResponse Variable
   */
  copyValueResponse: string[] = [];
  /**
   * isLabelChanged Variable
   */
  isLabelChanged: boolean;
  /**
   * isValueChanged Variable
   */
  isValueChanged: boolean;
  /**
   * isTextBorderShowResponse Variable
   */
  isTextBorderShowResponse: boolean[] = [];
  /**
   * ShowDateCustomResponse Variable
   */
  ShowDateCustomResponse: boolean[] = [];
  /**
   * ShowDate1Response Variable
   */
  ShowDate1Response = [];
  /**
   * isCustomBorderShowResponse Variable
   */
  isCustomBorderShowResponse: boolean[] = [];
  /**
   * isCustomValueFocusedResponse Variable
   */
  isCustomValueFocusedResponse: boolean[] = [];
  /**
   * isDateBorderShowResponse Variable
   */
  isDateBorderShowResponse: boolean[];
  /**
   * isDropDownBorderShowResponse Variable
   */
  isDropDownBorderShowResponse: boolean[];
  /**
   * isTextValueFocusedResponse Variable
   */
  isTextValueFocusedResponse: boolean[] = [];
  /**
   * isDateValueFocusedResponse Variable
   */
  isDateValueFocusedResponse: boolean[] = [];
  /**
   * currentTab Variable
   */
  currentTab: string;
  /**
   * formatExtensionResponse Variable
   */
  formatExtensionResponse: string[] = [];
  /**
   * docRenameListResponse Variable
   */
  docRenameListResponse: string[] = [];
  /**
   * isTemplateTextResponse Variable
   */
  isTemplateTextResponse: boolean[] = [];
  /**
   * isTemplateDateResponse Variable
   */
  isTemplateDateResponse: boolean[] = [];
  /**
   * canTextBorderShowResponse Variable
   */
  canTextBorderShowResponse: boolean[] = [];
  /**
   * canDateBorderShowResponse Variable
   */
  canDateBorderShowResponse: boolean[] = [];
    /**
   * discard Variable
   */
  @ViewChild("discardModal") discardModal: TemplateRef<any>;
  /**
   * shareuse Variable
   */
  @ViewChild("shareChild") shareuse: SharedAccessComponent;
  /**
   * attachModal Variable
   */
  @ViewChild("attachModal") attachModal: TemplateRef<any>;
  /**
   * duplicate docket modal variable
   */
  @ViewChild("duplicateDocket") duplicateDocket: TemplateRef<any>;
    /**
   * error in case document type deleted modal variable
   */
    @ViewChild("documentTypeDel") documentTypeDel: TemplateRef<any>;
  /**
   * unlink document from docket modal variable
   */
  @ViewChild ('unlinkDocument') unlinkDocument: TemplateRef<any>;
  /**
   * delet document modal variable
   */
  @ViewChild ('deleteDocument') deleteDocument: TemplateRef<any>;
  /**
   * docketSharing Variable
   */
  @ViewChild("shareuse") docketSharing: TemplateRef<any>;
  /**
   * chnageUid template variable
   */
  @ViewChild("changeUidConfirmation") changeUid: TemplateRef<any>;
  /**
   * copyLoadingScreen template variable
   */
  @ViewChild("copyLoadingScreen") copyLoadingScreen: TemplateRef<any>;
  /**
   * upgradeChild Variable
   */
  @ViewChild(UpgradeModalComponent) upgradeChild: UpgradeModalComponent;

  /**
   * isTagSaving Variable
   */
  isTagSaving = false;
  /**
   * shareButtonStatus Variable
   */
  shareButtonStatus= false;
  /**
   * verifiedtype Variable
   */
  @Input() verifiedtype;
  /**
   * isSaving Variable
   */
  isSaving = false;
  /**
   * saveCurrentPostData Variable
   */
  saveCurrentPostData: {
      Wsoid: number;
      WsLink: string;
      DocID: number;
      TempID: number;
      DocRename: any;
      DocOwner: any;
      IsAllUserShare: boolean;
      Flag: string;
      ModifiedBy: any;
      docItTags: any;
      //  "docTags": this.tagDetailsResponse,
      ownerEmailIdResponse: any;
      ownerName: any;
      iDsList: string;
      docGuidList: string;
      oldOwnerEmailId: any;
      /*
       *adding modified by display name in the key of oldOwnerName
       */
      oldOwnerName: any;
      Notes: any;
      WsName: string;
      guid: string;
      DocGuid: string;
  };
  /**
   * loginProfilImage Variable
   */
  loginProfilImage: any;
  /**
   * ediimageUrl Variable
   */
  ediimageUrl: any;
  /**
   * enableSave Variable
   */
  enableSave: boolean;
  /**
   * showProfile Variable
   */
  showProfile: any;
  /**
   * hidenotify Variable
   */
  hidenotify: boolean;
  /**
   * isMenu Variable
   */
  isMenu: boolean;
  /**
   * profiletogglefield Variable
   */
  profiletogglefield: string;
  /**
   * showoutprofile Variable
   */
  showoutprofile: string;
  /**
   * options Variable
   */
  options: string[];
  /**
   * filteredControlOptions Variable
   */
  filteredControlOptions$: any;
  /**
   * inputFormControl Variable
   */
  inputFormControl: FormControl;
  /**
   * filteredNgModelOptions Variable
   */
  filteredNgModelOptions$: any;
  /**
   * hideJobIdCard Variable
   */
  hideJobIdCard: boolean = false;
  /**
   * array Variable
   */
  array: any = [];
  /**
   * saved Variable
   */
  saved = true;
  /**
   * count Variable
   */
  count: number = 0;
  /**
   * rightUserList Variable
   */
  rightUserList: any;
  /**
   * allUserLength Variable
   */
  allUserLength: any;
  /**
   * sharedWithUsers Variable
   */
  sharedWithUsers: any;
  /**
   * setUserGroupArr Variable
   */
  setUserGroupArr: any;
  /**
   * isAllStatus Variable
   */
  isAllStatus: any;
  /**
   * shareuserCount Variable
   */
  shareuserCount: any;
  /**
   * showVerifier Variable
   */
  showVerifier: boolean;
  /**
   * isUpload Variable
   */
  isUpload: boolean = false;
  /**
   * upload Variable
   */
  upload: any;
  /**
   * frId Variable
   */
  frId: string | Blob;
  /**
   * verifierNameEmail Variable
   */
  verifierNameEmail: string | Blob;
  /**
   * hideDashed Variable
   */
  hideDashed = [];
  /**
   * fisrtTabActive Variable
   */
  fisrtTabActive: boolean = true;
  /**
   * secondTabActive Variable
   */
  secondTabActive: boolean = false;
  /**
   * docList Variable
   */
  docList: any;
  /**
   * headerDoc Variable
   */
  headerDoc: any;
  /**
   * docItid Variable
   */
  docItid: number;
  /**
   * docketRId Variable
   */
  docketRId: number;
  /**
   * docketShareStatus Variable
   */
  docketShareStatus= false;
  /**
   * getList Variable
   */
  getList: any;
  /**
   * isTrue Variable
   */
  isTrue: boolean = false;
  /**
   * tagPanelOpen Variable
   */
  tagPanelOpen: boolean = true;
  /**
   * hidedetail Variable
   */
  hidedetail: boolean = true;
  /**
   * selectedDocs Variable
   */
  selectedDocs: any[] = [];
  /**
   * HideViewDetailresponse Variable
   */
  HideViewDetailresponse = true;
  /**
   * isSelectedOnceDetailsView Variable
   */
  isSelectedOnceDetailsView: boolean = false;
  /**
   * isDetailOpen Variable
   */
  isDetailOpen: boolean = false;
  /**
   * gridColumnApi Variable
   */
  gridColumnApi: any;
  /**
   * gridApi Variable
   */
  gridApi: any;
  /**
   * rowData Variable
   */
  rowData = [];
  /**
   * rightnavdatadetail Variable
   */
  rightnavdatadetail: any;
  /**
   * rightnavchild Variable
   */
  @ViewChild(DetailAndActivityComponent)
  rightnavchild: DetailAndActivityComponent;
  /**
   * senddetailsdata Variable
   */
  senddetailsdata: string;
  /**
   * getTempId Variable
   */
  getTempId: any;
  /**
   * blockUI Variable
   */
  @BlockUI() blockUI: NgBlockUI;
  /**
   * TemplateEditor Variable
   */
  TemplateEditor: any;
  /**
   * isSubmitted Variable
   */
  isSubmitted: boolean;
  /**
   * tagTemplate Variable
   */
  tagTemplate: any = [];
  /**
   * templateArray Variable
   */
  templateArray: any;
  /**
   * defaultTemplate Variable
   */
  defaultTemplate: any;
  /**
   * selectedTemplate1 Variable
   */
  selectedTemplate1: string;
  /**
   * tagTempList Variable
   */
  tagTempList: any = [];
  /**
   * docTemplateId Variable
   */
  docTemplateId: any;
  /**
   * rightnavchild1 Variable
   */
  rightnavchild1: any;
  /**
   * Spinnershow Variable
   */
  Spinnershow: boolean = true;
  /**
   * titleList Variable
   */
  titleList: string[] = [];
  /**
   * localData Variable
   */
  localData: any;
  /**
   * uniqueId  Variable
   */
  uniqueId: any;
  /**
   * countCall Variable
   */
  countCall: number = 0;
  /**
   * uploadedDocId Variable
   */
  uploadedDocId = [];
  /**
   * uploadedDocName Variable
   */
  uploadedDocName = [];
  /**
   * uploadedDocGuid Variable
   */
  uploadedDocGuid = [];
  /**
   * uploadedDocOwnerName Variable
   */
  uploadedDocOwnerName = [];
  /**
   * selectedCardIndex Variable
   */
  selectedCardIndex: number;
  /**
   * isFileNotUploaded Variable
   */
  isFileNotUploaded = [];
  /**
   * uploadedFileSize Variable
   */
  uploadedFileSize = [];
  /**
   * isRefresh Variable
   */
  isRefresh: boolean = true;
  /**
   * avaliableExtensions Variable
   */
  avaliableExtensions: string;
  /**
   * maxFileSize Variable
   */
  maxFileSize: any;
  /**
   * maxFileSize Variable
   */
  maxFileSizeUnit='';
  /**
   * getLostTags Variable
   */
  getLostTags: any;
  /**
   * hidecard Variable
   */
  hidecard: boolean = false;
  /**
   * validationError Variable
   */
  validationError = [];
  /**
   * currentFlag Variable
   */
  currentFlag: any;
  /**
   * numberofRow Variable
   */
  numberofRow: number = 50;
    /**
   * numberofRow Variable
   */
    numberofRowShared: number = 50;
  /**
   * Check Variable
   */
  Check: number = 0;
  /**
   * check Variable
   */
  check: any;
  /**
   * threeDotMenu Variable
   */
  threeDotMenu: any;
  /**
   * uploadedFormat Variable
   */
  uploadedFormat = [];
  /**
   * uploadedConvertedFile Variable
   */
  uploadedConvertedFile = [];
  /**
   * sourceFile Variable
   */
  sourceFile: any = "";
  /**
   * wsOutSync Variable
   */
  @ViewChild("wsOutSync", { read: TemplateRef }) wsOutSync: TemplateRef<any>;
  /**
   * accountdisabled Variable
   */
  @ViewChild("accountdisabled", { read: TemplateRef })
  accountdisabled: TemplateRef<any>;
  /**
   * countModalOpen Variable
   */
  countModalOpen: number = 0;
  /**
   * selectFileAccess Variable
   */
  selectFileAccess = [];
  /**
   * rolename Variable
   */
  rolename: string;
  /**
   * groupname  Variable
   */
  groupname: string;
  /**
   * msg Variable
   */
  msg: any;
  /**
   * result Variable
   */
  result = [];
  /**
   * docGuid Variable
   */
  docGuid: any;
  /**
   * showBanner Variable
   */
  showBanner: boolean;
  /**
   * show_status Variable
   */
  show_status: boolean;
  /**
   * parserflag Variable
   */
  parserflag: string;
  /**
   * editRights Variable
   */
  editRights: string;
  /**
   * uploadRights Variable
   */
  uploadRights: string;
  /**
   * deleteRights Variable
   */
  deleteRights: string;
  /**
   * previewRights Variable
   */
  previewRights: string;
  /**
   * isUserDocOwner Variable
   */
  isUserDocOwner = [];
  /**
   * refreshToInviteUser Variable
   */
  refreshToInviteUser: boolean;
  /**
   * saveurl Variable
   */
  saveurl: any;
  /**
   * viewspinner Variable
   */
  viewspinner: boolean = false;
  /**
   * jobTitle Variable
   */
  jobTitle: any;

  docStatus = '';

  format = '';

  riser : string = 'page-width';

  changedFormat = [];

  formatAvailable: any;

  docIsDisplayed: boolean = false;
  docUnderProcessing: boolean = true;
  docFormatNotSupported: boolean = false;
  docIsLockedOrCorrupt: boolean = false;
  docParkingQueue: boolean= false;
  showProcessing: boolean = false;
  statusText = '';
  /**
   * isPlanChanged Variable
   */
  isPlanChanged: boolean = false;
/**
 * variable for total documents for owned by me list
 */
  totalDocuments: number= 1 ;
  DocketType: any;
  getallparseflag: any;
  docketType: any;
  docketName: string;
  availableStore: any;
  byPass: boolean =false;
    countErrorCall = 0;
    oldShareing: any;
    newShareing: any;
    domainurl: any;
    docPreviewName = '';
    formatExtension = '';
    totalRequiredFiedls: number= 0;
    filledInfoCount: number = 0;
    filledInfoCountOptional: number = 0;
    allworkspaceDoc1: any[] = [];
    /**
 * variable for tab of shared with me
 */
    thirdTabActive: boolean= false;
    /**
 * array for total documents for shared with me list
 */
    allworkspaceDoc2: any[] = [];
    /**
 * variable for handling page count in the API.
 */
    pageCount: number= 1;
    pageCountSO: number= 0;
    pageCountSharedSO: number = 0;
    /**
 * variable for total documents for owned by me list
 */
    totalDocumentsOwned: number = 1;
    Spinnershow1: boolean = false;
    isnew: boolean= true;
    docketNameError: string = '';
    showInfo: boolean = true;
    documentIds = [];
    displayOwnerName: any;
    selectedDocumentId: any = [];
    selectedDocumentIds: any[] = [];
    documentIdsShare = [];
    displayOwnerNameShare: any;
    selectedDocumentIdShare: any = [];
    selectedDocumentIdsShare: any[] = [];
    networkBrkRef: any;
    networkErrorHere = true;
    frIds: any = [];
    uploadFileName: any;
    parserflagvalue: string;
    parserfeature: boolean = false;
    numberofdoc: any;
    event: any;
    deepSearch: boolean = false;
    searchFilterOn: boolean = false;
    searchFilterSharedOn: boolean = false;

    @ViewChild("confirmDeleteResponse") confirmDeleteResponse: TemplateRef<any>;

    documentTypeUnlink: any;
    indx: any;
    unlinkFlag: any;
    uniqueIdHere: void;
    externalIdAttached: boolean = false;
    documentTypeUnlinkExternal: any;
    indxExternal: any;
    unlinkFlagExternal: any;
    networkError: boolean = false;
    isActive: boolean = false;
    selectedItems: any = [];
    lastSelectedIndex: number = -1;
    lastSelectedIndexShare: number;
    checkDocumentCount :number = 0;
    discardOpen: boolean =false;
    fileIsShown: boolean = true;
  /**
    isActive: boolean = false;
   * Define constructor
   */
  constructor(
      private toastrService : NbToastrService,
      public shareAccess:SharedAccessService,
      private allworkspace: AllWorkspaceService,
      private data1: VerifyDocumentService,
      public titleService: Title,
      private router: Router,
      public qwerty: UploadComponent,
      public datepipe: DatePipe,
      private renderer: Renderer2,
      private dialogService: NbDialogService,
      private data: VerifyDocumentService,
      private http: HttpClient,
      public sharedService: SharedService,
      private details: DetailAndActivityService,
      private data2: OwnedDocsService,
      private service: ApiIntegrationService,
      private sharedservice: SharedServiceService,
      private randomUploadService: RandomUploadService,
      private elem: ElementRef,
      private bulktag: BulkTagComponent,
      public preview: DocPreviewComponent,
      private deleteDocService: DeleteDocsService,
      private signInService: SignInService,
      public cd: ChangeDetectorRef,
      private cookieService: CookieService,
      private logger: NGXLogger,
      private ownedService: OwnedDocsService,
      private datanew: SharedDocsService,
      public dateTransform: DateTransformPipe,
      private networkService: NetworkService,
      private normalService: NormalSearchService,
      public location: Location,
  ) {
      this.GuestFlag = localStorage.getItem("GuestFlag");
      this.parserflagdata = localStorage.getItem("parserflag");
      this.guestAccess = localStorage.getItem("isGuestAccess");
      this.localData = localStorage.getItem("celldata");
      this.wsName = localStorage.getItem("WorkSpaceName");
      this.workSpaceId = Number(localStorage.getItem("localwsId"));
      this.createdBy = localStorage.getItem("localloginId");
      this.getguid = localStorage.getItem("loginguid");
      this.dateFormat = localStorage.getItem("DateFormat");
      this.subscriptionPlan = localStorage.getItem("wsSubscriptionPlan");
      this.guestAccess = localStorage.getItem("isGuestAccess");
      this.displayOwnerName = localStorage.getItem("WsdisplayName");
      this.editrights = JSON.parse(localStorage.getItem("editaccess"));
      this.digiRights = JSON.parse(localStorage.getItem("AllwsFlag"));
      if (localStorage.getItem("Deep Search") == "True") {
        this.deepSearch = true;
      } else {
        this.deepSearch = false;
      }
      this.WsdisplayName = localStorage.getItem("WsdisplayName")
          ? localStorage.getItem("WsdisplayName")
          : "";
      this.isModalHidden = localStorage.getItem("isModalHidden") === "true";
      this.domainurl = environment.domain;
      let st = this.domainurl?.split(':')[1];
      if (st !== '') {
        this.cookieService.set('localloginId' + this.workSpaceId.toString(), this.createdBy);
        this.cookieService.set('localwsId' + this.workSpaceId.toString(), this.workSpaceId.toString());
      }
  }
  Variable;
  /**
   * saveTags Variable
   */
  @ViewChild("saveTags") saveTags: PointerEvent;
  /**
   * bulkTagBody Variable
   */
  @ViewChild("bulkTagBody") bulkTagBody: ElementRef;
  /**
   * textArea Variable
   */
  @ViewChild("textArea") textArea: ElementRef;
  /**
   * singleTags Variable
   */
  @ViewChild("singleTags") singleTags: ElementRef;
  /**
   * textArray Variable
   */
  @ViewChild("textArray") textArray: ElementRef;
  /**
   * dateArray Variable
   */
  @ViewChild("dateArray") dateArray: ElementRef;

  @ViewChild('backArrow') backArrow: ElementRef;
  /**
   * dropDown Variable
   */
  @ViewChild("dropDown") dropDown: ElementRef;
  /**
   * previewShow Variable
   */
  previewShow: boolean = false;
  /**
   * threeDotDlistShow Variable
   */
  threeDotDlistShow = [];
  /**
   * docItTag Variable
   */
  docItTag: boolean = false;
  /**
   * uploadComponent Variable
   */
  uploadComponent: any;
  /**
   * selected Variable
   */
  selected = false;
  /**
   * fileName Variable
   */
  fileName: any = [];
  /**
   * fileProgress Variable
   */
  fileProgress = [];
  /**
   * isProgress Variable
   */
  isProgress = [];
  /**
   * index Variable
   */
  index: number;
  /**
   * chooseFileName Variable
   */
  chooseFileName = [];
  /**
   * isSelectedAllUsers Variable
   */
  isSelectedAllUsers: any;
  /**
   * verifierName Variable
   */
  verifierName: any;
  /**
   * isUsersGroup Variable
   */
  isUsersGroup: any;
  /**
   * isSelectNoUsers Variable
   */
  isSelectNoUsers: any;
  /**
   * verifierEmailID Variable
   */
  verifierEmailID: any;
  /**
   * docitdtid Variable
   */
  docitdtid: any;
  /**
   * enterData Variable
   */
  enterData: any;
  /**
   * filterEnterData Variable
   */
  filterEnterData = [];
  /**
   * ownedValue Variable
   */
  ownedValue: any;
  /**
   * documentName Variable
   */
  documentName: any;
  /**
   * startedData Variable
   */
  startedData: any;
  /**
   * allworkspaceDoc Variable
   */
  allworkspaceDoc = [];
  /**
   * unlink Variable
   */
  unlink: number = 1;
  /**
   * searchValue Variable
   */
  searchValue: string;
  /**
   * searchDataNew Variable
   */
  searchDataNew: any[] = [];
  /**
   * regardingsearch Variable
   */
  regardingsearch: boolean;
  /**
   * trees Variable
   */
  trees = [];
  /**
   * searchData Variable
   */
  searchData: any[] = [];
  /**
   * filterApplied Variable
   */
  filterApplied: any;
  /**
   * searchList variable
   */

  //Happy New Year by suraj
  searchList: any[];
  /**
   * docIdWs Variable
   */
  docIdWs: any;

  @ViewChild('networkBrk') networkBrk;

  newtworkError = false;

  files = [];

  globalIndex:any;

  isUploadClick: boolean = false;

  /**
   * This method is called when user presses a key from the `Select <JobTitle>` dropdown
   */
  makeChoice(event) {
      if (event.target.value == "") {
          this.selected = false;
      } else {
          this.selected = true;
      }
  }

  /**
   * This method is called when user selects an existing response from the `Select <JobTitle>` dropdown
   */
  selectedData() {
      this.selected = true;
  }

  /**
   * git ignore ngOnInit
   */

  isOpen: boolean = false;

  clickedOutside = true;
  hassavedChanges = true;
  showSpinnerDockets: boolean = false;

  

  async ngOnInit(): Promise<void> {


    //  setTimeout(() => {
    //   sessionStorage.removeItem('byPass')
    //  }, 8000);
    this.showSpinnerDockets = true;
     if(!window.location.href.includes("NEW")){
        this.isnew = false;
      this.hideJobIdCard= false;
     }
     this.parserflagvalue = localStorage.getItem('parserflag');
     if(this.parserflagvalue?.toLowerCase() === 'true'){
       this.parserfeature = true;
     }
     else{
       this.parserfeature = false;
     }
    //  this.sourceFile = 'https://img.freepik.com/free-photo/abstract-textured-backgound_1258-30461.jpg?w=740&t=st=1695901260~exp=1695901860~hmac=0e484b3e2fb3689449d1788cb1d6603ad6f598e7524aa386e5f54aae7530058c';
      await this.docitAndUniqueId();
      this.sharedservice.hideSideBar.next(false);
      this.titleService.setTitle(
          "Response . " +
          (localStorage.getItem("WsdisplayName")
              ? localStorage.getItem("WsdisplayName")
              : "") +
          " . " +
          localStorage.getItem("WorkSpaceName") +
          " . dox2U"
      );
      this.uploadRights = localStorage.getItem("isDocumentUpload");
      this.deleteRights = localStorage.getItem("deleteaccess");
      this.editRights = localStorage.getItem("editaccess");
      this.previewRights = localStorage.getItem("preview");
      localStorage.removeItem("hubMessage");
      localStorage.removeItem("deletedResponseUniqueID");
      localStorage.setItem("docOwnerEmailId", this.createdBy);
      this.UploadRightsUserList();
      this.connectiongenrate();
      await this.getResponsePageData();
      if (sessionStorage.getItem("celldata")) {
          this.localData = sessionStorage.getItem("celldata");
      }
      if (this.localData) {
          this.hideJobIdCard = false;  
        //   this.uniqueId = localStorage.getItem('cellData')?.split(",")[2];
          this.uniqueId = this.localData?.split(",")[0];
          this.uidInputValue = this.uniqueId;
          this.docItid = Number(this.localData?.split(",")[1]);
          sessionStorage.setItem("celldata", this.uniqueId + "," + this.docItid);
          this.getPreviousmethodValue();
      } else {
          this.docItid = Number(localStorage.getItem("docItid"));
      }
      this.inputFormControl = new FormControl(this.uniqueId);
      this.onGetTemplateDetailsResponse();
      this.getDataFromInput();
      this.currentTab = window.location.href.toString();

      setTimeout(() => {
          if (
              (this.customArrayResponse?.length === 0 || !this.customArrayResponse) &&
              this.hidecard
          ) {
              this.addTAgValueResponse();
          }
      }, 1000);

      this.sharedService.getTagId
          .pipe(
          catchError((error) => {
              this.logger.error(error);
              return of(`Error: ${error}`);
              })
          )
          .subscribe((event: any) => {
              this.getTempId = event;
          });

      this.onGetTemplateDetails();
      this.tagTemplateList(false);

      this.sharedService.ownedByMeTag
          .pipe(
              catchError((error) => {
                  this.logger.error(error);
                  return of(`Error: ${error}`);
              })
          )
          .subscribe((res) => {
              if (this.HideViewDetailresponse == res) {
                  this.tagVerify();
                  this.HideViewDetailresponse = false;
              }
          });

      this.sharedservice.ownedByMeViewDetails
          .pipe(
              catchError((error) => {
                  this.logger.error(error);
                  return of(`Error: ${error}`);
              })
          )
          .subscribe((res) => {
              if (this.HideViewDetailresponse == res) {
                  this.detailsection();
                  this.HideViewDetailresponse = false;
              }
          });

      this.validateUploadStorage();

      this.sharedService.closeDetailBar1
          .pipe(
              catchError((error) => {
                  this.logger.error(error);
                  return of(`Error: ${error}`);
              })
          )
          .subscribe(() => {
              this.closeTagPanel();
              this.crossdata();
              this.showData();
          });

      this.allverifiedDocList("refresh");
      this.sharedService.getOldTagsList
          .pipe(
              catchError((error) => {
                  this.logger.error(error);
                  return of(`Error: ${error}`);
              })
          )
          .subscribe((response) => {
              this.getLostTags = response;
          });

      this.sharedService.isClose
          .pipe(
              catchError((error) => {
                  this.logger.error(error);
                  return of(`Error: ${error}`);
              })
          )
          .subscribe((_reponse: any) => {
              this.previewShow = false;
          });

      setInterval(() => {
          if (
              this.uniqueId === localStorage.getItem("deletedResponseUniqueID")
          ) {
            //   this.openModal(this.wsOutSync, false);
              localStorage.setItem("deletedResponseUniqueID", "");
            //   this.countModalOpen++;
            this.router.navigate(['/pages/documents/dockets']);
          }
          if (localStorage.getItem("hubMessage")) {
              this.result = localStorage.getItem("hubMessage")?.split("|");
              localStorage.removeItem("hubMessage");
              this.openModal(this.accountdisabled, false);
          }
      }, 3000);

      if(this.uidInputValue===""){
        this.showUidSaveBtn = false;
      }
      if(window.location.href.includes("NEW")){
        this.isnew = true;
         this.selectId();
        }
        this.cd.detectChanges();
        console.log(this.uniqueId);
  }  
  async newDocket(): Promise<void> {
    //  setTimeout(() => {
    //   sessionStorage.removeItem('byPass')
    //  }, 8000);
    //  this.sourceFile = 'https://img.freepik.com/free-photo/abstract-textured-backgound_1258-30461.jpg?w=740&t=st=1695901260~exp=1695901860~hmac=0e484b3e2fb3689449d1788cb1d6603ad6f598e7524aa386e5f54aae7530058c';
      await this.docitAndUniqueId();
      this.sharedservice.hideSideBar.next(false);
      this.titleService.setTitle(
          "Response . " +
          (localStorage.getItem("WsdisplayName")
              ? localStorage.getItem("WsdisplayName")
              : "") +
          " . " +
          localStorage.getItem("WorkSpaceName") +
          " . dox2U"
      );
      this.uploadRights = localStorage.getItem("isDocumentUpload");
      this.deleteRights = localStorage.getItem("deleteaccess");
      this.editRights = localStorage.getItem("editaccess");
      this.previewRights = localStorage.getItem("preview");
      localStorage.removeItem("hubMessage");
      localStorage.removeItem("deletedResponseUniqueID");
      localStorage.setItem("docOwnerEmailId", this.createdBy);
      this.UploadRightsUserList();
      this.connectiongenrate();
      await this.getResponsePageData();
      if (sessionStorage.getItem("celldata")) {
          this.localData = sessionStorage.getItem("celldata");
      }
      if (this.localData) {
          this.hideJobIdCard = false;
          this.uniqueId = this.localData?.split(",")[0];
          this.uidInputValue = this.uniqueId;
          this.docItid = Number(this.localData?.split(",")[1]);
          sessionStorage.setItem("celldata", this.uniqueId + "," + this.docItid);
        //   this.getPreviousmethodValue();
      } else {
          this.docItid = Number(localStorage.getItem("docItid"));
      }
      this.inputFormControl = new FormControl(this.uniqueId);
      this.onGetTemplateDetailsResponse();
      this.getDataFromInput();
      this.currentTab = window.location.href.toString();

      setTimeout(() => {
          if (
              (this.customArrayResponse?.length === 0 || !this.customArrayResponse) &&
              this.hidecard
          ) {
              this.addTAgValueResponse();
          }
      }, 1000);

      this.sharedService.getTagId
          .pipe(
          catchError((error) => {
              this.logger.error(error);
              return of(`Error: ${error}`);
              })
          )
          .subscribe((event: any) => {
              this.getTempId = event;
          });

      this.onGetTemplateDetails();
      this.tagTemplateList(false);

      this.sharedService.ownedByMeTag
          .pipe(
              catchError((error) => {
                  this.logger.error(error);
                  return of(`Error: ${error}`);
              })
          )
          .subscribe((res) => {
              if (this.HideViewDetailresponse == res) {
                  this.tagVerify();
                  this.HideViewDetailresponse = false;
              }
          });

      this.sharedservice.ownedByMeViewDetails
          .pipe(
              catchError((error) => {
                  this.logger.error(error);
                  return of(`Error: ${error}`);
              })
          )
          .subscribe((res) => {
              if (this.HideViewDetailresponse == res) {
                  this.detailsection();
                  this.HideViewDetailresponse = false;
              }
          });

      this.validateUploadStorage();

      this.sharedService.closeDetailBar1
          .pipe(
              catchError((error) => {
                  this.logger.error(error);
                  return of(`Error: ${error}`);
              })
          )
          .subscribe(() => {
              this.closeTagPanel();
              this.crossdata();
              this.showData();
          });

      this.allverifiedDocList("refresh");
      this.sharedService.getOldTagsList
          .pipe(
              catchError((error) => {
                  this.logger.error(error);
                  return of(`Error: ${error}`);
              })
          )
          .subscribe((response) => {
              this.getLostTags = response;
          });

      this.sharedService.isClose
          .pipe(
              catchError((error) => {
                  this.logger.error(error);
                  return of(`Error: ${error}`);
              })
          )
          .subscribe((_reponse: any) => {
              this.previewShow = false;
          });

      setInterval(() => {
          if (
              this.uniqueId === localStorage.getItem("deletedResponseUniqueID") &&
              this.countModalOpen === 0
          ) {
              this.openModal(this.wsOutSync, false);
              localStorage.setItem("deletedResponseUniqueID", "");
              this.countModalOpen++;
          }
          if (localStorage.getItem("hubMessage")) {
              this.result = localStorage.getItem("hubMessage")?.split("|");
              localStorage.removeItem("hubMessage");
              this.openModal(this.accountdisabled, false);
          }
      }, 3000);

      if(this.uidInputValue===""){
        this.showUidSaveBtn = false;
      }
    //   this.isnew = false;
      this.cd.detectChanges();
  }

  /**
   * This method is called immediately after ngOnInit life cycle hook ends its execution
   */
  ngAfterViewInit() {
      this.sharedService.isResponse
          .pipe(
              catchError((error) => {
                  this.logger.error(error);
                  return of(`Error: ${error}`);
              })
          )
          .subscribe((response) => {
              this.rightnavchild1 = response;
          });
  }

  toggleAccordion() {
    this.isOpen = !this.isOpen;
  }

  /**
   * This method is called when user Presses `Next` after selecting/entering a new response name
   *
   * Calls API `</api/docItTypeMaster/CreateResponse>`
   */
 async selectId() {
    this.byPass =true
    sessionStorage.setItem('byPass','true')
      let val = "done";
    //   this.uniqueId = this.inputFormControl.value || this.inputFormControl;
    this.localData = localStorage.getItem('cellData');
              this.uniqueId = this.localData?.split(",")[2];
          this.docItid = Number(this.localData?.split(",")[1]);
      sessionStorage.setItem("celldata", this.uniqueId + "," + this.docItid);
      let doc = window.location.href.split('/')
      this.docketName = doc[6]
      let docName = doc[8]
    this.isActive = true;
    //   const url = this.router.serializeUrl(
    //       this.router.createUrlTree([
    //           "pages/documents/dockets/" + this.docketName + "/UID/" + this.uniqueId.replace(/\//g,"||"),
    //       ])
    //   );
    this.location.replaceState(`pages/documents/dockets/${this.docketName}/UID/${docName}`);
      setTimeout(() => {
        this.newDocket();
      }, 300);
      this.getPreviousmethodValue();
  }

  /**
   * This method is called to open Attach Documents modal
   */
  desiredIndex= -1;
  attachModalOPen(dialog: TemplateRef<any>, i) {
      localStorage.setItem('link', 'true')
      this.fisrtTabActive = true;
      this.secondTabActive = false;
      this.selectedCardIndex = i;
      this.desiredIndex = i;
      this.dialogService.open(dialog, {
        closeOnBackdropClick: false,
        closeOnEsc: false,
      });
      this.numberofRow = 50;
      this.numberofRowShared = 50;
    //   this.allverifiedDocList("abc");
  }

   /*Proceed Anyways Btn Click*/
   ProceedAnywaysClick() {
   
    const myElement1 = document.getElementById("mainAppBox");
    myElement1.style.display = "block";
 
    const myElement2 = document.getElementById("msgBox");
    myElement2.style.display = "none";
     
   }

  /**
   * This method is called when Tab 1(Local Response Document List) is to be loaded in the Attach Documents Modal
   */
  attachTabOne() {
      this.fisrtTabActive = true;
      this.secondTabActive = false;
      this.thirdTabActive =false;
  }

  /**
   * This method is called when Tab 2(All Workspace Document List) is to be loaded in the Attach Documents Modal
   */
  attachTabTwo() {
      this.secondTabActive = true;
      this.fisrtTabActive = false;
      this.thirdTabActive =false;
      this.totalDocuments = 1;
      this.getOwnedByList();
  }
/**
 * function for opening the shared with me in attach from ws modal
 */
  attachTabThree() {
    this.secondTabActive = false;
    this.fisrtTabActive = false;
    this.thirdTabActive =true;
    this.totalDocumentsOwned = 1;
    this.getSharedList();
  }
  /**
   * This method is called when existing Template List is to be loaded
   */
  getDataFromInput(val?) {
      this.onGetTemplateDetailsResponse(val);
      let postData = {
          wsoid: Number(this.workSpaceId),
          guid: this.getguid,
      };
      this.data
          .tagTemplateService(postData)
          .pipe(
              catchError((error) => {
                  this.logger.error(error);
                  return of(`Error: ${error}`);
              })
          )
          .subscribe((res: any) => {
              if (res) {
                  this.selectedShowCase = res.data;
              }
              this.selectedTemplate1 = this.selectedTemplate1 + "," + val?.tempName;
              const e = new Event("mouseenter");
              const element = document.querySelector("#dropDown");
              if (element && e) {
                  element.dispatchEvent(e);
              }
              const ele = new Event("mouseleave");
              const element2 = document.querySelector("#dropDown");
              if (element2 && ele) {
                  element2.dispatchEvent(ele);
              }
              const ele4 = new Event("mouseenter");
              const element4 = document.querySelector("#clickedTag");
              if (element4 && ele4) {
                  element4.dispatchEvent(ele4);
              }
              const ele3 = new Event("mouseleave");
              const element3 = document.querySelector("#clickedTag");
              if (element3 && ele3) {
                  element3.dispatchEvent(ele3);
              }
              const ele5 = new Event("mouseenter");
              const element5 = document.querySelector("#textArray");
              if (element5 && ele5) {
                  element5.dispatchEvent(ele5);
              }
              const ele6 = new Event("mouseleave");
              const element6 = document.querySelector("#textArray");
              if (element6 && ele6) {
                  element6.dispatchEvent(ele6);
              }
              // this.SaveTagVerifyData();
          });
      const e = new Event("mouseenter");
      const element = document.querySelector("#dropDown");
      if (element && e) {
          element.dispatchEvent(e);
      }
      const ele = new Event("mouseleave");
      const element2 = document.querySelector("#dropDown");
      if (element2 && ele) {
          element2.dispatchEvent(ele);
      }
      const ele4 = new Event("mouseenter");
      const element4 = document.querySelector("#clickedTag");
      if (element4 && ele4) {
          element4.dispatchEvent(ele4);
      }
      const ele3 = new Event("mouseleave");
      const element3 = document.querySelector("#clickedTag");
      if (element3 && ele3) {
          element3.dispatchEvent(ele3);
      }
      const ele5 = new Event("mouseenter");
      const element5 = document.querySelector("#textArray");
      if (element5 && ele5) {
          element5.dispatchEvent(ele5);
      }
      const ele6 = new Event("mouseleave");
      const element6 = document.querySelector("#textArray");
      if (element6 && ele6) {
          element6.dispatchEvent(ele6);
      }
  }

  /**
   * This method is called when TextField (Tags in Response) is moved out of focus
   */
  getTextBoxHideResponse(check, event, _x) {
      this.showBox = true;
      this.showBox1 = true;
      if (check == "tagLabel") {
          this.tagLabel = event?.target?.value || event?.target?.textContent;
      }
      if (check == "tagvalue") {
          this.tagvalue = event?.target?.value || event?.target?.textContent;
      }
      if (this.tagLabel && this.tagvalue) {
      }
  }

  /**
   * This method is called when TextField (Tags in Response) is moved in focus
   */
  getTextBoxShownResponse() {
      this.showBox = false;
      this.showBox1 = false;
  }

  /**
   * This method is called when Notes (Tags in Response) is moved out of focus
   */
  saveUserNoteResponse(event) {
      this.userInput = event?.target?.value || event?.target?.textContent;
      this.getUserData = this.userInput;
  }

  /**
   * This method is called when user presses a key in the single tag field of a response
   */
  saveUserSingleTagResponse(event?) {
    localStorage.setItem("documentId", this.sharedService.documentId);
      if (event && event?.target.value.trim() !== "") {
          this.singleTagFlag = true;
      }
      this.isInputEnterorSpace = event?.keyCode === 13 || event?.keyCode === 32;
      this.userInputSingleTag = event?.target.value.trim();
      let tagsInPastedText = this.getUserDataSingleTag?.split(" ");
      if (this.userInputSingleTag?.length <= 15) {
          if (this.isInputEnterorSpace) {
              this.getUserDataSingleTag =
                  this.userInputSingleTag !== "" ? this.userInputSingleTag : "";
              this.SaveSingleTagDataResponse();
          } else {
              this.getUserDataSingleTag =
                  this.userInputSingleTag !== "" ? this.userInputSingleTag : "";
          }
      } else if (this.isInputEnterorSpace) {
          this.SaveSingleTagDataResponse();
      } else {
          event.preventDefault();
      }
  }

  deleteDocumentModal(i,j,flag) {
    if (this.externalIdAttached) {
        this.documentTypeUnlinkExternal = i;
        this.indxExternal = j;
        this.unlinkFlagExternal = flag;
        this.openModal(this.deleteDocument, false);
    } else {
        this.unlinkData(i,j,flag);
    }
    
  }

  unlinkDocModal(i,j,flag) {
    this.uniqueIdHere = this.uniqueId;
    this.openModal(this.unlinkDocument, false);
    this.documentTypeUnlink = i;
    this.indx = j;
    this.unlinkFlag = flag;
  }

  confirmExternalDel() {
    this.unlinkData(this.documentTypeUnlinkExternal,this.indxExternal,this.unlinkFlagExternal);
    // if (this.previewShow) {
    //     this.previewShow = false;
    // }
    // if (this.docItTag) {
    //     this.docItTag = false
    // }
  }

  /**
   * This method is called to unlink/delete a document from Response page
   */
  unlinkData(i, j, flag?) {
      this.unlink = 0;
      let docId = localStorage.getItem("docId");
      if (flag) {
          this.getDocumentLinked(i, j, flag);
      } else {
          this.getDocumentLinked(i, j);
      }
      setTimeout(() => {
          this.isFileNotUploaded[i][j] = false;
          this.isProgress[i][j] = false;
          this.uploadedDocId[i]?.splice(j, 1);
          this.uploadedDocName[i]?.splice(j, 1);
          this.uploadedConvertedFile[i]?.splice(j, 1);
          this.uploadedFormat[i]?.splice(j, 1);
          this.uploadedDocGuid[i]?.splice(j, 1);
          this.uploadedDocOwnerName[i]?.splice(j, 1);
          this.uploadedFileSize[i]?.splice(j, 1);
          this.fileProgress[i]?.splice(j, 1);
          this.isProgress[i]?.splice(j, 1);
          this.chooseFileName[i]?.splice(j, 1);
          this.statusText = this.getDocketStatus();
          this.isFileNotUploaded[i]?.splice(j, 1);
          this.selectFileAccess[i]?.splice(j, 1);
          this.isUserDocOwner[i]?.splice(j, 1);
          this.validationError[i]?.splice(j, 1);
          if (this.chooseFileName[i]?.length === 0) {
              this.hideDashed[i] = false;
          }
          if (this.threeDotDlistShow[i]) {
              this.threeDotDlistShow[i][j] = false;
          }
        if (this.previewShow) {
            this.previewShow = false;
        }
            if (this.docItTag) {
                this.docItTag = false
        }
      }, 1000);
  }

  /**
   * This method is called when user clicks on `date` icon (convert tag type from `text` to `date`) from `custom` array(unfilled)
   */
  changeCustomTextInputTypeResponse(i) {
      this.ShowDateCustomResponse[i] = true;
      this.isvalueResponse[i] = "";
      this.isCustomBorderShowResponse[i] = false;
      this.isCustomValueFocusedResponse[i] = true;
      this.customArrayResponse[i].tagControlId = "1";
  }

  /**
   * This method is called when user clicks on `date` icon (convert tag type from `text` to `date`) from `text` array (already filled)
   */
  changeTextInputTypeResponse(i) {
      this.ShowDateResponse[i] = true;
      this.textFieldArrayResponse[i].tagValue = "";
      this.isTextBorderShowResponse[i] = false;
      this.isTextValueFocusedResponse[i] = true;
  }

  /**
   * This method is called when user clicks on `date` icon (convert tag type from `text` to `date`) from `date` array (already filled)
   */
  changeDate1InputTypeResponse(i) {
      this.ShowDate1Response[i] = true;
      this.datefieldArrayResponse[i].tagValue = "";
      this.isDateBorderShowResponse[i] = false;
      this.isDateValueFocusedResponse[i] = true;
  }

  /**
   * This method is called when user clicks on `text` icon (convert tag type from `date` to `text`) from `custom` array (not filled)
   */
  changeCustomDateInputTypeResponse(i) {
      this.ShowDateCustomResponse[i] = false;
      this.isvalueResponse[i] = "";
      this.isCustomBorderShowResponse[i] = false;
      this.isCustomValueFocusedResponse[i] = true;
      this.customArrayResponse[i].tagControlId = "2";
  }

  /**
   * This method is called when user clicks on `text` icon (convert tag type from `date` to `text`) from `text` array (already filled)
   */
  changeDateInputTypeResponse(i) {
      this.ShowDateResponse[i] = false;
      this.textFieldArrayResponse[i].tagValue = "";
      this.isTextBorderShowResponse[i] = false;
      this.isTextValueFocusedResponse[i] = true;
  }

  /**
   * This method is called when user clicks on `text` icon (convert tag type from `date` to `text`) from `date` array (already filled)
   */
  changeDate2InputTypeResponse(i) {
      this.ShowDate1Response[i] = false;
      this.datefieldArrayResponse[i].tagValue = "";
      this.isDateBorderShowResponse[i] = false;
      this.isDateValueFocusedResponse[i] = true;
  }

  /**
   * This method is called when user focuses in/ focuses out from `text` array `Label` (already filled)
   */
  isTextfocusResponse(i, event) {
      if (event?.target?.value.trim() !== "" && event.type === "blur") {
          this.isTextBorderShowResponse[i] = false;
      } else {
          this.isTextBorderShowResponse[i] = true;
      }
  }

  /**
   * This method is called when user focuses in/ focuses out from `date` array `Label` (already filled)
   */
  isDatefocusResponse(i, event) {
      if (event?.target?.value.trim() !== "" && event.type === "blur") {
          this.isDateBorderShowResponse[i] = true;
      } else {
          this.isDateBorderShowResponse[i] = false;
          // let checkIndex = "dateLabel_" + [i] + "_2";
          // document.getElementById(checkIndex).focus();
          // checkIndex = "dateLabel_" + [i] + "_1";
          // document.getElementById(checkIndex).focus();
      }
  }

  /**
   * This method is called when user focuses in/ focuses out from `dropdown` array `Label` (already filled)
   */
  isfocusDropDownResponse(i, event) {
      if (event?.target?.value.trim() !== "" && event.type === "blur") {
          this.isDropDownBorderShowResponse[i] = true;
      } else {
          this.isDropDownBorderShowResponse[i] = false;
      }
  }

  /**
   * This method is called when user focuses in/ focuses out from `custom` array `Label` (already filled)
   */
  isfocusCustomResponse(i, event) {
      if (event?.target?.value.trim() !== "" && event.type === "blur") {
          this.isCustomBorderShowResponse[i] = true;
      } else {
          this.isCustomBorderShowResponse[i] = false;
      }
  }

  /**
   * This method is called when user focuses out from any `Label` or `Value` field
   *
   * This checks various conditions(e.g. is label and value both filled, is one of them edited while other was already filled, etc) to finally decide whether to save changes made by the user
   */
  saveCustomTagsResponse(i, event, value, key?) {
      if (
          value == "label" &&
          ((event?.target?.value && event?.target?.value != "") ||
              (event?.target?.textContent && event?.target?.textContent !== ""))
      ) {
          if (
              this.copyLabelResponse?.length > 0 &&
              (this.copyLabelResponse[i] !== event?.target?.value ||
                  this.copyLabelResponse[i] !== event?.target?.textContent)
          ) {
              this.checklabelResponse[i] = true;
          } else if (this.isLabelChanged) {
              this.checklabelResponse[i] = true;
              this.copyLabelResponse[i + 1] =
                  event?.target?.value || event?.target?.textContent;
          } else {
              this.checklabelResponse[i] = false;
              this.copyLabelResponse[i + 1] =
                  event?.target?.value || event?.target?.textContent;
          }
          this.isLabelChanged = false;
      }

      if (
          value == "value" &&
          ((event?.target?.value && event?.target?.value != "") ||
              (event?.target?.textContent && event?.target?.textContent != ""))
      ) {
          this.checkvaluesResponse[i] = true;
          this.copyValueResponse[i + 1] =
              event?.target?.value || event?.target?.textContent;
          // this.isValueChanged = false;
      }

      if (
          this.textFieldArrayResponse &&
          this.textFieldArrayResponse?.length > 0
      ) {
          if (
              this.textFieldArrayResponse[i]?.tagValue &&
              this.checklabelResponse[i] &&
              this.textFieldArrayResponse[i]?.tagValue?.trim() !== "" &&
              (this.textFieldArrayResponse[i]?.tagLabel === event?.target?.value ||
                  this.textFieldArrayResponse[i]?.tagLabel ===
                  event?.target?.textContent)
          ) {
              this.checkvaluesResponse[i] = true;
          }
          if (
              this.textFieldArrayResponse[i]?.tagLabel &&
              this.checkvaluesResponse[i] &&
              this.textFieldArrayResponse[i]?.tagLabel?.trim() !== "" &&
              (this.textFieldArrayResponse[i]?.tagValue === event?.target?.value ||
                  this.textFieldArrayResponse[i]?.tagValue ===
                  event?.target?.textContent)
          ) {
              this.checklabelResponse[i] = true;
          }
      }

      if (
          this.datefieldArrayResponse &&
          this.datefieldArrayResponse?.length > 0
      ) {
          if (
              this.datefieldArrayResponse[i]?.tagValue &&
              this.checklabelResponse[i] &&
              this.datefieldArrayResponse[i]?.tagValue?.trim() !== "" &&
              (this.datefieldArrayResponse[i]?.tagLabel === event?.target?.value ||
                  this.datefieldArrayResponse[i]?.tagLabel ===
                  event?.target?.textContent)
          ) {
              this.checkvaluesResponse[i] = true;
          }
          if (
              this.datefieldArrayResponse[i]?.tagLabel &&
              this.checkvaluesResponse[i] &&
              this.datefieldArrayResponse[i]?.tagLabel?.trim() !== "" &&
              (this.datefieldArrayResponse[i]?.tagValue === event?.target?.value ||
                  this.datefieldArrayResponse[i]?.tagValue ===
                  event?.target?.textContent)
          ) {
              this.checklabelResponse[i] = true;
          }
      }

      if (this.dropdownArrayResponse && this.dropdownArrayResponse?.length > 0) {
          if (
              this.dropdownArrayResponse[i]?.tagValue &&
              this.checklabelResponse[i] &&
              this.dropdownArrayResponse[i]?.tagValue?.trim() !== "" &&
              (this.dropdownArrayResponse[i]?.tagLabel === event?.target?.value ||
                  this.dropdownArrayResponse[i]?.tagLabel ===
                  event?.target?.textContent)
          ) {
              this.checkvaluesResponse[i] = true;
          }
          if (
              this.dropdownArrayResponse[i]?.tagLabel &&
              this.checkvaluesResponse[i] &&
              this.dropdownArrayResponse[i]?.tagLabel?.trim() !== "" &&
              (this.dropdownArrayResponse[i]?.tagValue === event?.target?.value ||
                  this.dropdownArrayResponse[i]?.tagValue ===
                  event?.target?.textContent)
          ) {
              this.checklabelResponse[i] = true;
          }
      }

      if (this.islabelResponse[i] && this.checkvaluesResponse[i]) {
          if (this.islabelResponse[i].trim() !== "") {
              this.checklabelResponse[i] = true;
          }
      }

      if (this.isvalueResponse[i] && this.checklabelResponse[i]) {
          if (this.isvalueResponse[i].trim() !== "") {
              this.checkvaluesResponse[i] = true;
          }
      }

      if (this.checklabelResponse[i] && this.checkvaluesResponse[i]) {
          this.items = {
              docId: this.docidResponse[0],
              tempTagId: 0,
              tagLabel:
                  this.islabelResponse[i] || this.textFieldArrayResponse[i].tagLabel,
              tagValue:
                  this.isvalueResponse[i] || this.textFieldArrayResponse[i].tagValue,
              createdBy: localStorage.getItem("localloginId"),
              tagControlId: "1",
              optionValue: "",
          };
          this.checklabelResponse[i] = false;
          this.checkvaluesResponse[i] = false;
          this.islabelResponse[i] = "";
          this.isvalueResponse[i] = "";
          this.isCustomBorderShowResponse[i] = false;
          this.isCustomValueFocusedResponse[i] = true;
          if (key == "add") {
              let addArrayofItems = [this.items];
              if (this.ShowDateCustomResponse[i]) {
                  this.ShowDate1Response = [true].concat(this.ShowDate1Response);
                  this.isDateBorderShowResponse = [true].concat(
                      this.isDateBorderShowResponse
                  );
                  this.isDateValueFocusedResponse = [true].concat(
                      this.isDateValueFocusedResponse
                  );
                  this.datefieldArrayResponse = addArrayofItems.concat(
                      this.datefieldArrayResponse
                  );
                  this.items.tagControlId = "2";
              } else if (!this.ShowDateCustomResponse[i]) {
                  this.ShowDateResponse = [false].concat(this.ShowDateResponse);
                  this.isTextBorderShowResponse = [false].concat(
                      this.isTextBorderShowResponse
                  );
                  this.isTextValueFocusedResponse = [false].concat(
                      this.isTextValueFocusedResponse
                  );
                  this.textFieldArrayResponse = addArrayofItems.concat(
                      this.textFieldArrayResponse
                  );
              }
              // this.ShowDateCustomResponse = [false].concat(this.ShowDateCustomResponse);
              this.customArrayResponse.splice(i, 1);
          } else {
              this.textFieldArrayResponse[i] = this.items;
          }
          this.SaveTagVerifyData();
      }
  }

  /**
   * This method is called when Label is changed (Tag Label)
   */
  labelChangedResponse(_event?, _tag?) {
    localStorage.setItem("documentId", this.sharedService.documentId);
      this.isLabelChanged = true;
  }

  /**
   * This method is called when Value is changed (Tag Value)
   */
  valueChangedResponse(_event?, _tag?) {
    localStorage.setItem("documentId", this.sharedService.documentId);
      this.isValueChanged = true;
  }

  /**
   * This method is called when user pastes something in the single tag value field
   */
  onPasteResponse(event: ClipboardEvent) {
      let clipboardData = event.clipboardData;
      let pastedText = clipboardData.getData("text").toString();
      let regex = new RegExp("^[a-zA-Z0-9. s-]*$");
      let regPattern = /[\u0000-\u007A]+/g;
      if (regex.test(pastedText) && regPattern.test(pastedText)) {
          this.getUserDataSingleTag = "";
          let checkWhiteSpace = pastedText.split(" ")[1];
          if (checkWhiteSpace) {
              let tagsInPastedText = pastedText.split(" ");
              for (let i = 0; i < tagsInPastedText?.length; i++) {
                  if (
                      !this.singleTagsListResponse.includes(tagsInPastedText[i].trim()) &&
                      tagsInPastedText[i]?.length <= 15
                  ) {
                      this.singleTagsListResponse = [tagsInPastedText[i]].concat(
                          this.singleTagsListResponse
                      );
                  } else {
                      event.preventDefault();
                  }
              }
              this.SaveTagVerifyData();
          } else {
              if (
                  !this.singleTagsListResponse.includes(pastedText) &&
                  pastedText?.length <= 15
              ) {
                  this.singleTagsListResponse = [pastedText.trim()].concat(
                      this.singleTagsListResponse
                  );
                  this.SaveTagVerifyData();
              } else {
                  event.preventDefault();
              }
          }
      } else {
          event.preventDefault();
      }
  }

  /**
   * This method is called whenever window is reloaded
   */
  @HostListener("window:beforeunload", ["$event"])
  unloadNotificationResponse($event: any) {
      if (localStorage.getItem("saved") == "true" && this.isSaving) {
          $event.returnValue = true;
      }
  }

  /**
   * This method is called to call API `<TemplateDetailsById>` and fetch details about the template already existing in the workspace
   */
  onGetTemplateDetailsResponse(val?) {
      //tagLabel   tagValue  tagControlId
      if (val) {
          this.templateInput = [];
          let st = window.location.toString();
          let arr = st.split("/");
          let postData: any;
          postData = {
              Wsoid: Number(this.workSpaceId),
              WsLink: arr[2],
              TempId: val.tdId,
          };
          let text: any = [],
              date = [],
              dropdown = [],
              labelSet = [];
          this.pendingRequest = this.data
              .templateDetailsService(postData)
              .pipe(
                  catchError((error) => {
                      this.logger.error(error);
                      return of(`Error: ${error}`);
                  })
              )
              .subscribe((res: any) => {
                  res.data.forEach((e) => {
                      if (e.tagControlId == 1) {
                          this.isText = true;
                          if (!e.tagValue || e.tagValue === null || e.tagValue === "") {
                              e.tagValue = "";
                          } else {
                              e.tagValue = e.tagValue.slice(0, 40);
                          }
                          text.push(e);
                          this.ShowDateResponse = [false].concat(this.ShowDateResponse);
                          this.isTemplateTextResponse = [true].concat(
                              this.isTemplateTextResponse
                          );
                          this.isTextBorderShowResponse = [false].concat(
                              this.isTextBorderShowResponse
                          );
                          if (e.tagValue === "") {
                              this.isTextValueFocusedResponse = [true].concat(
                                  this.isTextValueFocusedResponse
                              );
                          } else {
                              this.isTextValueFocusedResponse = [false].concat(
                                  this.isTextValueFocusedResponse
                              );
                          }
                          // this.isTextValueFocusedResponse = [true].concat(this.isTextValueFocusedResponse);
                      } else if (e.tagControlId == 2) {
                          this.isDate = true;
                          if (
                              e.tagValue.includes("null") ||
                              e.tagValue === "Invalid date NaN,NaN" ||
                              e.tagValue === null
                          ) {
                              e.tagValue = "";
                          }
                          if (e.tagValue !== "") {
                              if (!e.tagValue.includes("-")) {
                                  e.tagValue =
                                      e.tagValue.slice(0, 4) +
                                      "-" +
                                      e.tagValue.slice(4, 6) +
                                      "-" +
                                      e.tagValue.slice(6);
                              }
                              e.tagValue = String(
                                  new DatePipe("en-US").transform(e.tagValue, "YYYY-MM-dd")
                              );
                          }
                          date.push(e);
                          this.ShowDate1Response = [true].concat(this.ShowDate1Response);
                          this.isTemplateDateResponse = [true].concat(
                              this.isTemplateDateResponse
                          );
                          this.isDateBorderShowResponse = [true].concat(
                              this.isDateBorderShowResponse
                          );
                          if (e.tagValue === "") {
                              this.isDateValueFocusedResponse = [true].concat(
                                  this.isDateValueFocusedResponse
                              );
                          } else {
                              this.isDateValueFocusedResponse = [false].concat(
                                  this.isDateValueFocusedResponse
                              );
                          }
                          // this.isDateValueFocusedResponse = [true].concat(this.isDateValueFocusedResponse);
                      } else if (e.tagControlId == 3) {
                          this.isOption = true;
                          dropdown.push(e);
                          this.isDropDownBorderShowResponse = [true].concat(
                              this.isDropDownBorderShowResponse
                          );
                      }
                  });

                  this.textFieldArrayResponse = text?.concat(
                      this.textFieldArrayResponse
                  );
                  this.datefieldArrayResponse = date?.concat(
                      this.datefieldArrayResponse
                  );
                  this.dropdownArrayResponse = dropdown?.concat(
                      this.dropdownArrayResponse
                  );
                  this.tagLabel = this.textFieldArrayResponse.tagLabel;
                  this.tagvalue = this.textFieldArrayResponse.tagLabel;
                  for (let i = 0; i < this.dropdownArrayResponse?.length; i++) {
                      if (this.dropdownArrayResponse[i].tagValue.includes("||")) {
                          this.dropdownValueResponse[i] =
                              this.dropdownArrayResponse[i].tagValue.split("||");
                      } else {
                          this.dropdownValueResponse[i] = [
                              this.dropdownArrayResponse[i].tagValue,
                          ];
                          this.selectedResponse[i] = this.dropdownArrayResponse[i].tagValue;
                      }
                  }
                  const e = new Event("mouseenter");
                  const element = document.querySelector("#dropDown");
                  if (element && e) {
                      element.dispatchEvent(e);
                  }
                  const ele = new Event("mouseleave");
                  const element2 = document.querySelector("#dropDown");
                  if (element2 && ele) {
                      element2.dispatchEvent(ele);
                  }
                  const ele4 = new Event("mouseenter");
                  const element4 = document.querySelector("#clickedTag");
                  if (element4 && ele4) {
                      element4.dispatchEvent(ele4);
                  }
                  const ele3 = new Event("mouseleave");
                  const element3 = document.querySelector("#clickedTag");
                  if (element3 && ele3) {
                      element3.dispatchEvent(ele3);
                  }
                  const ele5 = new Event("mouseenter");
                  const element5 = document.querySelector("#textArray");
                  if (element5 && ele5) {
                      element5.dispatchEvent(ele5);
                  }
                  const ele6 = new Event("mouseleave");
                  const element6 = document.querySelector("#textArray");
                  if (element6 && ele6) {
                      element6.dispatchEvent(ele6);
                  }
                  // this.SaveTagVerifyData();
              });
          const e = new Event("mouseenter");
          const element = document.querySelector("#dropDown");
          if (element && e) {
              element.dispatchEvent(e);
          }
          const ele = new Event("mouseleave");
          const element2 = document.querySelector("#dropDown");
          if (element2 && ele) {
              element2.dispatchEvent(ele);
          }
          const ele4 = new Event("mouseenter");
          const element4 = document.querySelector("#clickedTag");
          if (element4 && ele4) {
              element4.dispatchEvent(ele4);
          }
          const ele3 = new Event("mouseleave");
          const element3 = document.querySelector("#clickedTag");
          if (element3 && ele3) {
              element3.dispatchEvent(ele3);
          }
          const ele5 = new Event("mouseenter");
          const element5 = document.querySelector("#textArray");
          if (element5 && ele5) {
              element5.dispatchEvent(ele5);
          }
          const ele6 = new Event("mouseleave");
          const element6 = document.querySelector("#textArray");
          if (element6 && ele6) {
              element6.dispatchEvent(ele6);
          }
      }
  }

  /**
   * This method is called when user selects a value from the dropdown tag
   */
  getSelectedDataResponse(event, i) {
      this.selectedResponse[i] = event;
      this.dropdownArrayResponse[i].tagValue = this.selectedResponse[i];
      this.SaveTagVerifyData();
  }

  /**
   * This method is called when user selects a template from the template selector
   */
  getTagId(event) {
      this.sharedService.getTagId.next(event);
  }

  /**
   * This method is called to check how many documents are selected at once
   */
  checkEmptyPage() {
      let flag = false;
      this.selectedPagesIncrease = this.emptyPage;
      this.emptyPage = localStorage.getItem("selectedDocs");
      let tagList = this.bulkTagBody;
      for (let i = 0; i < this.docidResponse?.length; i++) {
          if (this.selectedDocumentsResponse) {
              if (this.selectedDocumentsResponse[i] !== this.docidResponse[i]) {
                  this.verifyFlag = false;
                  this.selectedPagesIncrease = 1;
                  this.singleTagsListResponse = [];
                  this.getUserDataSingleTag = "";
              }
          }
          this.selectedDocumentsResponse[i] = this.docidResponse[i];
      }
      if (this.selectedPagesIncrease !== this.emptyPage) {
          this.verifyFlag = false;
          this.selectedPagesIncrease = 1;
          this.singleTagsListResponse = [];
          this.getUserDataSingleTag = "";
      }
      if (this.emptyPage > 0) {
          flag = true;
      }
      return flag;
  }

  /**
   * This method is called from ngOnInit, i.e. when user first lands on the response page.
   *
   * This adds a custom array (empty label and value fields) and sets border and focus parameters
   */
  addTAgValueResponse() {
      this.blankState = true;
      let item = {
          tagControlId: "1",
          tagLabel: "",
          tagValue: "",
      };
      this.customArrayResponse = [item].concat(this.customArrayResponse);
      this.ShowDateCustomResponse = [false].concat(this.ShowDateCustomResponse);
      this.isCustomBorderShowResponse = [false].concat(
          this.isCustomBorderShowResponse
      );
      this.isCustomValueFocusedResponse = [true].concat(
          this.isCustomValueFocusedResponse
      );
      this.islabelResponse = [""].concat(this.islabelResponse);
      this.isvalueResponse = [""].concat(this.isvalueResponse);
  }

  /**
   * Calls API `<GetTagValueList>`
   */
  templatechange(data?) {
      let templatedata = {
          wsoid: Number(this.workSpaceId),
          tagLabel: data.tagTempLabel,
      };
      this.pendingRequest = this.data
          .templatechange(templatedata)
          .pipe(
              catchError((error) => {
                  this.logger.error(error);
                  return of(`Error: ${error}`);
              })
          )
          .subscribe((response: any) => {
              if (response.isSuccess) {
                  if (response.data?.length > 0) {
                      let temdata = {
                          createdDtm: null,
                          tagValue: data.tagTempValue,
                      };
                      response.data.push(temdata);
                      const key = "tagValue";
                      this.arrayUniqueByKeyResponse = [
                          ...new Map(
                              response.data.map((item) => [item[key], item])
                          ).values(),
                      ];
                      for (let i = 0; i <= this.templateInput?.length - 1; i++) {
                          if (this.templateInput[i].id == data.id) {
                              this.templateInput[i].tagvaldata = temdata.tagValue;
                              this.templateInput[i].existingtagvalue =
                                  this.arrayUniqueByKeyResponse;
                              this.templateInput[i].checktagdrop = true;
                              this.templateInput[i]["errormsg"] = "";
                          }
                      }
                  }
              }
          });
  }

  /**
   * This method is called when saved tag data (single tag, label-values, notes) are to be saved
   *
   * Calls API `<UpdateDocDetails>`
   */
  SaveTagVerifyData(array?, type?, tag?) {
      let tagsAndValue = array;
      let postDatas = [];
      if (type) {
          if (type === "text") {
              this.textFieldArrayResponse.splice(tag, 1);
              this.deleteField = true;
          } else if (type === "date") {
              this.datefieldArrayResponse.splice(tag, 1);
              this.deleteField = true;
          } else if (type === "dropdown") {
              this.dropdownArrayResponse.splice(tag, 1);
              this.deleteField = true;
          }
      }
      for (let i = 0; i < this.datefieldArrayResponse?.length; i++) {
          if (
              this.datefieldArrayResponse[i].tagValue !== "" &&
              this.datefieldArrayResponse[i].tagValue.includes("-") &&
              !this.datefieldArrayResponse[i].tagValue.includes("null") &&
              !this.datefieldArrayResponse[i].tagValue === null
          ) {
              this.datefieldArrayResponse[i].tagValue = String(
                  new DatePipe("en-US").transform(
                      this.datefieldArrayResponse[i].tagValue,
                      "YYYY-MM-dd"
                  )
              );
          }
      }
      tagsAndValue = [
          ...this.textFieldArrayResponse,
          ...this.datefieldArrayResponse,
          ...this.dropdownArrayResponse,
      ];
      console.log("tags and value", tagsAndValue);
      for (let x = 0; x < this.docidResponse?.length; x++) {
          for (let tag of tagsAndValue) {
              if (
                  tag.tagValue?.trim() !== "" ||
                  (!tag.tagValue?.includes("||") && tag.tagControlId === "3")
              ) {
                  postDatas.push({
                      docItID: this.docItid,
                      uniqueID: String(this.uidInputValue),
                      tempId: tag.tempTagId,
                      tagLabel: tag.tagLabel,
                      tagValue: tag.tagValue,
                      createdBy: localStorage.getItem("localloginId"),
                      tagControlId: tag.tagControlId.toString(),
                      optionValue: "",
                  });
              }
          }
      }
      tagsAndValue = postDatas;
      var st = window.location.toString();
      var arr = st.split("/");
      let doctagArray = [];
      this.tagDetailsResponse = [];
      doctagArray.push(array);
      let list = []
      for (let item of this.uploadedDocGuid) {
          if (item.length !== 0) {
              list.push(item)
          }
      }
      let postData = {
          Wsoid: Number(this.workSpaceId),
          WsLink: arr[2],
          docItID: this.docItid,
          uniqueID: String(this.uidInputValue),
          DocID: Number(this.docidResponse[0]) != null?Number(this.docidResponse[0]): 0,
          TempID: 1,
          DocRename: this.docModifiedEditedName || "",
          DocOwner: this.ownerEmailIdResponse[0],
          IsAllUserShare:
              this.isCheckedAllUsers === false && this.isAllUserShare === false
                  ? false
                  : true,
          Flag: "tag",
          ModifiedBy: this.createdBy,
          docItTags: tagsAndValue,
          //  "docTags": this.tagDetailsResponse,
          ownerEmailIdResponse: this.ownerEmailIdResponse[0],
          ownerName: this.ownerEmailIdResponse[0],
          iDsList: this.docidResponse.toString(),
          docGuidList: list.toString(),
          oldOwnerEmailId: this.ownerEmailID,
          /*
           *adding modified by display name in the key of oldOwnerName
           */
          oldOwnerName: this.WsdisplayName,
          Notes: this.tagNote,
          WsName: this.wsName,
          guid: this.getguid,
          DocGuid: this.getguid,
      };

      setTimeout(() => {
          if (this.singleTagsListResponse?.length > 0) {
              postData.TempID = 0;
              this.flagValue = "verify";
              this.tagList = JSON.parse(localStorage.getItem("tagList"));
              for (let x = 0; x < this.docidResponse?.length; x++) {
                  for (let i = 0; i < this.singleTagsListResponse?.length; i++) {
                      let docTagList = {
                          docItID: this.docItid,
                          uniqueID: String(this.uidInputValue),
                          tempId: 0,
                          tagLabel: this.singleTagsListResponse[i],
                          tagValue: "",
                          createdBy: this.createdBy,
                          tagControlId: "1",
                          optionValue: "",
                      };
                      postData.docItTags.push(docTagList);
                  }
              }
          }
      }, 100);
      if (postData.Flag === "verify") {
          let filteredData = this.templateInput?.filter((e) => {
              return e.tagTempValue != "";
          });
          filteredData?.forEach((e) => {
              let template = {
                  tempId: this.tagTemplateId,
                  tagLabel: "",
                  tagValue: "",
                  createdBy: this.createdBy,
                  tagcontrolid: "",
                  optionvalue: "",
              };
              if (e.inputType === "text") {
                  template.tagLabel = e.tagTempLabel;
                  template.tagValue = e.tagTempValue;
                  template.tagcontrolid = "1";
                  doctagArray.push(template);
              } else if (e.inputType === "dropdown") {
                  if (e.selectedValue != "" || e.selectedValue != null) {
                      template.tagLabel = e.tagTempLabel;
                      template.tagValue = e.selectedValue;
                      template.tagcontrolid = "3";
                      if (e.tagTempLabel && e.selectedValue) {
                          if (e.selectedValue != "Select") {
                              template.optionvalue = e.tagTempValue.join("||");
                              doctagArray.push(template);
                          }
                      }
                  }
              } else if (e.inputType === "date") {
                  if (e.tagTempValue != "Invalid date NaN,NaN") {
                      template.tagLabel = e.tagTempLabel;
                      // let changedateformat = moment(e.tagTempValue).format("YYYY-MM-DD");
                      // template.tagValue = changedateformat;
                      template.tagValue = e.tagTempValue;
                      template.tagcontrolid = "2";
                      doctagArray.push(template);
                  }
              }
          });
          if (this.customform) {
              for (let e of this.customform?.controls) {
                  let a = e as FormGroup;
                  let template = {
                      tagLabel: "",
                      tagValue: "",
                      tagcontrolid: "",
                      createdBy: this.createdBy,
                  };
                  template.tagLabel = a.value.label;
                  if (a.value.tagcontrolid == 2) {
                      // let changedateformat = moment(a.value.value).format("YYYY-MM-DD");
                      // template.tagValue = changedateformat;
                      template.tagValue = e.tagTempValue;
                  } else {
                      template.tagValue = a.value.value;
                  }
                  template.tagcontrolid = a.value.tagcontrolid;
                  doctagArray.push(template);
              }
          }
      }
      // let postDataForElastic;
      // let checkIfElasticUpdated = false;
      setTimeout(() => {
          if (this.verifyFlag) {
              postData.Flag = "verify";
              this.data
                  .DocItTemplateInsert(postData)
                  .pipe(
                      catchError((error) => {
                          this.logger.error(error);
                          return of(`Error: ${error}`);
                      })
                  )
                  .subscribe((response: any) => {
                      // let postDataForElastic;
                      // let checkIfElasticUpdated = false;
                      if (response.isSuccess) {
                          if (this.count == 0) {
                              this.saveCurrentPostData = postData;
                          }
                          this.count++;
                          this.data
                              .elasticUpdate(this.saveCurrentPostData)
                              .pipe(
                                  catchError((error) => {
                                      this.logger.error(error);
                                      return of(`Error: ${error}`);
                                  })
                              )
                              .subscribe((response: any) => {
                                  if (response.isSuccess) {
                                      this.count = 0;
                                  }
                              });
                          this.isSaving = true;
                          setTimeout(() => {
                              this.isSaving = false;
                          }, 500);
                          // window.location.reload();
                      }
                  });
          } else {
              this.data
                  .saveDocsitTags(postData)
                  .pipe(
                      catchError((error) => {
                          this.logger.error(error);
                          return of(`Error: ${error}`);
                      })
                  )
                  .subscribe((response: any) => {
                      if (response.isSuccess) {
                          this.data
                              .elasticUpdate(postData)
                              .pipe(
                                  catchError((error) => {
                                      this.logger.error(error);
                                      return of(`Error: ${error}`);
                                  })
                              )
                              .subscribe();
                          this.isSaving = true;
                          setTimeout(() => {
                              this.isSaving = false;
                          }, 500);
                      }
                  });
          }
      }, 300);

  }

  /**
   * This method is called when user presses space or enter (or pastes something) in the single tag field
   */
  SaveSingleTagDataResponse() {
      if (this.getUserDataSingleTag || this.getUserDataSingleTag !== "") {
          let checkSpace = this.getUserDataSingleTag.split(" ");
          if (checkSpace?.length > 1) {
              for (let i = 0; i < checkSpace?.length; i++) {
                  let spaceSeparatedTag = [checkSpace[i].trim()];
                  this.singleTagsListResponse = spaceSeparatedTag.concat(
                      this.singleTagsListResponse
                  );
              }
          } else {
              let tagWithoutSpaces = [this.getUserDataSingleTag.trim()];
              this.singleTagsListResponse = tagWithoutSpaces.concat(
                  this.singleTagsListResponse
              );
          }
          this.singleTagsListResponse = [...new Set(this.singleTagsListResponse)];
          this.SaveTagVerifyData();
      }
      this.getUserDataSingleTag = "";
  }

  /**
   * This method is called when event received from upload file menu
   */
  getEvent($event) {
      this.isUpload = true;
      if ($event.srcElement.files) {
          let count = 0;
          for (let i of $event.srcElement.files) {
              this.fileName.push(i);
              this.uploadSubmit(count);
              count++;
          }
      }
      // this.sharedService.getUpload.next(true)
      // document.getElementById('getFile').click();
  }

  /**
   * This method is called when a file is finalised to be send to Uplaod component
   *
   * Also receives data from the Upload Component (After file is being sent to upload, i.e. API <DocUpload1> is called)
   */
  uploadSubmit(index?) {
      this.sharedService.upgradeIt.pipe(
              catchError((error) => {
                  this.logger.error(error);
                  return of(`Error: ${error}`);
              })
          ).subscribe(() => {
          this.upgradeChild.openUpgradeUpload('upload additional dPages', 'EM-048');
          localStorage.setItem('refreshKey', 'true')
      });
      this.index = index;
      var saveUrl = environment.baseUrl + "/api/RandomDocumentUpload/DocUpload1";
      this.qwerty.isDocumentUpload = true;
      let docitdtid = this.docList[this.index]?.docItdtid;
      this.docItid = Number(localStorage.getItem("docItid"));
      let jIndex = this.uploadedDocGuid[this.index]?.length;
      this.fileProgress[this.index][jIndex] = 0;
      this.isProgress[this.index][jIndex] = true;
      console.log("choose file name", this.chooseFileName[this.index]);
      this.qwerty.uploadResponse(
          this.chooseFileName[this.index],
          jIndex,
          docitdtid,
          this.docItid
      );
      localStorage.setItem("isFileUploaded", "false");
      this.sharedService.responseUploadProgressStatus
          .pipe(
              catchError((error) => {
                  this.logger.error(error);
                  return of(`Error: ${error}`);
              })
          )
          .subscribe((response: any) => {
              if (!isNaN(response)) {
                console.log("response is heree", response);
                  jIndex = this.uploadedDocGuid[this.index]?.length;
                  this.fileProgress[this.index][jIndex] = response;
              }
          });
      this.sharedService.responseUploadProgress
          .pipe(
              catchError((error) => {
                  this.logger.error(error);
                  return of(`Error: ${error}`);
              })
          )
          .subscribe((response: boolean) => {
            console.log("response is heree 1234", response);
              jIndex = this.uploadedDocGuid[this.index]?.length;
              this.isProgress[this.index][jIndex] = response;
          });
          this.sharedService.documentTypeNotExist
          .pipe(
              catchError((error) => {
                  this.logger.error(error);
                  return of(`Error: ${error}`);
              })
          )
          .subscribe((response: boolean) => {
            //   jIndex = this.uploadedDocGuid[this.index]?.length;
            //   this.isProgress[this.index][jIndex] = response;
            if (response) {
                this.openModal(this.documentTypeDel, false);
            }

          });
      this.sharedService.responseDocDetails
          .pipe(
              catchError((error) => {
                  this.logger.error(error);
                  return of(`Error: ${error}`);
              })
          )
          .subscribe((response: any) => {
              let uploadedDocResponse = response;
              jIndex = this.fileProgress[this.index]?.length - 1;
              if (
                  uploadedDocResponse &&
                  this.uploadedDocName[this.index]?.length !== jIndex + 1
              ) {
                  this.Spinnershow = false;
                  this.fileProgress[this.index][jIndex] = 100;
                  this.uploadedDocId[this.index][jIndex] = Number(
                      uploadedDocResponse?.DocId
                  );
                  this.uploadedDocName[this.index][jIndex] =
                      uploadedDocResponse?.Request.originalFileName;
                  this.uploadedDocOwnerName[this.index][jIndex] =
                      uploadedDocResponse?.Request.documentOwner;
                  this.uploadedDocGuid[this.index][jIndex] =
                      uploadedDocResponse?.DocGuid;
                  this.uploadedFormat[this.index][jIndex] =
                      uploadedDocResponse?.Request?.format;
                  this.uploadedConvertedFile[this.index][jIndex] =
                      uploadedDocResponse?.filename;
                  this.isUserDocOwner[this.index][jIndex] = true;
                  this.selectFileAccess[this.index][jIndex] = true;
                  this.tagVerify(this.index, jIndex, "upload");
              }
          });
      this.sharedService.DocketFileUploadError.pipe(
          catchError((error) => {
              this.logger.error(error);
              return of(`Error: ${error}`);
          })
      ).subscribe((response: any) => {
                if (response[1] == "Network Error") {
                    if(this.countErrorCall === 0){
                        jIndex = this.uploadedDocGuid[this.index]?.length;
                        this.isFileNotUploaded[this.index][jIndex] = response[0];
                        if (response.length > 1) {
                            this.countErrorCall++;
                    if(localStorage.getItem('currentlyUploadingFile')){
                        this.validationError[this.index][jIndex] = response[1];
                        this.uploadedDocGuid[this.index][jIndex] = "NA";
                        localStorage.removeItem('currentlyUploadingFile');
                    }
                    this.globalIndex = jIndex;
                    this.networkError = true;
                    this.isProgress[this.index][jIndex] = false;
                    this.openModal(this.networkBrk, false);
                }
                setTimeout(() => {
                  this.countErrorCall = 0;
                }, 100);
            }
                }
               else if (response[1] == "Error from Server") {
                this.Spinnershow = false;
                // this.fileProgress[this.index][jIndex] = 100;
                this.uploadedDocId[this.index][jIndex] = Number(
                    "23908"
                );
                this.uploadedDocName[this.index][jIndex] =
                    "Dox2u";
                this.uploadedDocOwnerName[this.index][jIndex] =
                    "Himanshu";
                this.uploadedDocGuid[this.index][jIndex] =
                    "1_833848484";
                this.uploadedFormat[this.index][jIndex] =
                    "png";
                this.uploadedConvertedFile[this.index][jIndex] =
                    "abc";
                this.isUserDocOwner[this.index][jIndex] = true;
                // this.tagVerify(this.index, jIndex, "upload");
                    this.globalIndex = jIndex;
                    this.isProgress[this.index][jIndex] = false;
                    this.validationError[this.index][jIndex] = response[1];
                    this.uploadedDocGuid[this.index][jIndex] = "NA";
                    this.isFileNotUploaded[this.index][jIndex] = true; 
                    localStorage.setItem("trigerUploadNext", "true")
                    // this.errorHandleSecene();
                } else {
                    if(this.countErrorCall === 0){
                        jIndex = this.uploadedDocGuid[this.index]?.length;
                        this.isFileNotUploaded[this.index][jIndex] = response[0];
                        if (response.length > 1) {
                            this.countErrorCall++;
                    if(localStorage.getItem('currentlyUploadingFile')){
                        this.validationError[this.index][jIndex] = response[1];
                        this.uploadedDocGuid[this.index][jIndex] = "NA";
                        localStorage.removeItem('currentlyUploadingFile');
                    }
                }
                setTimeout(() => {
                  this.countErrorCall = 0;
                }, 100);
            }
                }
      });
  }

  errorHandleSecene() {
    for (let i= this.globalIndex; i< this.chooseFileName[this.index].length; i++) {
        this.isFileNotUploaded[this.index][i] = true;
        this.validationError[this.index][i] = "Error from Server";
        this.uploadedDocGuid[this.index][i] = "NA";        
      }
      this.disableUploadinCard = false;
  }

  /**
   * This method is called when user selects x number of documents from the upload window
   */
  getChoosefile($event, index) {
    let nu = [];
    nu = [...this.chooseFileName[index]];
  this.disableUploadinCard = true;
  
    localStorage.setItem('subCount','')
      if ($event.srcElement.files) {
          let count = 0;
          this.hideDashed[index] = true;
          let totalFiles = [];
          const filesArray = Array.from($event.srcElement.files) as File[];
          console.log("files array are like this", filesArray, this.chooseFileName[index]);
          filesArray.sort((file1, file2) => file1.size - file2.size);
          for (let i of filesArray) {
              totalFiles.push(i);
              this.chooseFileName[index].push(i);
          }
          console.log("choosefilename", this.chooseFileName[index]);
          this.statusText = this.getDocketStatus();
          count =this.uploadFile(totalFiles[count], index, count);
          let newFileIndex = this.isProgress[index]?.length;
          const interval = setInterval(() => {
            
            if(localStorage.getItem("trigerUploadNext") === "true"){
                  if(count < totalFiles?.length){
                      newFileIndex = this.uploadedDocGuid[index]?.length;
                      let totalDocIDs = [];
                      for (let i = 0; i < this.uploadedDocId?.length; i++) {
                          for (let j = 0; j < this.uploadedDocId[i]?.length; j++) {
                              totalDocIDs.push(this.uploadedDocId[i][j]);
                          }
                      }
                      console.log("break point 0", totalDocIDs);
                      if (
                          this.fileProgress[index][newFileIndex - 1] === 100 &&
                          totalDocIDs?.includes(this.uploadedDocId[index][newFileIndex - 1]) &&
                          localStorage.getItem("isFileUploaded") === "true" &&
                          this.isFileNotUploaded[index][newFileIndex - 1] === false
                      ) {
                          if (
                              !this.fileProgress[index][newFileIndex] ||
                              (this.fileProgress[index][newFileIndex] &&
                                  this.fileProgress[index][newFileIndex] === 100) ||
                              !this.isFileNotUploaded[index][newFileIndex] ||
                              (this.isFileNotUploaded[index][newFileIndex] &&
                                  this.isFileNotUploaded[index][newFileIndex] === false)
                          ) {
                        localStorage.setItem("trigerUploadNext", "false");
                        console.log("break point 1");
                        if (this.networkError) {
                            clearInterval(interval);
                            this.disableUploadinCard = false;
                        }
                        count =this.uploadFile(totalFiles[count], index, count);
                      
                          }
                          console.log("break point 1(outer)");
                      } else if (
                          (this.uploadedDocGuid[index][newFileIndex - 1] === "NA" ||
                          this.uploadedDocGuid[index][newFileIndex] === "NA") &&
                          localStorage.getItem('currentlyUploadingFile') !== totalFiles[count]?.name
                      ) {
                    localStorage.setItem("trigerUploadNext", "false");
                    console.log("break point 2");
                    if (this.networkError) {
                        clearInterval(interval);
                        this.disableUploadinCard = false;
                    }
                    count =this.uploadFile(totalFiles[count], index, count);
                  } else if (
                    count < totalFiles?.length &&
                    this.fileProgress[index][newFileIndex - 1] === 100 &&
                    totalDocIDs?.includes(this.uploadedDocId[index][newFileIndex - 1]) &&
                    localStorage.getItem("isFileUploaded") === "false" &&
                    this.isFileNotUploaded[index][newFileIndex - 1] === false
                    ) {
                        console.log("break point 3");
                        if (this.networkError) {
                            clearInterval(interval);
                            this.disableUploadinCard = false;
                        }
                      localStorage.setItem("isFileUploaded", "true");
                    }
                  }
                  else{
                  localStorage.setItem("trigerUploadNext", "false");
                  clearInterval(interval);
                  this.disableUploadinCard = false;
                }

              }
            }, 700);

      }
  }


  /**
   * this method is used to convert the file size into kilobytes and this help in validating the file size of the document.
   *
   * @param maximumFileSize
   * @param maximumFileSizeUnit
   * @returns Kilobytes
   */

  convertToKilobytes(maximumFileSize: number, maximumFileSizeUnit: string): number {
    const units = {
      KB: 1,
      MB: 1024,
      GB: 1024 * 1024,
      TB: 1024 * 1024 * 1024
    };
      return maximumFileSize * units[maximumFileSizeUnit];
  }

  /**
   * This method is called to select which files are to be selected for upload and which files are not
   *
   * e.g. If a file is not supported by dox2u, then it is not be send to upload, and appropriate error response is to be sent to the user.
   */
  private uploadFile(file: any, index: any, count: number) {
    this.fileIsShown = false;
    localStorage.setItem('currentlyUploadingFile', file?.name || "");
      let extension = file?.name.split(".")?.at(-1)?.toUpperCase();
      let uploadLimit = this.convertToKilobytes(this.maxFileSize,this.maxFileSizeUnit);
      let subCount = Number(localStorage.getItem('subCount'))|| 0;
      if (((file.size / 1024) >= uploadLimit)) {
          let x:number = this.isProgress[index]?.length
          x+= subCount;
          this.isFileNotUploaded[index][x] = true;
          this.uploadedDocGuid[index][x] = "NA";
          this.isUpload = false;
          this.validationError[index][x] = "File size is too large";
          count++;
          subCount++;
          localStorage.setItem('subCount',String(subCount))
          localStorage.setItem("trigerUploadNext", "true");
      } else if (!this.avaliableExtensions?.includes(extension)) {
          let x:number = this.isProgress[index]?.length
          x+= subCount;
          this.isFileNotUploaded[index][x] = true;
          this.isUpload = false;
          this.uploadedDocGuid[index][x] = "NA";
          this.validationError[index][x] = "File Not Supported";
          count++;
          subCount++;
          localStorage.setItem('subCount',String(subCount))
          localStorage.setItem("trigerUploadNext", "true");
      } else {
        localStorage.setItem('subCount','')
          this.isUpload = true;
          let x = this.isProgress[index]?.length;
          this.uploadedFileSize[index][x] = this.bytesToSize(file?.size);
           this.uploadSubmit(index);
          count++;
      }
      return count;
  }

  /**
   * This method is called to convert the filesize to appropriate unit
   */
  bytesToSize(bytes: number) {
      let sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "n/a";
      let i = Math.floor(Math.log(bytes) / Math.log(1024));
      if (i == 0) return bytes + " " + sizes[i];
      return (bytes / Math.pow(1024, i)).toFixed(1) + " " + sizes[i];
  }

  /**
   * This method is called to flush previous arrays(from previously selected document), and
   *
   * fetch existing tags from the newly selected document
   */
  getmethodValue(
      id?,
      verifierEmailId?,
      ownerEmailID?,
      guid?,
      uploadedByDisplayNameResponse?,
      check?,
      extension?,
      docRenameListResponse?
  ) {
      if (this.countCall === 0) {
          var st = window.location.toString();
          var arr = st?.split("/");
          this.docidResponse = id;
          console.log("docidResponse 1", this.docidResponse);
          this.multipleDocError = this.docidResponse?.length > 1 ? true : false;
          this.verifierEmailidResponse = verifierEmailId;
          this.ownerEmailIdResponse = ownerEmailID;
          this.docGuidResponse = guid;
          this.formatExtensionResponse = extension;
          this.uploadedByDisplayNameResponse = uploadedByDisplayNameResponse;
          this.docRenameListResponse = docRenameListResponse;
          // this.SaveTagVerifyData();
          setTimeout(() => {
              this.getTagId("clickedTag");
              check == "myQueue"
                  ? (this.checkBulkTagHeader = true)
                  : (this.checkBulkTagHeader = false);
              this.textFieldArrayResponse = [];
              this.datefieldArrayResponse = [];
              this.dropdownArrayResponse = [];
              this.customArrayResponse = [];
              this.tagNote = "";
              for (let i = 0; i < this.uploadedByDisplayNameResponse?.length; i++) {
                  if (this.uploadedByDisplayNameResponse[i]?.includes("undefined")) {
                      let OriginalDisplayName =
                          this.uploadedByDisplayNameResponse[i]?.split("undefined")[1];
                      if (
                          OriginalDisplayName?.includes("(") &&
                          OriginalDisplayName?.includes(")")
                      ) {
                          OriginalDisplayName = OriginalDisplayName?.replace(
                              "(",
                              ""
                          ).replace(")", "");
                      }
                      if (OriginalDisplayName) {
                          this.uploadedByDisplayNameResponse[i] =
                              OriginalDisplayName.trim();
                      }
                  }
              }

              this.islabelResponse = [];
              this.isvalueResponse = [];
              this.isTextBorderShowResponse = [];
              this.isTextValueFocusedResponse = [];
              this.isDateBorderShowResponse = [];
              this.isDateValueFocusedResponse = [];
              this.isDropDownBorderShowResponse = [];
              this.isCustomBorderShowResponse = [];
              this.isCustomValueFocusedResponse = [];
              this.ShowDateResponse = [];
              this.ShowDate1Response = [];
              this.selectedResponse = [];
              this.ShowDateCustomResponse = [];
              this.checklabelResponse = [];
              this.checkvaluesResponse = [];

              localStorage.setItem(
                  "selectedDocsNumber",
                  String(this.docidResponse?.length)
              );

              if (!this.multipleDocError) {
                  let postdata = {
                      DocGuid: guid[0],
                      DocID: id[0],
                      // WsLink: arr[2],
                      Wsoid: Number(localStorage.getItem("localwsId")),
                      currentUser: localStorage.getItem("localloginId"),
                  };
                  this.data
                      .verifierDocDetailsService(postdata)
                      .pipe(
                          catchError((error) => {
                              this.logger.error(error);
                              return of(`Error: ${error}`);
                          })
                      )
                      .subscribe((customTags: any) => {
                          this.tagNote =
                              customTags.data.docverificationdetailslists[0]?.notes;
                          this.getOldTags(customTags.data.doctagsdetailslists);
                      });
              }

              if (!this.multipleDocError) {
                  let _data = {
                      wsoid: Number(this.workSpaceId),
                      docID: this.docidResponse.toString(),
                  };
                  this.data
                      .GetTagValuebyID(_data)
                      .pipe(
                          catchError((error) => {
                              this.logger.error(error);
                              return of(`Error: ${error}`);
                          })
                      )
                      .subscribe((response: any) => {
                          if (response.returnMessage !== "No Record Found") {
                              this.getSelectedDocTagData = response.data;
                              if (this.getSelectedDocTagData?.length > 0) {
                                  for (let i = 0; i < this.getSelectedDocTagData?.length; i++) {
                                      if (this.getSelectedDocTagData[i].tagValue === "") {
                                          this.singleTagsListResponse[i] =
                                              this.getSelectedDocTagData[i].tagLabel;
                                          this.singleTagsListResponse = [
                                              ...new Set(this.singleTagsListResponse),
                                          ];
                                      }
                                  }
                              }
                          }
                          if (this.singleTagsListResponse?.includes(undefined)) {
                              let index = this.singleTagsListResponse?.indexOf(undefined);
                              if (index !== -1) {
                                  this.singleTagsListResponse.splice(index, 1);
                              }
                          }
                      });
              } else {
                  this.singleTagsListResponse = [];
                  this.getUserDataSingleTag = "";
              }
          }, 300);
      }
  }

  /**
   * This method is called to fetch old tags (label and value) for the newly selected docket
   */
  getPreviousmethodValue() {
    this.docItid = Number(localStorage.getItem('docItid'));
      if (this.countCall === 0) {
          let postData;
          if (this.uniqueId) {
              postData = {
                  wsoid: Number(this.workSpaceId),
                  docItid: Number(this.docItid),
                  uniqueID: String(this.uniqueId),
                  flag: "",
              };
          } else {
              postData = {
                  wsoid: Number(this.workSpaceId),
                  docItid: this.docItid,
                  uniqueID: "",
                  flag: "new",
              };
          }
          this.docidResponse.push(this.docItid);
          console.log("doc response", this.docidResponse);
          this.data
              .GetDocItTagtemplateList(postData)
              .pipe(
                  catchError((error) => {
                      this.logger.error(error);
                      return of(`Error: ${error}`);
                  })
              )
              .subscribe((response: any) => {
                  let getSelectedDocTagData = response?.data;
                  this.getOldTags(response?.data);
                  if (getSelectedDocTagData?.length > 0) {
                      for (let i = 0; i < getSelectedDocTagData?.length; i++) {
                          if (getSelectedDocTagData[i].tagValue === "") {
                              this.singleTagsListResponse[i] =
                                  getSelectedDocTagData[i].tagLabel;
                              this.singleTagsListResponse = [
                                  ...new Set(this.singleTagsListResponse),
                              ];
                          }
                      }
                      if (this.singleTagsListResponse?.includes(undefined)) {
                          let index = this.singleTagsListResponse?.indexOf(undefined);
                          if (index !== -1) {
                              this.singleTagsListResponse.splice(index, 1);
                          }
                      }
                  }
              });
          this.countCall = 1;
          setTimeout(() => {
              this.countCall = 0;
          }, 1000);
      }
  }

  /**
   * This method is called to fetch old tags (label and value) for the newly selected document
   */
  getOldTags(val) {
      let text: any = [],
          date = [],
          dropdown = [];

      val.forEach((e) => {
          if (
              e.tagControlId == 1 &&
              (e.tagValue != "" || e.tempName === "General")
          ) {
              this.isText = true;
              e.tagValue = e.tagValue.slice(0, 40);
              text.push(e);
              this.ShowDateResponse.push(false);
              this.isTemplateTextResponse = [true].concat(
                  this.isTemplateTextResponse
              );
              this.canDateBorderShowResponse = [true].concat(
                  this.canDateBorderShowResponse
              );
              this.isTextBorderShowResponse = [false].concat(
                  this.isTextBorderShowResponse
              );
              if (e.tagValue === "") {
                  this.isTextValueFocusedResponse = [true].concat(
                      this.isTextValueFocusedResponse
                  );
              } else {
                  this.isTextValueFocusedResponse = [false].concat(
                      this.isTextValueFocusedResponse
                  );
              }
              // this.isTextValueFocusedResponse = [true].concat(this.isTextValueFocusedResponse);
          } else if (
              e.tagControlId == 2 &&
              (e.tagValue != "" || e.tempName === "General")
          ) {
              this.isDate = true;
              if (
                  e.tagValue.includes("null") ||
                  e.tagValue === "Invalid date NaN,NaN" ||
                  e.tagValue === null
              ) {
                  e.tagValue = "";
              }
              if (e.tagValue !== "") {
                  e.tagValue = String(
                      new DatePipe("en-US").transform(e.tagValue, "YYYY-MM-dd")
                  );
              }
              this.ShowDate1Response.push(true);
              date.push(e);
              this.isTemplateDateResponse = [true].concat(
                  this.isTemplateDateResponse
              );
              this.canDateBorderShowResponse = [true].concat(
                  this.canDateBorderShowResponse
              );
              this.isDateBorderShowResponse = [true].concat(
                  this.isDateBorderShowResponse
              );
              if (e.tagValue === "") {
                  this.isDateValueFocusedResponse = [true].concat(
                      this.isDateValueFocusedResponse
                  );
              } else {
                  this.isDateValueFocusedResponse = [false].concat(
                      this.isDateValueFocusedResponse
                  );
              }
              // this.isDateValueFocusedResponse = [true].concat(this.isDateValueFocusedResponse);
          } else if (
              e.tagControlId == 3 &&
              e.tagValue != "" &&
              !e.tagValue?.includes("||")
          ) {
              this.isOption = true;
              dropdown.push(e);
              this.isDropDownBorderShowResponse.push(true);
          }
      });

      this.textFieldArrayResponse = this.textFieldArrayResponse.concat(text);
      this.datefieldArrayResponse = this.datefieldArrayResponse.concat(date);
      this.dropdownArrayResponse = this.dropdownArrayResponse.concat(dropdown);
      for (let i = 0; i < this.dropdownArrayResponse?.length; i++) {
          if (this.dropdownArrayResponse[i].tagValue.includes("||")) {
              this.dropdownValueResponse[i] =
                  this.dropdownArrayResponse[i].tagValue.split("||");
          } else {
              this.dropdownValueResponse[i] = [
                  this.dropdownArrayResponse[i].tagValue,
              ];
              this.selectedResponse[i] = this.dropdownArrayResponse[i].tagValue;
          }
      }
      if (
          this.textFieldArrayResponse?.length === 0 &&
          this.datefieldArrayResponse?.length === 0
      ) {
          this.hidecard = true;
      } else {
          this.hidecard = false;
      }
      const e = new Event("mouseenter");
      const element = document.querySelector("#clickedTag");
      if (element && e) {
          element.dispatchEvent(e);
      }
      /**
      * tag insert api unnecssary implemented on refresh and docket opening
      */
     //this.SaveTagVerifyData();
  }

  /**
   * This method is called to get all indices of an element in present the given array
   */
  getAllIndexes(arr, val) {
      let indexes = [],
          i;
      for (i = 0; i < arr?.length; i++) if (arr[i] === val) indexes.push(i);
      return indexes.slice(0, -1);
  }

  /**
   * This method is called to get notes for the selected document
   */
  getNoteData(data?) {
      this.tagNote = "";
      let detail = {
          wsoid:
              Number(this.workSpaceId) || parseInt(localStorage.getItem("localwsId")),
          docId: data.docId,
          CreatedBy: this.createdBy,
          guid: this.getguid,
          DocGuid: data.docGuidResponse,
      };
      this.details
          .getdetailsdata(detail)
          .pipe(
              catchError((error) => {
                  this.logger.error(error);
                  return of(`Error: ${error}`);
              })
          )
          .subscribe((response: any) => {
              this.tagNote = response.data?.documentDetailsList[0]?.notes;
          });
  }

  /**
   * This method is called to remove (unfilled) label/value from the docket response
   */
  removeTagValueResponse(i) {
      if (this.islabelResponse?.at(i) && this.islabelResponse?.at(i) !== "") {
          this.islabelResponse.splice(i, 1);
      } else if (this.islabelResponse?.length > i + 1) {
          let remaningLabels = this.islabelResponse.slice(i);
          remaningLabels.shift();
          this.islabelResponse = this.islabelResponse
              .slice(0, i)
              .concat(remaningLabels);
      }

      if (this.isvalueResponse.at(i) && this.isvalueResponse.at(i) !== "") {
          this.isvalueResponse.splice(i, 1);
      } else if (this.isvalueResponse?.length > i + 1) {
          let remainingValues = this.isvalueResponse.slice(i);
          remainingValues.shift();
          this.isvalueResponse = this.isvalueResponse
              .slice(0, i)
              .concat(remainingValues);
      }

      this.customArrayResponse.splice(i, 1);
      this.ShowDateCustomResponse.splice(i, 1);
      this.isCustomBorderShowResponse.splice(i, 1);
      this.isCustomValueFocusedResponse.splice(i, 1);
  }

  /**
   * This method is called to remove single-value tag from the docket response
   */
  removeChipResponse(tag) {
      let index = this.singleTagsListResponse.indexOf(tag);
      if (index !== -1) {
          this.singleTagsListResponse.splice(index, 1);
          this.getUserDataSingleTag = "";
      }
      this.SaveTagVerifyData();
  }

  /**
   * This method is called to remove (filled, text-type) label-value from the docket response
   */
  removeTextTagResponse(tag, type) {
      let template = {
          tempId: this.textFieldArrayResponse.tempId,
          tagLabel: "",
          tagValue: "",
          createdBy: this.createdBy,
          tagcontrolid: "",
          optionvalue: "",
      };
      this.ShowDateResponse.splice(tag, 1);
      this.textFieldArrayResponse.splice(tag, 1);
      this.deleteField = true;
      this.SaveTagVerifyData(template, type, tag);
      if (
          this.textFieldArrayResponse?.length === 0 &&
          this.datefieldArrayResponse?.length === 0
      ) {
          this.addTAgValueResponse();
      }
  }

  /**
   * This method is called to remove (filled, date-type) label-value from the docket response
   */
  removeDateTagResponse(tag, type) {
      let template = {
          tempId: this.datefieldArrayResponse.tempId,
          tagLabel: "",
          tagValue: "",
          createdBy: this.createdBy,
          tagcontrolid: "",
          optionvalue: "",
      };
      this.ShowDate1Response.splice(tag, 1);
      this.datefieldArrayResponse.splice(tag, 1);
      this.deleteField = true;
      this.SaveTagVerifyData(template, type, tag);
      if (
          this.textFieldArrayResponse?.length === 0 &&
          this.datefieldArrayResponse?.length === 0
      ) {
          this.addTAgValueResponse();
      }
  }

  /**
   * This method is called to remove (filled, dropdown-type) label-value from the docket response
   */
  removeDropdownTagResponse(tag, type) {
      let template = {
          tempId: this.dropdownArrayResponse.tempId,
          tagLabel: "",
          tagValue: "",
          createdBy: this.createdBy,
          tagcontrolid: "",
          optionvalue: "",
      };
      this.dropdownArrayResponse.splice(tag, 1);
      this.deleteField = true;
      this.SaveTagVerifyData(template, type, tag);
  }

  /**
   * This method is called to verify the document
   */
  verifyDocument(dialogModal: TemplateRef<any>, _closeOnBackdropClick: boolean) {
      localStorage.setItem("saved", "false");
      if (!this.getUserData && !this.isModalHidden) {
          this.dialogRef = this.dialogService.open(dialogModal, {
              closeOnBackdropClick: false,
              autoFocus: true,
              closeOnEsc: false,
          });
      } else {
          this.verifyFlag = true;
          this.SaveTagVerifyData();
      }
  }

  /**
   * This method is called to check if a non-english character is typed in the single value field
   */
  checkNonEnglishResponse(event) {
      if (event.charCode > 255) {
          event.preventDefault();
      }
  }

  /**
   * This method is called to make sure text length (single-value tags) does not cross max-length of 15
   */
  checkTagLengthResponse(event) {
      let singleTagValueOnKeyPress =
          event?.target?.value.trim() || event?.target?.textContent.trim();
      if (
          (event.keyCode !== 13 || event.keyCode !== 32) &&
          singleTagValueOnKeyPress?.length >= 15
      ) {
          event.preventDefault();
      }
  }

  /**
   * This method is called to make sure `Label` in label-value (tags) does not cross max-length of 15
   */
  checkLabelLengthResponse(event) {
      let LabelDataOnKeyPress =
          event?.target?.value.trim() || event?.target?.textContent.trim();
      if (LabelDataOnKeyPress?.length >= 15) {
          event.preventDefault();
      }
  }

  /**
   * This method is called when document is verified
   */
  onDocumentVerifierUpdate() {
      this.SaveTagVerifyData();
  }

  /**
   * This method is called to hide tag panel (verify without tags) modal
   */
  hideModal() {
      let canVerifyWithoutTags = localStorage.getItem("isModalHidden");
      if (canVerifyWithoutTags) {
          if (canVerifyWithoutTags === "true") {
              this.isModalHidden = false;
          } else if (canVerifyWithoutTags === "false") {
              this.isModalHidden = true;
          }
      } else {
          this.isModalHidden = !this.isModalHidden;
      }
      localStorage.setItem("isModalHidden", String(this.isModalHidden));
  }

  /**
   * This method is called when user wants to leave docket response window
   */
  leaveResponse(_event?) {
      const element = document.getElementById("clickedTag");
      let isMenuOpen = false;
      this.renderer.listen("window", "click", (e) => {
          if (
              (window.innerWidth >= 1920 &&
                  e.x < window.innerWidth - 500 &&
                  e.y < 200) ||
              (window.innerWidth < 1920 && e.x < window.innerWidth - 450 && e.y < 180)
          ) {
              isMenuOpen = true;
              // this.SaveTagVerifyData()
          }
      });
  }

  /**
   * This method is called to make sure tag 'value' text length does not cross length 40.
   */
  tagValueInputResponse(tagValue: string, textarea: string, event, type?, i?) {
    localStorage.setItem("documentId", this.sharedService.documentId);
      if (textarea?.length <= 40) {
          tagValue = textarea;
      } else {
          window.event.cancelBubble = true;
          event.returnValue = false;
      }
      if (type === "custom") {
          this.customArrayResponse[i].tagValue = tagValue;
      }
      return tagValue;
  }

  /**
   * This method is called whenever user presses a key
   *
   * This is user mainly to limit the user from entering more than 40 characters in the `value` field
   */
  @HostListener("document:keypress", ["$event"])
  handleKeyboardEventResponse(event, i, type): void {
      const eventTarget = event.target;
      if (type === "text") {
          if (
              eventTarget &&
              (eventTarget.value?.length > 40 ||
                  eventTarget.textContent?.length > 40 ||
                  eventTarget.innerHTML?.length > 40)
          ) {
              event.preventDefault();
          } else if (event.which !== 13 || event.which !== 32) {
              this.textFieldArrayResponse[i].tagValue =
                  eventTarget.value || eventTarget.textContent || eventTarget.innerHTML;
              if (this.textFieldArrayResponse[i].tagValue.at(-1) !== event.key) {
                  // this.textFieldArrayResponse[i].tagValue = this.textFieldArrayResponse[i].tagValue + (event.key);
              }
          }
      } else if (type === "date") {
          if (
              eventTarget &&
              (eventTarget.value?.length > 40 ||
                  eventTarget.textContent?.length > 40 ||
                  eventTarget.innerHTML?.length > 40)
          ) {
              event.preventDefault();
          } else if (event.which !== 13 || event.which !== 32) {
              this.datefieldArrayResponse[i].tagValue =
                  eventTarget.value || eventTarget.textContent || eventTarget.innerHTML;
          }
      } else if (type === "custom") {
          if (
              eventTarget &&
              (eventTarget.value?.length > 40 ||
                  eventTarget.textContent?.length > 40 ||
                  eventTarget.innerHTML?.length > 40)
          ) {
              event.preventDefault();
          } else if (event.which !== 13 || event.which !== 32) {
              this.isvalueResponse[i] =
                  eventTarget.value || eventTarget.textContent || eventTarget.innerHTML;
          }
      }
  }

  /**
   * This method is called to send audit logs whenever user updates tags for the docket response
   */
  updateDocAuditLog(operation?, _serial?) {
      let postData = [];
      let currentIp = localStorage.getItem("ipAddress");
      for (let i = 0; i < this.docidResponse?.length; i++) {
          postData[i] = {
              wsoid: Number(this.workSpaceId),
              category: "Document",
              activity: "Document View",
              logMessage:
                  `<b>${this.docRenameListResponse[i]}.${this.formatExtensionResponse[
                      i
                  ].toLowerCase()}</b>` +
                  operation +
                  `<b>${this.uploadedByDisplayNameResponse[i]}</b>`,
              createdBy: this.createdBy,
              docId: Number(this.docidResponse[i]),
              database: "get2doxMaster",
              // "serialNo": serial,
              docName: this.docRenameListResponse[i],
              userName: localStorage.getItem("noteuserfullname"),
              guid: this.docGuidResponse[i],
              IpAddress: currentIp,
              IsIntegrationViewer: 0
              
          };
      }
      for (let i = 0; i < postData?.length; i++) {
          this.data
              .viewDocsAuditLog(postData[i])
              .pipe(
                  catchError((error) => {
                      this.logger.error(error);
                      return of(`Error: ${error}`);
                  })
              )
              .subscribe();
      }
  }
  //John Doe (name of the user who created the Docket) has generated a Docket for the (Docket Type name) Docket Type

 async updateDocAuditLogs(value:boolean,str:string) {
      this.workSpaceId = localStorage.getItem("localwsId");
      let name = localStorage.getItem("WsdisplayName");
      let currentIp = localStorage.getItem("ipAddress");
      this.createdBy = localStorage.getItem("localloginId");
      let currentDocketDetails = this.getList.find(docket => docket.uniqueID === this.uniqueId);
      if(value){
        let postData = {
          wsoid: Number(this.workSpaceId),
          category: "Dockets",
          activity: "Docket sharing changed ",
          logMessage:`<b>${name}</b>  has changed the sharing of the Docket <b>${this.uniqueId}</b> from <b>${this.oldShareing}</b> to <b>${this.newShareing}</b>`,
          createdBy: this.createdBy,
          docId: currentDocketDetails.docItRid,
          docitRID :currentDocketDetails.docItRid,
          database: "get2doxMaster",
          docName: "",
          userName: localStorage.getItem("noteuserfullname"),
          guid: "",
          IpAddress: currentIp,
          IsIntegrationViewer: 0
          
      };
      this.data.viewDocsAuditLog(postData).pipe(
              catchError((error) => {
                  this.logger.error(error);
                  return of(`Error: ${error}`);
              })
          ).subscribe();

      }
      else if(str === "uidchange"){
        let postData = {
            wsoid: Number(this.workSpaceId),
            category: "Dockets",
            activity: "Docket sharing changed ",
            // logMessage:`<b>${name}</b>  has changed the UID of a Docket created in the <b> ${this.openedDocketTypeName}</b> from <b> ${this.uniqueId} </b> to <b>${this.uidInputValue.trim()}  </b>`,
            createdBy: this.createdBy,
            docId: 0,
            database: "get2doxMaster",
            docName: "",
            userName: localStorage.getItem("noteuserfullname"),
            guid: "",
            IpAddress: currentIp,
            IsIntegrationViewer: 0
            
        };
       await this.data.viewDocsAuditLog(postData).pipe(
                catchError((error) => {
                    this.logger.error(error);
                    return of(`Error: ${error}`);
                })
            ).toPromise();

      }
      else{
        let postData = {
            wsoid: Number(this.workSpaceId),
            category: "D-List",
            activity: "Document Created",
            // logMessage:
            //     `<b>"${localStorage.getItem("noteuserfullname")}"</b>` +
            //     " " +
            //     "has generated a Docket for the" +
            //     " " +
            //     `<b>${localStorage.getItem("cellData")?.split(",")[0]}</b>`,
            createdBy: this.createdBy,
            docId: Number(0),
            database: "get2doxMaster",
            // "serialNo" : serial,
            docName: "",
            userName: localStorage.getItem("noteuserfullname"),
            guid: "",
            IpAddress: currentIp,
            IsIntegrationViewer: 0
            
        };
        this.data.viewDocsAuditLog(postData).pipe(
              catchError((error) => {
                  this.logger.error(error);
                  return of(`Error: ${error}`);
              })
          ).subscribe();
      }
  }
  /**
   * This method is called to save date value when user selects a date from the date picker
   */
  dateChange(event, i) {
      let datePickedArray = event?.toDateString().split(" ");
      let datePickedString =
          datePickedArray[2] + " " + datePickedArray[1] + " " + datePickedArray[3];
      this.datefieldArrayResponse[i].tagValue = datePickedString;
  }

  /**
   * This method is called whenever user focuses out of the main bulk-tag panel(bulk-tag div)
   */
  @HostListener("document:blur", ["$event"])
  checkBlur() {
      // this.SaveTagVerifyData();
  }

  /**
   * This method is called when user focuses in/out of the custom label field(unfilled)
   */
  customValueFocusedResponse(i, _type, event?) {
      if (
          (event?.target?.value && event?.target?.value !== "") ||
          (event?.target?.textContent && event?.target?.textContent !== "")
      ) {
          this.isCustomValueFocusedResponse[i] = true;
          // let checkIndex = "customValue_" + [i] + "_2";
          // document.getElementById(checkIndex).focus();
      }
  }

  /**
   * This method is called when user focuses in/out of the custom value field(unfilled)
   */
  customValueBlurResponse(i, _type, event?) {
      if (
          (event?.target?.textContent && event?.target?.textContent !== "") ||
          (event?.target?.value && event?.target?.value !== "")
      ) {
          this.isCustomValueFocusedResponse[i] = false;
          // let checkIndex = "customValue_" + [i] + "_1";
          // document.getElementById(checkIndex).focus();
      }
  }

  /**
   * This method is called when user focuses in/out of the text label field(filled)
   */
  TextValueFocusedResponse(i, _type, event?) {
      if (
          (event?.target?.value && event?.target?.value !== "") ||
          (event?.target?.textContent && event?.target?.textContent !== "")
      ) {
          this.isTextValueFocusedResponse[i] = true;
          // let checkIndex = "textValue_" + [i] + "_2";
          // document.getElementById(checkIndex).focus();
      }
  }

  /**
   * This method is called when user focuses in/out of the text value field(filled)
   */
  TextValueBlurResponse(i, _type, event?) {
      if (
          (event?.target?.textContent && event?.target?.textContent !== "") ||
          (event?.target?.value && event?.target?.value !== "")
      ) {
          this.isTextValueFocusedResponse[i] = false;
          // let checkIndex = "textValue_" + [i] + "_1";
          // document.getElementById(checkIndex).focus();
      }
  }

  /**
   * This method is called when user focuses in/out of the date label field(filled)
   */
  DateValueFocusedResponse(i, _type, event?) {
      if (
          (event?.target?.value && event?.target?.value !== "") ||
          (event?.target?.textContent && event?.target?.textContent !== "")
      ) {
          this.isDateValueFocusedResponse[i] = true;
          // let checkIndex = "dateValue_" + [i] + "_2";
          // document.getElementById(checkIndex).focus();
      }
  }

  /**
   * This method is called when user focuses in/out of the date value field(filled)
   */
  DateValueBlurResponse(i, _type, event?) {
      if (
          (event?.target?.textContent && event?.target?.textContent !== "") ||
          (event?.target?.value && event?.target?.value !== "")
      ) {
          this.isDateValueFocusedResponse[i] = false;
          // let checkIndex = "dateValue_" + [i] + "_1";
          // document.getElementById(checkIndex).focus();
      }
  }

  /**
   * This method is called when user clicks on a three dot menu option for a document present in the docket response
   */
  showThreeDotMenuResponse(i, j) {
      localStorage.setItem('link', 'true')
      let threeDotReverse = false;
      if (!this.threeDotDlistShow[i]) {
          this.threeDotDlistShow[i] = [];
      } else {
          threeDotReverse = this.threeDotDlistShow[i][j];
          for (let x = 0; x < this.threeDotDlistShow[i]?.length; x++) {
              this.threeDotDlistShow[i][x] = false;
          }
      }
      this.threeDotDlistShow[i][j] = !threeDotReverse;
      if (this.threeDotDlistShow[i][j]) {
          this.selectedCardIndex = i;
      }
  }

  /**
   * This method is called when user presses preview panel for a document present in the docket repsonse
   *
   * Also sends an audit log entry about the same.
   */
  openPreviewResponse(i, j) {
        this.docUnderProcessing = true;
        this.docFormatNotSupported = false;
        this.docIsLockedOrCorrupt = false;
        this.docIsDisplayed = false;
        this.docParkingQueue = false;
        this.showProcessing = false;
      this.viewspinner = true;
    var st = window.location.toString();
    var arr = st?.split("/");
    console.log("uploadDocId", this.uploadedDocGuid[i][j], this.uploadedDocName[i][j], this.uploadedDocId[i][j]);
    let postdata = {
      "DocGuid": this.uploadedDocGuid[i][j],
      "DocID": Number(this.uploadedDocId[i][j]),
      // "WsLink": arr[2],
      "Wsoid": Number(localStorage.getItem('localwsId')),
      "currentUser": localStorage.getItem('localloginId'),
    }
    this.data1.verifierDocDetailsService(postdata).subscribe((customTags: any) => {
      if (customTags.isSuccess) {
        console.log("extension format", customTags);

        this.docStatus = customTags?.data.docverificationdetailslists[0].parserstatuscode;
          this.riser = 'page-fit';
     
      localStorage.setItem("Invokepreview", String(true));
      this.previewShow = true;
      this.viewspinner = true;
      this.docPreviewName = this.uploadedDocName[i][j].split(".")[0];
      this.formatExtension = customTags?.data.docverificationdetailslists[0].extension;
      console.log("the path of the document is", this.uploadedConvertedFile[i][j]);
      this.preview?.dockets(
          this.uploadedConvertedFile[i][j],
          this.uploadedFormat[i][j],
          this.uploadedDocName[i][j],
          this.uploadedDocId[i][j],
          this.uploadedDocGuid[i][j]
      );
      this.docItTag = false;
      this.sharedService.base64string.subscribe((res) => {
        if (this.docStatus == 'doc_21') {
            this.docUnderProcessing = true;
            this.docFormatNotSupported = false;
            this.docIsLockedOrCorrupt = false;
            this.docIsDisplayed = false;
            this.docParkingQueue = false;
            this.showProcessing = true;
          } else if (this.docStatus == 'doc_15'){
            this.docParkingQueue = true;
            this.docFormatNotSupported = false;
            this.docIsLockedOrCorrupt = false;
            this.docIsDisplayed = false;
            this.docUnderProcessing = false;
      
          } else if (this.docStatus == 'doc_22') {
            this.docIsLockedOrCorrupt = true;
            this.docFormatNotSupported = false;
            this.docUnderProcessing = false;
            this.docIsDisplayed = false;
            this.docParkingQueue = false;
          } else if (this.docStatus == 'doc_23') {
            this.docFormatNotSupported = true;
            this.docUnderProcessing = false;
            this.docIsLockedOrCorrupt = false;
            this.docIsDisplayed = false;
            this.docParkingQueue = false;
          }else{
            this.docIsLockedOrCorrupt = false;
            this.docFormatNotSupported = false;
            this.docUnderProcessing = false;
            this.docIsDisplayed = true;
            this.docParkingQueue = false;
            this.sourceFile = res;
            console.log("sourcefile", this.sourceFile)
          }
    })
      setTimeout(() => {
        if (!(this.docStatus == 'doc_21' || this.docStatus == 'doc_22' || this.docStatus == 'doc_23' || this.docStatus == 'doc_15')) {
              if (this.sourceFile) {
                  setTimeout(() => {
                    this.riser = "page-width"
                      this.viewspinner = false;
                      this.preview.updateDocAuditLog();
                  }, 300);
              }
        }
          this.cd.detectChanges();
      }, 600);
    }
})
  }

  /**
   * This method is called when user close the preview panel(if opened) in the response page
   */
  closePreviewResponse() {
      localStorage.setItem("Invokepreview", String(false));
      this.previewShow = false;
  }

  /**
   * This method is called to automaitcally verify the document uploaded in the docket response
   */
  DocItTagOpenResponse(i, j) {
      if (!this.docItTag) {
          this.tagVerify(i, j);
      }
      this.docItTag = true;
      this.previewShow = false;
      // this.previewShow=true;
  }

  /**
   * This method is called to check if user has upload rights
   *
   * Calls API `</api/RandomDocumentUpload/UploadRightsUserList>`
   */
  UploadRightsUserList() {
      var st = window.location.toString();
      var arr = st.split("/");
      let postData: any;
      postData = {
          Wsoid: Number(this.workSpaceId),
          WsLink: arr[2],
      };
      this.pendingRequest = this.data
          .UploadRightsUserListService(postData)
          .pipe(
              catchError((error) => {
                  this.logger.error(error);
                  return of(`Error: ${error}`);
              })
          )
          .subscribe((response: any) => {
              if (response.isSuccess) {
                  this.rightUserList = response.data;
                //   this.allUserLength = response.data?.length;
                  localStorage.setItem("userList", this.allUserLength);
              }
          });

          this.ownedService.searchAllUsersService(postData).subscribe((response : any)=>{
            let actualUsers = [];
            if(response.isSuccess){
            response.data.forEach(user=>{
              if(!user.roleName.includes("Tally") && user.emailId !== this.createdBy ){
                actualUsers.push(user.roleName);
              }
            })
            this.allUserLength = actualUsers.length;
        }
          })
  }

  /**
   * This method is called to check Share Status for the logged in user, against the current WS
   *
   * Calls API `</api/DocumentVerification/DocVerifyById>`
   */
  onVerifyDocDetails(params) {
      var st = window.location.toString();
      var arr = st.split("/");
      let postdata = {
          DocGuid: params.docGuid,
          DocID: params.docId,
          // "WsLink": arr[2],
          Wsoid: Number(localStorage.getItem("localwsId")),
          currentUser: localStorage.getItem("localloginId"),
      };
      this.data
          .verifierDocDetailsService(postdata)
          .pipe(
              catchError((error) => {
                  this.logger.error(error);
                  return of(`Error: ${error}`);
              })
          )
          .subscribe((customTags: any) => {
              if (customTags.isSuccess) {
                  this.isAllStatus =
                      customTags?.data.docverificationdetailslists[0].isAllUserShare;
                  this.sharedService.isShare.next(this.isAllStatus);
              }
          });
  }

  /**
   * This method is called to check if change shared status for a particular document uploaded in docket response
   *
   * Calls API `</api/GetUserDetailWithGroup/GetUserDetailWithGroup>`
   */
  getSharedWithData(docid) {
      let postData = {
          wsoid: Number(this.workSpaceId),
          docID: Number(docid),
          database: "get2doxMaster",
      };
      this.pendingRequest = this.data
          .getSharedWithModalDataForView(postData)
          .pipe(
              catchError((error) => {
                  this.logger.error(error);
                  return of(`Error: ${error}`);
              })
          )
          .subscribe((response: any) => {
              if (response) {
                  this.sharedWithUsers = response.data;
                  this.setUserGroupArr = this.sharedWithUsers;
                  /*
                   *radio button selection value checking
                   */
                  this.sharedService.getuserradio.next(true);
                  this.sharedService.selectedAccessEditor.next(response.data);
              } else {
                  /*
                   *radio button selection value checking
                   */
                  this.sharedService.getuserradio.next(false);
              }
          });
  }

  /**
   * This method is called to check if user has selected All users in shared status for a document
   */
  isSelectedAllUser($event) {
      this.isSelectedAllUsers = $event;
  }

  /**
   * This method is called to check shared group count
   */
  sharedGroupCount($event) {
      this.shareuserCount = $event;
  }

  /**
   * This method is called to check if all user radio button is checked
   */
  isCheckedAllUser($event) {
      this.isCheckedAllUsers = $event;
      this.isAllUserShare = $event;
  }

  /**
   * This method is called when "group" radio button is checked and user selects a `group` from the dropdown menu
   */
  sharedGroup($event) {
      this.setUserGroupArr = $event;
      if (this.setUserGroupArr?.length > 0 && !this.isCheckedAllUsers) {
          this.isAllUserShare = false;
      }
      if (this.setUserGroupArr.includes(this.verifierName)) {
          this.showVerifier = true;
      } else {
          this.showVerifier = false;
      }
  }

  /**
   * This method is called when "group" radio button is checked
   */
  isUserGrp($event) {
      this.isUsersGroup = $event;
  }

  /**
   * This method is called when user selects `No One` from the radio buttons
   */
  isSelectNoUser($event) {
      this.isSelectNoUsers = $event;
  }

  /**
   * This method is called when user invokes the sharing modal from a document
   *
   * Calls API `</api/DocumentVerification/DocVerifyById>`
   *
   * Calls APi `</api/DocumentVerification/GetUsersAndGroupsList>`
   */
  openShareCommonModal(i, j) {
      localStorage.setItem("docOwnerEmailId", this.uploadedDocOwnerName[i][j]);

      let postdata = {
          DocGuid: this.uploadedDocGuid[i][j],
          DocID: this.uploadedDocId[i][j],
          Wsoid: Number(this.workSpaceId),
          currentUser: this.createdBy,
      };

      this.data
          .verifierDocDetailsService(postdata)
          .pipe(
              catchError((error) => {
                  this.logger.error(error);
                  return of(`Error: ${error}`);
              })
          )
          .subscribe((response: any) => {
              this.sharedService.isShare.next(
                  response?.data?.docverificationdetailslists[0]?.isAllUserShare
              );
          });
      let tempdata = {
          owner: localStorage.getItem("localloginId"),
          verifier: localStorage.getItem("localloginId"),
          docId: this.uploadedDocId[i][j],
          bydefault: "",
      };
      setTimeout(() => {
          this.shareuse.shareUsersMqueueDocIt(tempdata,undefined,true);
      }, 150);
  }

  /**
   * This method is called when user drags and drops a file
   *
   * ? Currently not in use
   */
  allowDrop(ev) {
      ev.preventDefault();
  }

  /**
   * This method is called when user drags and drops a file
   *
   * ? Currently not in use
   */
  drop(ev) {
      ev.preventDefault();
      var data = ev.dataTransfer.getData("text");
      ev.target.appendChild(document.getElementById(data));
  }

  /**
   * This method is called when user has dropped a file in the drag and drop section
   *
   * ? Currently not in use
   */
  onFileDropped(ev) {
      ev.preventDefault();
      var data = ev.dataTransfer.getData("text");
      ev.target.appendChild(document.getElementById(data));
  }

  /**
   * This method is called to get the docket response's info
   *
   * Calls API `</api/docItTypeMaster/GetdocItTypeWithUniqueId>`
   *
   * Calls API `</api/docItTypeMaster/GetResponseList>`
   *
   * Calls API `</api/docItTypeMaster/GetdocItdocumentTypeMaster>`
   *
   * Also initializes several 2-d arrays that store various document data (such as docid, sharing/edit rights, etc.)
   */
  async getResponsePageData() {
      this.docItid = Number(localStorage.getItem("docItid"));
      this.sharedService.responseSelect
          .pipe(
              catchError((error) => {
                  this.logger.error(error);
                  return of(`Error: ${error}`);
              })
          )
          .subscribe((_cell: any) => {
              this.uniqueId = this.localData?.split(",")[0];
              this.docItid = this.localData?.split(",")[0];
              this.selectId();
          });
        //   this.localData = localStorage.getItem("celldata");
        //   this.uniqueId = this.localData?.split(",")[0];
        //   this.docItid = Number(this.localData?.split(",")[1]);
        this.docItid = Number(localStorage.getItem('docItid'));
      let getJobId = {
          wsoid: Number(this.workSpaceId),
          docitid: this.docItid,
          docItUid: 0,
      };
      await this.data
          .GetdocItTypeWithUniqueId(getJobId)
          .pipe(
              catchError((error) => {
                  this.logger.error(error);
                  return of(`Error: ${error}`);
              })
          )
          .toPromise().then((response: any) => {
              this.jobTitle = response.data[0]?.title;
          });
      let jobRequest = {
          wsoid: Number(this.workSpaceId),
          docItid: this.docItid,
          docItdtid: 0,
          uniqueID: "",
          pagecount: 1,
          numberofRow: 1000,
          loginIDEmailID: this.createdBy
      };
      await this.data
          .GetResponseList(jobRequest)
          .pipe(
              catchError((error) => {
                  this.logger.error(error);
                  return of(`Error: ${error}`);
              })
          )
          .toPromise().then((res: any) => {
              this.getList = res.data;
          });
          let rid = localStorage.getItem("cellDatas");
      let postDatas = {
          wsoid: Number(this.workSpaceId),
          docitid: this.docItid,
          docitdtid: 0,
          docItRId: 0
      };
      await this.data
          .getTitleList(postDatas)
          .pipe(
              catchError((error) => {
                  this.logger.error(error);
                  return of(`Error: ${error}`);
              })
          )
          .toPromise().then(async (response: any) => {
              if (response.isSuccess) {
                  // setTimeout(async () => {
                      await this.ValidateUIDAndDocket()
                      this.headerDoc = response.data;
                    //   this.headerDoc = this.headerDoc.map(obj => {
                    //     return {
                    //       ...obj,
                    //       isRequired: this.getRandomBoolean()
                    //     };
                    // })
                    console.log("obe",this.headerDoc);
                      this.docList = this.headerDoc;
                      this.totalRequiredFiedls = 0;
                      for (let i = 0; i < this.headerDoc?.length; i++) {
                        if(this.headerDoc[i].isRequired){
                                this.totalRequiredFiedls = this.totalRequiredFiedls +1;
                        }
                          this.titleList[i] = this.headerDoc[i]?.title;
                          this.chooseFileName[i] = [];
                          this.statusText = this.getDocketStatus();
                          this.fileProgress[i] = [];
                          this.isProgress[i] = [];
                          this.uploadedDocId[i] = [];
                          this.uploadedDocName[i] = [];
                          this.uploadedDocGuid[i] = [];
                          this.uploadedDocOwnerName[i] = [];
                          this.isFileNotUploaded[i] = [];
                          this.uploadedFileSize[i] = [];
                          this.uploadedConvertedFile[i] = [];
                          this.uploadedFormat[i] = [];
                          this.selectFileAccess[i] = [];
                          this.isUserDocOwner[i] = [];
                          this.validationError[i] = [];
                      }
                  // }, 100);
                  this.statusText = this.getDocketStatus();
              }
          });
  }


//   getRandomBoolean() {
//     return Math.random() < 0.5;
//   }
  getDocketStatus() {
    this.filledInfoCount = 0;
    this.filledInfoCountOptional = 0;
    this.headerDoc.forEach((element,index) => {
        if(element.isRequired){
            if(this.chooseFileName[index]?.length>0){
                this.filledInfoCount = this.filledInfoCount +1;
            }
        }
        else {
            if(this.chooseFileName[index]?.length>0){
                this.filledInfoCountOptional = this.filledInfoCountOptional +1;
            }
        }
    });
    
    if(this.totalRequiredFiedls == this.filledInfoCount){
        return 'Complete Docket';
    }
    else if(this.totalRequiredFiedls >0 && this.filledInfoCount == 0 ) {

        return 'Empty Docket'
    }
    else {

        return 'Incomplete Docket'
    }
    
  } 
  /**
   * This method is called to verify the uploaded documents
   */
  async tagVerify(i?, j?, key?) {
      this.isTrue = true;
      this.tagPanelOpen = false;
      this.hidedetail = true;
      if (key) {
          await this.getIds(i, j, key);
      } else {
          this.getIds(i, j);
      }
      localStorage.setItem("isResponse", "true");
      localStorage.setItem("saved", "true");
  }

  /**
   * This method is called to verify uploaded documents by using `BulkTagComponent` and sending info about the document
   */
  async getIds(i?, j?, key?) {
      const ids = [this.uploadedDocId[i][j]];
      const docVerifierEmailId = [localStorage.getItem("localloginId")];
      const docOwnerEmailId = [localStorage.getItem("localloginId")];
      const selectedGuid = [this.uploadedDocGuid[i][j]];
      const uploadedByDisplayName = [localStorage.getItem("noteuserfullname")];
      const extension = [];
      const docRenameList = [this.uploadedDocName[i][j]];
      this.selectedDocs.forEach((obj) => {
          ids.push(obj.docId);
          docVerifierEmailId.push(obj.verifiedByEmailId);
          docOwnerEmailId.push(obj.docOwnerEmailId);
          selectedGuid.push(obj.docGuid);
          uploadedByDisplayName.push(obj.uploadedByDisplayName);
          extension.push(obj.formatExtension);
          docRenameList.push(obj.neweDocRename);
      });
      localStorage.setItem("isFileUploaded", "false");
      localStorage.setItem("docId", String(ids.at(-1)));
      this.sharedService.documentIds = [...ids];
      setTimeout(() => {
          if (this.count === 0) {
              localStorage.setItem("selectedDocs", String(ids?.length));
              if (key) {
                  this.bulktag?.getmethodValue(
                      ids,
                      docVerifierEmailId,
                      docOwnerEmailId,
                      selectedGuid,
                      uploadedByDisplayName,
                      "",
                      extension,
                      docRenameList,
                      key
                  );
              } else {
                  this.bulktag?.getmethodValue(
                      ids,
                      docVerifierEmailId,
                      docOwnerEmailId,
                      selectedGuid,
                      uploadedByDisplayName,
                      "",
                      extension,
                      docRenameList
                  );
              }
          }
          this.count++;
          setTimeout(async () => {
              this.count = 0;
              if(key ==="unlink"){
              await this.allverifiedDocList("refresh");
              }
          }, 500);
        }, 300);
  }

  /**
   * This method is called when detail section of the document is to be opened
   */
  detailsection() {
      this.tagPanelOpen = true;
      this.hidedetail = !this.hidedetail;
      this.HideViewDetailresponse = !this.HideViewDetailresponse;
      localStorage.setItem("saved", "false");

      this.hideData();
      if (!this.hidedetail) {
          if (this.rowData) {
              if (this.selectedDocs?.length > 0) {
                  this.sendetailvalue(this.selectedDocs[this.selectedDocs?.length - 1]);
              } else {
                  this.sendetailvalue(this.senddetailsdata);
              }
          }
      }
      this.hidedetail ? this.crossdata() : "";
      this.hidedetail ? this.showData() : "";
  }

  /**
   * This method is called any panel for the document is to be hidden
   */
  hideData() {
      this.isSelectedOnceDetailsView = false;
      this.isDetailOpen = true;
      this.gridColumnApi.setColumnVisible("pageCount", false);
      this.gridColumnApi.setColumnVisible("uploadedByDisplayName", false);
      this.gridColumnApi.setColumnVisible("verifiedByDisplayName", false);
      setTimeout(() => {
          this.gridApi.sizeColumnsToFit();
      }, 1000);
      // setInterval(()=> {this.isDetailOpen ?  this.sizeToFit():'' }, 1000);
  }

  /**
   * This method is called to send detail to the `DetailAndActivityComponent`
   */
  sendetailvalue(data, isGuest?) {
      if (data) {
          localStorage.setItem("dRights", data);
          this.rightnavdatadetail = data;
          this.rightnavchild.actdata(this.rightnavdatadetail, isGuest);
      } else {
          this.rightnavdatadetail = "";
          this.rightnavchild.actdata(this.rightnavdatadetail, isGuest);
      }
  }

  /**
   * This method is called when the tag panel is closed
   */
  closeTagPanel() {
      this.docItTag = false;
      this.isDetailOpen = false;
      this.isTrue = false;
  }

  /**
   * This method is called when detail panel is closed
   */
  crossdata() {
      this.hidedetail = true;
      this.HideViewDetailresponse = true;
  }

  /**
   * This method is called when detail panel is opened
   */
  showData() {
      this.isDetailOpen = false;
      this.gridColumnApi?.setColumnVisible("pageCount", true);
      this.gridColumnApi?.setColumnVisible("uploadedByDisplayName", true);
      this.gridColumnApi?.setColumnVisible("verifiedByDisplayName", true);
      setTimeout(() => {
          this.gridApi?.sizeColumnsToFit();
      }, 100);
  }

  /**
   * This method is called when tag template panel is opened
   */
  onTagTemplateId(event) {
      this.tagTemplateId = event.tdId;
      let data = { title: "", description: "" };
      data["title"] = event.tempName;
      data["description"] = event.tempDescription;
      this.TemplateEditor?.patchValue(data);
      this.onGetTemplateDetails();
      this.docsVerification?.markAsDirty();
  }

  /**
   * This method is called to fetch template panel details
   *
   * Calls API `</api/DocumentVerification/TemplateDetailsById>`
   */
  onGetTemplateDetails() {
      if (this.tagTemplateId) {
          this.templateInput = [];
          var st = window.location.toString();
          var arr = st.split("/");
          let postData: any;
          postData = {
              Wsoid: Number(this.workSpaceId),
              WsLink: arr[2],
              TempId: this.tagTemplateId,
          };
          this.pendingRequest = this.data
              .templateDetailsService(postData)
              .pipe(
                  catchError((error) => {
                      this.logger.error(error);
                      return of(`Error: ${error}`);
                  })
              )
              .subscribe(
                  (response: any) => {
                      if (response.isSuccess) {
                          let id = 0;
                          response.data.forEach((e) => {
                              let template = {
                                  tagTempLabel: "",
                              };

                              if (e.tagControlId == 1) {
                                  template["inputType"] = "text";
                              } else if (e.tagControlId == 2) {
                                  template["inputType"] = "date";
                              } else if (e.tagControlId == 3) {
                                  template["inputType"] = "dropdown";
                              }
                              template.tagTempLabel = e.tagLabel;
                              if (
                                  typeof e.tagValue == undefined ||
                                  e.tagValue == null ||
                                  e.tagValue.trim() == ""
                              ) {
                                  template["tagTempValue"] = "";
                              } else if (e.tagControlId == 3) {
                                  let tagValue = e.tagValue.split("||");
                                  tagValue.unshift("Select");
                                  template["tagTempValue"] = tagValue;
                                  template["selectedValue"] = null;
                              } else {
                                  template["tagTempValue"] = e.tagValue;
                              }
                              template["id"] = id;
                              template["required"] = e.isRequired;
                              this.templateInput.push(template);
                              id++;
                          });
                          this.templateInput.forEach((e) => {
                              if (e.required) {
                                  return;
                              }
                          });

                          this.editTemplateInput = this.templateInput.slice();
                          for (let i = 0; i <= this.templateInput?.length - 1; i++) {
                              this.templateInput[i].checktagdrop = false;
                              if (this.templateInput[i].inputType == "date") {
                                  if (
                                      this.templateInput[i].tagTempValue ===
                                      "Invalid date NaN,NaN"
                                  ) {
                                      this.templateInput[i].tagTempValue = "";
                                  }
                                  if (
                                      this.templateInput[i].tagTempValue &&
                                      this.templateInput[i].tagTempValue !==
                                      "Invalid date NaN,NaN"
                                  ) {
                                      this.templateInput[i].tagTempValue = new Date(
                                          this.templateInput[i].tagTempValue
                                      );
                                  }
                              }
                              if (this.templateInput[i].inputType == "text") {
                                  this.templateInput[i].errormsg = "";
                                  this.templatechange(this.templateInput[i]);
                              }
                          }
                      }
                  },
                  (_error) => {
                      this.errormsg();
                  }
              );
      }
  }

  /**
   * This method is called to show any type of error message
   */
  private errormsg() {
      setTimeout(() => {
          this.blockUI.stop();
      }, 1000);
  }

  /**
   * This method is called when user edits the tag template panel
   */
  tagTemplateEdit($event: any) {
      this.isSubmitted = false;
      this.tagTemplateList($event.flag, $event);
  }

  /**
   * This method is called to populate tag template dropdown
   *
   * Calls API `</api/DocumentVerification/TemplateList>`
   */
  tagTemplateList(isRefresh, value?) {
      var st = window.location.toString();
      var arr = st.split("/");
      let postData = {
          wsoid: Number(this.workSpaceId),
          guid: this.createdBy,
      };
      this.pendingRequest = this.data
          .tagTemplateService(postData)
          .pipe(
              catchError((error) => {
                  this.logger.error(error);
                  return of(`Error: ${error}`);
              })
          )
          .subscribe(
              (response: any) => {
                  if (response.isSuccess == true) {
                      this.tagTemplate = response.data;
                      const filterOutTemp = this.tagTemplate.filter(
                          (al) => al["tempName"] === "No Template"
                      );
                      const filterOutNoTemp = this.tagTemplate.filter(
                          (al) => al["tempName"] !== "No Template"
                      );
                      filterOutNoTemp.sort((a, b) =>
                          a.tempName.localeCompare(b.tempName)
                      );
                      this.tagTemplate = [...filterOutNoTemp, ...filterOutTemp];
                      this.templateArray = response.data;
                      this.defaultTemplate = response.data.find((obj) => obj.isdefault);
                      this.selectedTemplate = this.defaultTemplate
                          ? this.defaultTemplate
                          : response.data[0];
                      if (value) {
                          for (let i = 0; i <= response.data?.length - 1; i++) {
                              if (value.tdId == response.data[i].tdId) {
                                  this.defaultTemplate = response.data[i];
                                  if (this.selectedTemplate.tdId !== value.tdId) {
                                      this.selectedTemplate = response.data[i];
                                  }
                              }
                          }
                      } else {
                          this.defaultTemplate = response.data.find((obj) => obj.isdefault);
                          this.selectedTemplate = this.defaultTemplate
                              ? this.defaultTemplate
                              : response.data[0];
                      }
                      this.tagTempList = this.templateArray.slice();
                      if (!isRefresh) {
                          this.tagTempList.sort((a, b) =>
                              a.tempName.localeCompare(b.tempName)
                          );
                          let inx = 0;
                          let inxGeneral = 0;
                          let inxNoTemplate = 0;
                          for (let user of this.tagTemplate) {
                              user.tempName;
                              user["tagTemplateDropdown"] = user.tempName;
                              if (user.tdId == this.docTemplateId) {
                                  if (!isRefresh && !this.defaultTemplate) {
                                      this.selectedTemplate = user;
                                      this.onTagTemplateId(this.selectedTemplate);
                                  }
                              }
                              if (user.tempName == "General") {
                                  inxGeneral = inx;
                              }
                              if (user.tempName == "No Template") {
                                  inxNoTemplate = inx;
                              }
                              inx = inx + 1;
                          }
                          if (this.defaultTemplate) {
                              this.onTagTemplateId(this.selectedTemplate);
                          } else {
                              this.onTagTemplateId(this.selectedTemplate);
                          }
                      } else {
                          this.selectedTemplate = value;
                          this.onTagTemplateId(this.selectedTemplate);
                      }
                  }
              },
              (_error) => {
                  this.errormsg();
              }
          );
  }

  /**
   * This method is called when document list is to be loaded, whether it be
   *
   * (a) docket-type related, i.e. All documents in a docket type (say Air Export),
   * (b) docket-response related, i.e. All documents in a docket response (say AE_0001), or
   * (c) All documents existing in the workspace
   *
   * Calls API `</api/AllWorkspaceDocument/AllWSDocit>`
   */
  async allverifiedDocList(flag?, numberofRows?) {
      this.currentFlag = flag;
      localStorage.setItem(
          "JobID",
         this.uidInputValue  || this.inputFormControl?.value || this.inputFormControl 
      );
      let postData: any;
      this.Spinnershow = true;
      if (flag == "tab2") {
          this.isRefresh = false;
          postData = {
              Wsoid: Number(this.workSpaceId),
              OriginalFileName: "",
              StartDate: "",
              EndDate: "",
              documentFormat: "",
              uploadedBy: "",
              verifiedBy: "",
              createdBy: this.createdBy,
              OwnedBy: "",
              docItId: 0,
              docitdtid: 0,
              numberofRow: Number(this.numberofRow) || Number(numberofRows),
              pagecount: 1,
          };
      } else if (flag == "refresh") {
          this.isRefresh = true;
          postData = {
              Wsoid: Number(this.workSpaceId),
              OriginalFileName: "",
              StartDate: "",
              EndDate: "",
              documentFormat: "",
              uploadedBy: "",
              verifiedBy: "",
              createdBy: this.createdBy,
              OwnedBy: "",
              docItId: Number(localStorage.getItem("docItid")),
              docitdtid: 0,
              uniqueValue: this.uidInputValue || this.inputFormControl?.value || this.inputFormControl,
              numberofRow: 1000,
              pagecount: 1,
          };
      } else {
          this.isRefresh = false;
          postData = {
              Wsoid: Number(this.workSpaceId),
              OriginalFileName: "",
              StartDate: "",
              EndDate: "",
              documentFormat: "",
              uploadedBy: "",
              verifiedBy: "",
              createdBy: this.createdBy,
              OwnedBy: "",
              docItId: Number(localStorage.getItem("docItid")),
              // "docitdtid": this.docList[this.selectedCardIndex]?.docItdtid,
              uniqueValue: this.uidInputValue || this.inputFormControl?.value || this.inputFormControl,
              numberofRow: Number(this.numberofRow) || Number(numberofRows),
              pagecount: 1,
          };
      }
      await this.allworkspace
          .allWsDocListService(postData)
          .pipe(
              catchError((error) => {
                  this.logger.error(error);
                  return of(`Error: ${error}`);
              })
          )
          .toPromise().then((response: any) => {
              if (response.isSuccess) {
                  this.totalDocuments = response?.data?.length;
                //   this.showSpinnerDockets = false;
                //   this.allworkspaceDoc = response.data;
                  this.allworkspaceDoc = this.dateTransform.transform(response)?.data;
                  let docIdList = [];
                  this.allworkspaceDoc = this.allworkspaceDoc.filter((item) => {
                      docIdList.push(item.docId);
                      if (item.isShare === "1" && flag == "tab2") {
                          return item;
                      } else if (item.isShare !== "1" && flag == "tab2") {
                          return;
                      } else if (item.isShare !== "1") {
                          item.isReadable = false;
                          return item;
                      } else {
                          return item;
                      }
                  });
                  this.searchList = this.allworkspaceDoc;

                  let previousDocIds = [];
                  if (flag !== "refresh") {
                      this.allworkspaceDoc = this.allworkspaceDoc.filter((item) => {
                          if (!previousDocIds?.includes(item.docId)) {
                              previousDocIds.push(item.docId);
                              return item;
                          } else {
                              return;
                          }
                      });
                  }
                  this.Spinnershow = false;
                  this.ownedValue = response?.data?.originalFileName;
                  this.documentName = response?.data?.docOwnerDisplayName;
                  this.docIdWs = response?.data?.docId;
                  this.startedData = response?.data?.addedDateTime?.split(" ")[0];
                  setTimeout(() => {
                      this.Check = 0;
                  }, 400);
                  setTimeout(async () => {
                      if (flag === "refresh") {
                         await this.bindFileToDocument();
                      }
                  }, 200);
              }
          });
  }

/**
 * function on closing the attach from WS modal
 */
  closeAttachWSModal() {
    this.fisrtTabActive = false;
    this.secondTabActive = false;
    this.thirdTabActive = false;
    this.pageCount = 1;
    this.documentIds=[];
    this.documentIdsShare=[];
    this.searchFilterOn = false;
    this.searchFilterSharedOn = false;
    this.pageCountSO = 0;
    this.pageCountSharedSO = 0;
    this.selectedDocumentIds = [];
    this.selectedDocumentId = [];
    this.selectedDocumentIdsShare = [];
  }
  /**
 * fumction for getting the owned by me list
 */
   getOwnedByList(flag?, numberRow?,pageCount?) {
    // this.allworkspaceDoc1 = [];
    this.Spinnershow1 = true;
    localStorage.setItem(
        "JobID",
        this.uidInputValue ||  this.inputFormControl?.value || this.inputFormControl 
    );
    let postData = {
            "Wsoid": Number(this.workSpaceId),
            "OriginalFileName": '',
            "startDate":  "",
            "endDate": "",
            "documentFormat": "",
            "uploadedBy": "",
            "verifiedBy": "",
            "createdBy": this.createdBy,
            "NumberofRow": Number(this.numberofRow) || numberRow,
            "Pagecount": Number(this.pageCount) || pageCount,
            "sortingstr": ""
            // "RowNumber":Number(this.selectedItem),
            // (this.selectedItem *) *
    }
    this.ownedService
    .getOwnedByMeDocListData(postData)
    .pipe(
        catchError((error) => {
            this.logger.error(error);
            return of(`Error: ${error}`);
        })
    )
    .toPromise().then((response: any) => {
        if (response.isSuccess) {
            this.totalDocuments = response?.data?.length;
            if(response.returnMessage?.toLowerCase().includes("no record found")){
                this.totalDocuments = 0;
            }
            // this.allworkspaceDoc1 = response.data;
            console.log("data for owned by me", this.allworkspaceDoc1);
            // this.allworkspaceDoc1= [...this.allworkspaceDoc1];
            // this.allworkspaceDoc1 = this.dateTransform.transform(response)?.data;
            this.dateTransform.transform(response)?.data.forEach((data)=>{
                this.allworkspaceDoc1 = [...this.allworkspaceDoc1, data];
            })
            console.log("length for owned by me", this.allworkspaceDoc1.length);
            
            let docIdList = [];
            this.allworkspaceDoc1 = this.allworkspaceDoc1.filter((item) => {
                docIdList.push(item.docId);
                if (item.isShare === "1" && flag == "tab2") {
                    return item;
                } else if (item.isShare !== "1" && flag == "tab2") {
                    return;
                } else if (item.isShare !== "1") {
                    item.isReadable = false;
                    return item;
                } else {
                    return item;
                }
            });
            this.searchList = this.allworkspaceDoc1;

            let previousDocIds = [];
            if (flag !== "refresh") {
                this.allworkspaceDoc1 = this.allworkspaceDoc1.filter((item) => {
                    if (!previousDocIds?.includes(item.docId)) {
                        previousDocIds.push(item.docId);
                        return item;
                    } else {
                        return;
                    }
                });
            }
            this.Spinnershow1 = false;
            this.ownedValue = response?.data?.originalFileName;
            this.documentName = response?.data?.docOwnerDisplayName;
            this.docIdWs = response?.data?.docId;
            this.startedData = response?.data?.addedDateTime?.split(" ")[0];
            setTimeout(() => {
                this.Check = 0;
            }, 400);
            setTimeout(async () => {
                if (flag === "refresh") {
                   await this.bindFileToDocument();
                }
            }, 200);
        }
        else{
            this.totalDocuments = 0;
        }
    });
  }
/**
 * function for getting the shared with me list
 */

  getSharedList(flag?, numberRow?,pageCount?) {
    this.Spinnershow1 = true;
    localStorage.setItem(
        "JobID",
        this.uidInputValue ||  this.inputFormControl?.value || this.inputFormControl 
    );
    let postData = {
            "Wsoid": Number(this.workSpaceId),
            "OriginalFileName": '',
            "startDate":  "",
            "endDate": "",
            "documentFormat": "",
            "uploadedBy": "",
            "verifiedBy": "",
            "createdBy": this.createdBy,
            "NumberofRow": Number(this.numberofRow) || numberRow,
            "Pagecount": Number(this.pageCount) || pageCount,
            "sortingstr": ""
            // "RowNumber":Number(this.selectedItem),
            // (this.selectedItem *) *
    }
    this.datanew
    .getSharedByMeDocListData(postData)
    .pipe(
        catchError((error) => {
            this.logger.error(error);
            return of(`Error: ${error}`);
        })
    )
    .toPromise().then((response: any) => {
        if (response.isSuccess) {
            this.totalDocumentsOwned += response?.data?.length;
            if(response.returnMessage?.toLowerCase().includes("no record found")){
                this.totalDocumentsOwned = 0;
            }
            // this.allworkspaceDoc1 = response.data;
            console.log("data for Share by me", this.allworkspaceDoc2);
            this.dateTransform.transform(response)?.data.forEach((data)=>{
                this.allworkspaceDoc2 = [...this.allworkspaceDoc2, data];
            })
            
            let docIdList = [];
            this.allworkspaceDoc2 = this.allworkspaceDoc2.filter((item) => {
                docIdList.push(item.docId);
                if (item.isShare === "1" && flag == "tab2") {
                    return item;
                } else if (item.isShare !== "1" && flag == "tab2") {
                    return;
                } else if (item.isShare !== "1") {
                    item.isReadable = false;
                    return item;
                } else {
                    return item;
                }
            });
            this.searchList = this.allworkspaceDoc2;

            let previousDocIds = [];
            if (flag !== "refresh") {
                this.allworkspaceDoc2 = this.allworkspaceDoc2.filter((item) => {
                    if (!previousDocIds?.includes(item.docId)) {
                        previousDocIds.push(item.docId);
                        return item;
                    } else {
                        return;
                    }
                });
            }
            this.Spinnershow1 = false;
            this.ownedValue = response?.data?.originalFileName;
            this.documentName = response?.data?.docOwnerDisplayName;
            this.docIdWs = response?.data?.docId;
            this.startedData = response?.data?.addedDateTime?.split(" ")[0];
            setTimeout(() => {
                this.Check = 0;
            }, 400);
            setTimeout(async () => {
                if (flag === "refresh") {
                   await this.bindFileToDocument();
                }
            }, 200);
        }
        else{
            this.totalDocumentsOwned = 0;
        }
    });
    this.cd.detectChanges();
  }

/**
 * function on back button arrow click
 */

  onBackBtnClick() {
    this.thirdTabActive= false;
    this.fisrtTabActive = true;
    this.secondTabActive=false;
    this.pageCount = 1;
    this.allworkspaceDoc1 = [];
    this.allworkspaceDoc2 = [];
    this.documentIds = [];
    this.documentIdsShare = [];
    this.selectedDocumentId = [];
    this.selectedDocumentIdsShare = [];
  }

  /**
   * This method is called when a document in `linked`, `unlinked`, or `deleted` from the docket response
   *
   * This can also call `deleteDoc()` method which is used to delete a document from the entire workspace
   */


  async getDocumentLinked(i?, j?, flag?, item?) {
    let cardColumnIndex:number;
    let currentDocumentSharingStatus=false;
    let currentCardIndex = this.selectedCardIndex;
      if (item) {
          var myFile = new File([item.originalFileName], item.originalFileName, {
              type: item.documentFormat,
          });
          this.chooseFileName[this.selectedCardIndex].push(myFile);
          this.statusText = this.getDocketStatus();
          let jIndex = this.chooseFileName[this.selectedCardIndex]?.length - 1;
          cardColumnIndex= jIndex;
          this.uploadedDocId[this.selectedCardIndex][jIndex] = item.docId;
          this.uploadedDocGuid[this.selectedCardIndex][jIndex] = item.docGuid;
          this.uploadedDocName[this.selectedCardIndex][jIndex] =
              item.originalFileName;
          this.uploadedDocOwnerName[this.selectedCardIndex][jIndex] =
              item.docOwnerEmailId;
          this.uploadedConvertedFile[this.selectedCardIndex][jIndex] =
              item.convertedPath;
          this.uploadedFormat[this.selectedCardIndex][jIndex] = item.documentFormat;
          console.log("isReadable", item);
          this.selectFileAccess[this.selectedCardIndex][jIndex] =
              item?.isReadable === true ? true : false;
          this.isUserDocOwner[this.selectedCardIndex][jIndex] =
              localStorage.getItem("noteuserfullname") === item.docOwnerName;
          this.hideDashed[this.selectedCardIndex] = true;
          this.isFileNotUploaded[this.selectedCardIndex][jIndex] = false;
          this.tagVerify(this.selectedCardIndex, jIndex, "upload");
          if(item.isallshare === "1"){
            currentDocumentSharingStatus = true;
          }

      }
      if (flag == "cell") {
          this.unlink = 1;
      }
      console.log("docket ids are", this.docList);
      let postData = {
          wsoid: Number(this.workSpaceId),
          docId: item?.docId.toString() || (this.uploadedDocId[i][j]).toString(),
          docitdtid: this.docList[this.selectedCardIndex]?.docItdtid,
          docItId: Number(localStorage.getItem("docItid")),
          unlink: this.unlink,
          flag: null,
          createdby: this.createdBy,
          uniqueID:this.uidInputValue || this.inputFormControl?.value || this.inputFormControl ,
      };
      if (flag === "unlink") {
          postData.flag = "unlink";
      } else if (flag === "delete") {
          postData.flag = "delete";
      }
      if (postData.docId) {
          this.data
              .linkDocument(postData)
              .pipe(
                  catchError((error) => {
                      this.logger.error(error);
                      return of(`Error: ${error}`);
                  })
              )
              .subscribe(async (_response: any) => {
                if(_response.isSuccess && flag === "cell"){
                  await this.sharingStatusCheck(this.docketShareStatus,currentDocumentSharingStatus,currentCardIndex,cardColumnIndex,_response.statusCode);
                  if(this.docketSharingFlagString.toLowerCase().includes('true')){
                    this.shareingMisMatchFound = true;
                  }
                  else{
                    this.shareingMisMatchFound = false;
                  }
                }
                if (_response.isSuccess) {
                    if (flag === "unlink") {
                        await this.tagVerify(i, j, "unlink");
                      }
                      if (this.unlink === 0 && flag === "delete") {
                        await this.deleteDoc(i, j);
    
                      }
                } else {
                    if(_response.statusCode == "C202") {
                        this.openModal(this.documentTypeDel, false);
                    }
                }
               
              });
      }
  }

//   insertOwnedByMe(item, index1) {
   
//     const index = this.documentIds.findIndex(docId => docId === item);
  
//     if (index !== -1) {
//       // If item exists in the array, remove it
//       this.documentIds.splice(index, 1);
//     } else {
//       // If item does not exist in the array, add it
//       this.documentIds.push(item);
        
//     }
//     this.toggleSelection(item)
    
//     // You can add any additional logic you need here
//     console.log('Updated documentIds:', this.documentIds);
//   }

//   toggleSelection(item: any) {
//     let cardColumnIndex:number;
//     let index : number;
//     if (item.docId != undefined) {
//         index = this.selectedDocumentIds.indexOf(item.docId);
//     }
//     if (item.docid != undefined) {
//         index = this.selectedDocumentIds.indexOf(item.docid);
//     }
    
//     if (index !== -1) {
//       this.selectedDocumentIds.splice(index, 1);
//     } else {
//       if (item.docId != undefined) {
//          this.selectedDocumentIds.push(item.docId);
//       }
//        if (item.docid != undefined) {
//             this.selectedDocumentIds.push(item.docid);
//         }
//     }

//     this.selectedDoclength = this.selectedDocumentIds.length;
//   }

//   isSelected(item: any): boolean {
//     return this.selectedDocumentIds.includes(item.docId ? item.docId : item.docid);
//   }

isSelected(item): boolean {
    return this.documentIds.includes(item);
  }

  onItemClicked(event: MouseEvent, item, index: number): void {
    if (event.ctrlKey) {
      this.handleCtrlClick(item);
    } else if (event.shiftKey) {
      this.handleShiftClick(index);
    } else {
      this.handleSingleClick(item);
    }
  }

  handleCtrlClick(item): void {
    const itemIndex = this.documentIds.indexOf(item);
    if (itemIndex === -1) {
      this.documentIds.push(item);
      this.selectedDocumentId = this.documentIds.map((res) => res.docId ? res.docId : res.docid);
    } else {
      this.documentIds.splice(itemIndex, 1);
      this.selectedDocumentId = this.documentIds.map((res) => res.docId ? res.docId : res.docid);
    }
    this.lastSelectedIndex = this.allworkspaceDoc1.indexOf(item);
  }

  handleShiftClick(index: number): void {
    if (this.lastSelectedIndex === -1) {
      this.documentIds = [this.allworkspaceDoc1[index]];
      this.selectedDocumentId = this.documentIds.map((res) => res.docId ? res.docId : res.docid);
    } else {
      const start = Math.min(this.lastSelectedIndex, index);
      const end = Math.max(this.lastSelectedIndex, index);
      this.documentIds = this.allworkspaceDoc1.slice(start, end + 1);
      this.selectedDocumentId = this.documentIds.map((res) => res.docId ? res.docId : res.docid);
    }
  }

  handleSingleClick(item): void {
    this.documentIds = [item];
    this.selectedDocumentId = this.documentIds.map((res) => res.docId ? res.docId : res.docid);
    this.lastSelectedIndex = this.allworkspaceDoc1.indexOf(item);
  }

  insertOwnedByMe(): void {
    // Process selected items
    console.log(this.documentIds);
    // Implement your logic here
  }

  onItemClickedShared(event: MouseEvent, item, index: number): void {
    if (event.ctrlKey) {
      this.handleCtrlClickShare(item);
    } else if (event.shiftKey) {
      this.handleShiftClickShare(index);
    } else {
      this.handleSingleClickShare(item);
    }
  }

  handleCtrlClickShare(item): void {
    const itemIndex = this.documentIdsShare.indexOf(item);
    if (itemIndex === -1) {
      this.documentIdsShare.push(item);
      this.selectedDocumentIdsShare = this.documentIdsShare.map((res) => res.docId ? res.docId : res.docid);
    } else {
      this.documentIdsShare.splice(itemIndex, 1);
      this.selectedDocumentIdsShare = this.documentIdsShare.map((res) => res.docId ? res.docId : res.docid);
    }
    this.lastSelectedIndexShare = this.allworkspaceDoc2.indexOf(item);
  }

  handleShiftClickShare(index: number): void {
    if (this.lastSelectedIndexShare === -1) {
      this.documentIdsShare = [this.allworkspaceDoc2[index]];
      this.selectedDocumentIdsShare = this.documentIdsShare.map((res) => res.docId ? res.docId : res.docid);
    } else {
      const start = Math.min(this.lastSelectedIndexShare, index);
      const end = Math.max(this.lastSelectedIndexShare, index);
      this.documentIdsShare = this.allworkspaceDoc2.slice(start, end + 1);
      this.selectedDocumentIdsShare = this.documentIdsShare.map((res) => res.docId ? res.docId : res.docid);
    }
  }

  handleSingleClickShare(item): void {
    this.documentIdsShare = [item];
    this.selectedDocumentIdsShare = this.documentIdsShare.map((res) => res.docId ? res.docId : res.docid);
    this.lastSelectedIndexShare = this.allworkspaceDoc2.indexOf(item);
  }
  
//   insertSharedBy(item) {
//          // Initialize the documentIds array
//          const index = this.documentIdsShare.findIndex(docId => docId === item);
  
//          if (index !== -1) {
//            // If item exists in the array, remove it
//            this.documentIdsShare.splice(index, 1);
//          } else {
//            // If item does not exist in the array, add it
//            this.documentIdsShare.push(item);
//          }
//          this.toggleSelectionShare(item)
         
//          // You can add any additional logic you need here
//          console.log('Updated documentIds:', this.documentIdsShare);
//   }

//   toggleSelectionShare(item) {
//     const index = this.selectedDocumentIdsShare.indexOf(item.docId ? item.docId : item.docid);

//     if (index !== -1) {
//       // If item is already selected, remove it from the array
//       this.selectedDocumentIdsShare.splice(index, 1);
//     } else {
//       // If item is not selected, add it to the array
//       this.selectedDocumentIdsShare.push(item.docId ? item.docId : item.docid);
//     }

//     this.selectedDoclengthShare = this.selectedDocumentIdsShare.length;
//     console.log("selectedDoclength", this.selectedDoclength)
//   }

  isSelectedShare(item: any): boolean {
    return item.docId ? this.selectedDocumentIdsShare.includes(item.docId) : this.selectedDocumentIdsShare.includes(item.docid);
  }

  isDocumentIdsEmpty(): boolean {
    return this.documentIds.length === 0;
  }

  isDocumentIdsShareEmpty(): boolean {
    return this.documentIdsShare.length === 0;
  }

  sendDataOwnedBy() {
    let docIds = this.documentIds.map((res) => res.docId ? res.docId : res.docid);
    const numbersString: string = docIds.join(', ');
    let currentDocumentSharingStatus = false;
    
    let cardColumnIndex:number;
    let currentCardIndex = this.selectedCardIndex;
    this.hideDashed[this.selectedCardIndex] = true;
    let postData = {
        wsoid: Number(this.workSpaceId),
        docId: numbersString,
        docitdtid: this.docList[this.selectedCardIndex]?.docItdtid,
        docItId: Number(localStorage.getItem("docItid")),
        unlink: this.unlink,
        flag: null,
        createdby: this.createdBy,
        uniqueID:this.uidInputValue  || this.inputFormControl?.value || this.inputFormControl,
    };
   
    if (postData.docId) {
        this.data
            .linkDocument(postData)
            .pipe(
                catchError((error) => {
                    this.logger.error(error);
                    return of(`Error: ${error}`);
                })
            )
            .subscribe(async (_response: any) => {
              if(_response.isSuccess){
                let dataStatus = _response.data;
                let statusCode = '';
                this.documentIds.forEach((res, index) => {
                    console.log("response is", res);
                    if (res) {
                        let myFile = new File([res.originalFileName ? res.originalFileName : res.originalfilename], res.originalFileName ? res.originalFileName : res.originalfilename, {
                            type: res.documentFormat ? res.documentFormat : res.format,
                        });
                        this.chooseFileName[this.selectedCardIndex].push(myFile);
                        console.log("choose linked file attached", this.chooseFileName[this.selectedCardIndex]);
                        let jIndex = this.chooseFileName[this.selectedCardIndex]?.length - 1;
                        cardColumnIndex= jIndex;
                        this.uploadedDocId[this.selectedCardIndex][jIndex] = res.docId ? res.docId : res.docid;
                        this.uploadedDocGuid[this.selectedCardIndex][jIndex] = res.docGuid ? res.docGuid : res.docguid;
                        this.uploadedDocName[this.selectedCardIndex][jIndex] =
                        res.originalFileName ? res.originalFileName : res.originalfilename;
                        this.uploadedDocOwnerName[this.selectedCardIndex][jIndex] =
                        res.docOwnerEmailId ? res.docOwnerEmailId : res.documentowner;
                        this.uploadedConvertedFile[this.selectedCardIndex][jIndex] =
                        res.convertedPath != null ? res.convertedPath : res.convertedpath;
                        console.log("isReadable is here", this.uploadedConvertedFile[this.selectedCardIndex][jIndex], res.convertedPath);
                        this.uploadedFormat[this.selectedCardIndex][jIndex] = res.documentFormat ? res.documentFormat : res.format;
                        console.log("isReadable", res);
                        this.selectFileAccess[this.selectedCardIndex][jIndex] =
                        res?.isReadable ? res?.isReadable : true;
                        this.isUserDocOwner[this.selectedCardIndex][jIndex] =
                            (localStorage.getItem("noteuserfullname") === res.docOwnerName ? localStorage.getItem("noteuserfullname") === res.docOwnerName : localStorage.getItem("noteuserfullname") === res.docownername);
                        this.hideDashed[this.selectedCardIndex] = true;
                        this.isFileNotUploaded[this.selectedCardIndex][jIndex] = false;
                        this.tagVerify(this.selectedCardIndex, jIndex, "upload");
                        if (res.isallshare != undefined) {
                            if (res.isallshare == "1") {
                                console.log("weniweg", res.isallshare);
                                currentDocumentSharingStatus = true
                            }
                        }
                        if (res.isallusershare != undefined) {
                            if (res.isallusershare == "True") {
                                console.log("weniweg", res.isallusershare);
                                currentDocumentSharingStatus = true
                            }
                        }
                        dataStatus.forEach((response) => {
                            if (response.docId == res.docId || response.docId == res.docid) {
                                statusCode = response.matchStatus;
                            }
                        });
                this.sharingStatusCheck(this.docketShareStatus,currentDocumentSharingStatus,currentCardIndex,cardColumnIndex,statusCode);
                if(this.docketSharingFlagString.toLowerCase().includes('true')){
                  this.shareingMisMatchFound = true;
                }
                else{
                  this.shareingMisMatchFound = false;
                }
                }
              })
              console.log("choose linked file attached 123", this.chooseFileName[this.selectedCardIndex]);
              } else {
                if(_response.statusCode == "C202") {
                    this.openModal(this.documentTypeDel, false);
                }
              }
              this.pageCount = 1;
              this.documentIds=[];
              this.documentIdsShare=[];
              this.searchFilterOn = false;
              this.searchFilterSharedOn = false;
              this.pageCountSO = 0;
              this.pageCountSharedSO = 0;
              this.selectedDocumentIds = [];
              this.selectedDocumentId = [];
              this.selectedDocumentIdsShare = [];
              this.secondTabActive = false;
            });
    }
  }

  sendDataSharedBy() {
    let docIds = this.documentIdsShare.map((res) => res.docId ? res.docId : res.docid);
    const numbersString: string = docIds.join(', ');
    let currentDocumentSharingStatus = false;
    
    let cardColumnIndex:number;
    let currentCardIndex = this.selectedCardIndex;
    this.hideDashed[this.selectedCardIndex] = true;
    let postData = { 
        wsoid: Number(this.workSpaceId),
        docId: numbersString,
        docitdtid: this.docList[this.selectedCardIndex]?.docItdtid,
        docItId: Number(localStorage.getItem("docItid")),
        unlink: this.unlink,
        flag: null,
        createdby: this.createdBy,
        uniqueID:this.uidInputValue ||  this.inputFormControl?.value || this.inputFormControl ,
    };
   
    if (postData.docId) {
        this.data
            .linkDocument(postData)
            .pipe(
                catchError((error) => {
                    this.logger.error(error);
                    return of(`Error: ${error}`);
                })
            )
            .subscribe(async (_response: any) => {
              if(_response.isSuccess){
                let dataStatus = _response.data;
                let statusCode = '';
                this.documentIdsShare.forEach((res, index) => {
                    if (res) {
                        let myFile = new File([res.originalFileName ? res.originalFileName : res.originalfilename], res.originalFileName ? res.originalFileName : res.originalfilename, {
                            type: res.documentFormat ? res.documentFormat : res.format,
                        });
                        this.chooseFileName[this.selectedCardIndex].push(myFile);
                        let jIndex = this.chooseFileName[this.selectedCardIndex]?.length - 1;
                        cardColumnIndex= jIndex;
                        this.uploadedDocId[this.selectedCardIndex][jIndex] = res.docId ? res.docId : res.docid;
                        this.uploadedDocGuid[this.selectedCardIndex][jIndex] = res.docGuid ? res.docGuid : res.docguid;
                        this.uploadedDocName[this.selectedCardIndex][jIndex] =
                        res.originalFileName ? res.originalFileName : res.originalfilename;
                        this.uploadedDocOwnerName[this.selectedCardIndex][jIndex] =
                        res.docOwnerEmailId ? res.docOwnerEmailId : res.documentowner;
                        this.uploadedConvertedFile[this.selectedCardIndex][jIndex] =
                        res.convertedPath != null ? res.convertedPath : res.uploadpath;
                        this.uploadedFormat[this.selectedCardIndex][jIndex] = res.documentFormat ? res.documentFormat : res.format;
                        console.log("isReadable", res);
                        this.selectFileAccess[this.selectedCardIndex][jIndex] =
                        res?.isReadable ? res?.isReadable : true;
                        this.isUserDocOwner[this.selectedCardIndex][jIndex] =
                            (localStorage.getItem("noteuserfullname") === res.docOwnerName ? localStorage.getItem("noteuserfullname") === res.docOwnerName : localStorage.getItem("noteuserfullname") === res.docownername);
                        this.hideDashed[this.selectedCardIndex] = true;
                        this.isFileNotUploaded[this.selectedCardIndex][jIndex] = false;
                        this.tagVerify(this.selectedCardIndex, jIndex, "upload");
                        if (res.isallshare != undefined) {
                            if (res.isallshare == "1") {
                                currentDocumentSharingStatus = true
                            }
                        }
                        if (res.isallusershare != undefined) {
                            if (res.isallusershare == "True") {
                                currentDocumentSharingStatus = true
                            }
                        }
                        dataStatus.forEach((response) => {
                            if (response.docId == res.docId || response.docId == res.docid) {
                                statusCode = response.matchStatus;
                            }
                        });
                this.sharingStatusCheck(this.docketShareStatus,currentDocumentSharingStatus,currentCardIndex,cardColumnIndex,statusCode);
                if(this.docketSharingFlagString.toLowerCase().includes('true')){
                  this.shareingMisMatchFound = true;
                }
                else{
                  this.shareingMisMatchFound = false;
                }
                }
              })
                
              } else {
                if(_response.statusCode == "C202") {
                    this.openModal(this.documentTypeDel, false);
                }
              }
              this.pageCount = 1;
              this.documentIds=[];
              this.documentIdsShare=[];
              this.searchFilterOn = false;
              this.searchFilterSharedOn = false;
              this.pageCountSO = 0;
              this.pageCountSharedSO = 0;
              this.selectedDocumentIds = [];
              this.selectedDocumentId = [];
              this.selectedDocumentIdsShare = [];
              this.thirdTabActive = false;
            });
    }
  }

  refreshPage() {
    window.location.reload();
  }

  searchUserFormOwned(value: string, flag) {
    this.allworkspaceDoc1 = this.searchList;
      this.searchValue = value;
      if (value == "") {
        this.searchFilterOn = false;
          this.getOwnedByList();
      } else if (!value) {
          this.allworkspaceDoc1 = this.searchData;
          this.regardingsearch = false;
          this.searchDataNew = this.allworkspaceDoc1;
      } else {
        this.allworkspaceDoc1 = [];
        this.searchFilterOn = true;
        this.searchOwnedByDocs(value);
      }
  }

  searchUserFormShared(value: string, flag) {
    this.allworkspaceDoc2 = this.searchList;
      this.searchValue = value;
      if (value == "") {
        this.searchFilterSharedOn = false;
          this.getSharedList();
      } else if (!value) {
          this.allworkspaceDoc2 = this.searchData;
          this.regardingsearch = false;
          this.searchDataNew = this.allworkspaceDoc2;
      } else {
        this.allworkspaceDoc2 = [];
        this.searchFilterSharedOn = true;
        this.searchSharedByDocs(value);
      }
  }

  searchOwnedByDocs(value, numberRow?, Pagecount?) {
    this.Spinnershow1 = true;
    localStorage.setItem(
        "JobID",
       this.uidInputValue ||  this.inputFormControl?.value || this.inputFormControl 
    );
    let postData = {
        "WSOID": this.workSpaceId.toString(),
        "format": '',
        "docownername": this.createdBy,
        "docrename":'',
        "FromDate": '',
        "ToDate":'',
        "EXTRACTED_TEXT": '',
        "searchingstring": value,
        "Tags": '',
        "Notes": '',
        "sharedBy": '',
        "groupid": "",
        "isAllUserShare": "",
        "LoginUserEmailID":this.createdBy,
        "Flag": "Advance",
        "strBoostField":"name",
        "CountPerPage" : Number(this.numberofRow) || numberRow,
        "PageNumber" : this.pageCountSO,
        "IsParserEnable" : this.deepSearch? 1:0,
  
      }
    this.normalService
    .SearchNormalFilter(postData)
    .pipe(
        catchError((error) => {
            this.logger.error(error);
            return of(`Error: ${error}`);
        })
    )
    .toPromise().then((response: any) => {
        if (response.isSuccess) {
            
            this.totalDocuments = response?.data?.length;
            if(response.returnMessage?.toLowerCase().includes("no record found")){
                this.totalDocuments = 0;
            }
            this.dateTransform.transformSearch(response.data)?.forEach((data)=>{
                this.allworkspaceDoc1 = [...this.allworkspaceDoc1, data];
            })
            console.log("length for owned by me", this.allworkspaceDoc1.length);
            
            this.searchList = this.allworkspaceDoc1;

            this.Spinnershow1 = false;
            this.ownedValue = response?.data?.originalFileName;
            this.documentName = response?.data?.docOwnerDisplayName;
            this.docIdWs = response?.data?.docId;
            this.startedData = response?.data?.addedDateTime?.split(" ")[0];
            setTimeout(() => {
                this.Check = 0;
            }, 400);
        }
        else{
            this.totalDocuments = 0;
            this.Spinnershow1 = false;
        }
            
    });
  }

  searchSharedByDocs(value, numberRow?, Pagecount?) {
    this.Spinnershow1 = true;
    localStorage.setItem(
        "JobID",
       this.uidInputValue  ||  this.inputFormControl?.value || this.inputFormControl 
    );
    let postData = {
        "WSOID": this.workSpaceId.toString(),
        "format": '',
        "docownername": '',
        "docrename":'',
        "FromDate": '',
        "ToDate":'',
        "EXTRACTED_TEXT": '',
        "searchingstring": value,
        "Tags": '',
        "Notes": '',
        "sharedBy": this.createdBy,
        "groupid": "",
        "isAllUserShare": "",
        "LoginUserEmailID":this.createdBy,
        "Flag": "Advance",
        "strBoostField":"name",
        "CountPerPage" : Number(this.numberofRow) || numberRow,
        "PageNumber" : this.pageCountSharedSO,
        "IsParserEnable" : this.deepSearch? 1:0,
  
      }
    this.normalService
    .SearchNormalFilter(postData)
    .pipe(
        catchError((error) => {
            this.logger.error(error);
            return of(`Error: ${error}`);
        })
    )
    .toPromise().then((response: any) => {
        if (response.isSuccess) {
            
            this.totalDocuments = response?.data?.length;
            if(response.returnMessage?.toLowerCase().includes("no record found")){
                this.totalDocuments = 0;
                this.Spinnershow1 = false;
            }
            this.dateTransform.transformSearch(response.data)?.forEach((data)=>{
                this.allworkspaceDoc2 = [...this.allworkspaceDoc2, data];
            })
            console.log("length for owned by me", this.allworkspaceDoc2.length);
            
            this.searchList = this.allworkspaceDoc2;

            this.Spinnershow1 = false;
            this.ownedValue = response?.data?.originalFileName;
            this.documentName = response?.data?.docOwnerDisplayName;
            this.docIdWs = response?.data?.docId;
            this.startedData = response?.data?.addedDateTime?.split(" ")[0];
            setTimeout(() => {
                this.Check = 0;
            }, 400);
        }
        else{
            this.totalDocuments = 0;
            this.Spinnershow1 = false;
        }
            
    });
  }

  proceedToUpload() {
    for (let i= this.globalIndex; i< this.chooseFileName[this.index].length; i++) {
        this.isFileNotUploaded[this.index][i] = true;
        this.validationError[this.index][i] = "Network Error";
        this.uploadedDocGuid[this.index][i] = "NA";        
      }
      this.disableUploadinCard = false;
  }

  confirmUnlink() {
    this.unlinkData(this.documentTypeUnlink, this.indx, this.unlinkFlag);
    // if (this.previewShow) {
    //     this.previewShow = false;
    // }
    // if (this.docItTag) {
    //     this.docItTag = false
    // }
  }

  /**
   * This method is called to delete the document from the docket response and the entire workspace
   */
  async deleteDoc(i, j) {
      var stval = window.location.toString();
      var arr = stval?.split("/");
      let postData: any;
      postData = {
          wsoid: Number(this.workSpaceId),
          docIdList: String(this.uploadedDocId[i][j]),
          originalFileNameList: this.uploadedDocName[i][j],
          modifiedBy: this.createdBy,
          modifiedName: this.WsdisplayName,
          separator: "|",
          wsLink: arr[2],
          docOwnerEmailId: localStorage.getItem("loginguid"),
          wsName: this.wsName,
          documentCount: "1",
          guid: this.getguid,
          DocGuid: this.uploadedDocGuid[i][j],
      };
      await this.AfterDelete(postData);
  }

  /**
   * Calls API `</api/OwnedByMe/AfterDeleteOwnedByMeDoc>`
   */
  async AfterDelete(data) {
      await this.deleteDocService
          .afterDeleteOwnedService(data)
          .pipe(
              catchError((error) => {
                  this.logger.error(error);
                  return of(`Error: ${error}`);
              })
          )
          .toPromise().then((response: any) => {
              if (response.isSuccess) {
              } else {
              }
          });
          setTimeout(async () => {
            await this.allverifiedDocList("refresh");
        }, 300);
  }

  /**
   * This method is called when user clicks on the upload button from the docket response
   */
  uploadClick(r) {
    this.isUploadClick = true;
    this.avaliableExtensions = '';
    this.format = '';
    this.formatAvailable = '';
    this.changedFormat = [];
    setTimeout(() => {
        var st = window.location.toString();
        var arr = st?.split('/');
        let postData = {
          "Wslink": arr[2],
            "emailId": localStorage.getItem('localloginId'),
        }
        this.signInService.wsStatus(postData).subscribe((response: any) => {
          if (response) {
              let format = (response.data[0].format);
              localStorage.setItem('FormatAvailables', format);
              let avaliableExtensions1 = format
              ?.split(":")
              .join(",");
          let avaliableExtensions = [];
          avaliableExtensions = avaliableExtensions1?.split(",");
          for (let i = 0; i < avaliableExtensions?.length; i++) {
              if (isNaN(Number(avaliableExtensions[i]))) {
                  avaliableExtensions[i] = avaliableExtensions[i]?.toUpperCase();
              }
          }
          this.avaliableExtensions = avaliableExtensions?.join();
          this.format = format;
        let valueFormat = this.format?.split(',')
        console.log("value format", valueFormat);
    
        for(let i=0;i<=valueFormat.length-1;i++){
          if(valueFormat[i].includes(':')){
            let FormatChange = valueFormat[i]?.split(':');
            for(let j=0;j<=FormatChange.length-1;j++){
    
              let changesFormateing = parseInt(FormatChange[j])
    
              if(isNaN(changesFormateing)){
                this.changedFormat.push( ' .' + FormatChange[j])
                console.log("changed format is", this.changedFormat);
              }
              else if(!isNaN(changesFormateing)){
                break;
              }
    
            }
    
    
          }
          else{
            this.changedFormat.push(' .' + valueFormat[i]);
          }
    
        }
        const uniqueDates = new Set();
        this.changedFormat.forEach((res) => {
          uniqueDates.add(res);
        });
        let reverArray = Array.from(uniqueDates);
        this.formatAvailable = reverArray.join(',').toLowerCase();
        console.log("format available are", this.formatAvailable);
        this.validateDocStorage(r);
          }
        })
        
        console.log("hiii",this.docItid);
        localStorage.setItem('documentId',String(0));
    }, 700) 
  }

  validateDocStorage(i) {
    var st = window.location.toString();
    var arr = st?.split('/');
    let postDatastorage = {
        Wsoid: Number(this.workSpaceId),
        WsLink: arr[2],
        guid: localStorage.getItem('loginguid'),
        userEmailID: this.createdBy
    };
    this.data.ValidateAvailableStorage(postDatastorage).pipe(
            catchError((error) => {
                this.logger.error(error);
                return of(`Error: ${error}`);
            })
        ).subscribe((response: any) => {
        if (response.isSuccess) {
            this.availableStore = response.data.applyAvailableStorage;
            localStorage.setItem('availableStorage', this.availableStore)
            if (this.availableStore && (this.availableStore <= 0 || this.availableStore === null || this.availableStore === undefined)) {
                this.upgradeChild.openUpgradeUpload('upload additional dPages', 'EM-048');
                return
            }
            else {
                console.log("event for the click is");
                localStorage.removeItem('link')
                let getElement = "getFile_" + i;
                document.getElementById(getElement).click();
            }
            this.isUploadClick = false;

        }
    }
    )
  }
  /**
   * This method is called when user wants to search documents from the All Workspace Docs Modal
   */
  searchUserForm(value: string, flag) {
      this.allworkspaceDoc = this.searchList;
      this.searchValue = value;
      if (value == "") {
          this.allverifiedDocList(flag);
      } else if (!value) {
          this.allworkspaceDoc = this.searchData;
          this.regardingsearch = false;
          this.searchDataNew = this.allworkspaceDoc;
      } else {
          this.allworkspaceDoc = this.allworkspaceDoc.filter((x) =>
              x.originalFileName
                  .trim()
                  .toLowerCase()
                  .includes(value.trim().toLowerCase())
          );
          this.searchDataNew = this.allworkspaceDoc;
          this.filterMyDoc();
      }
  }

  /**
   * This method is called to finally filter the document as per the user-entered search string
   */
  filterMyDoc() {
      this.allworkspaceDoc = this.searchDataNew;
  }

  /**
   * This method is called when user clicks on the `View Document` option from a documents' three-dot menu
   *
   * Calls API `</api/GetUserRoleAccess/UserWiseRoleAccess>`
   */
  onViewClickThreeDot(i, j) {
      //  if ((selectedrowData[0].docId == this.valuerowdata) && ((this.shiftkey !== 'Shift' && this.shiftkeyCode !== 16))) {
      let postData = {
          wsoid: Number(this.workSpaceId),
          docID: Number(this.uploadedDocId[i][j]),
          role: "View",
          database: "get2doxMaster",
          createdby: this.createdBy,
          Isdeleteby: false,
          guid: this.getguid,
          DocGuid: this.uploadedDocGuid[i][j],
      };
      this.pendingRequest = this.data
          .viewUserWiseRoleAccess(postData)
          .pipe(
              catchError((error) => {
                  this.logger.error(error);
                  return of(`Error: ${error}`);
              })
          )
          .subscribe((response: any) => {
              if (response.isSuccess) {
                  localStorage.setItem("redirectedFrom", "/response/response");
                  const url = this.router.serializeUrl(
                      this.router.createUrlTree(["view/view-document"], {
                          queryParams: {
                              docId: this.uploadedDocId[i][j],
                              docGuid: this.uploadedDocGuid[i][j],
                          },
                      })
                  );
                  window.open(url, "_blank");
              }
          });
      // }
  }

  /**
   * This method is called to check if user has share rights
   */
  shareAccessTags(_i, _j) {
      let flag = false;
      let isSharedRights = localStorage.getItem("isSharedRights");
      if (this.editrights) {
          flag = true;
      }
      return flag;
  }

  /**
   * This method is called to check the max docuemnt size allowed for uploading a document
   *
   * Calls API `</api/RandomDocumentUpload/ValidateAvailableStorage>`
   */
  validateUploadStorage() {
      let st = window.location.toString();
      let arr = st.split("/");
      let postDatastorage = {
          Wsoid: Number(this.workSpaceId),
          WsLink: arr[2],
          guid: localStorage.getItem("loginguid"),
          userEmailID: this.createdBy,
      };
      this.randomUploadService
          .ValidateAvailableStorage(postDatastorage)
          .pipe(
              catchError((error) => {
                  this.logger.error(error);
                  return of(`Error: ${error}`);
              })
          )
          .subscribe((response: any) => {
              this.maxFileSize =
                  response?.data?.maximumFileSize?.split(":")[0];
                  this.maxFileSizeUnit =
                  response?.data?.maximumFileSize?.split(":")[1];
              localStorage.setItem(
                  "wsAvailableStorage",
                  this.maxFileSize
              );
          });
  }

  /**
   * This method is called to bind document to the document type it belongs to, in the particular docket response
   */
  /**
   * sharing mismatched variable
   */
  shareingMisMatchFound = false;
  docketSharingFlagString="";
  docListIndex=-1;
  async bindFileToDocument() {
      if (this.isRefresh) {
        this.docketSharingFlagString="";
          let docTdIdList = [];
          this.allworkspaceDoc.sort((a, b) => a.docItdtid - b.docItdtid);
          for (let i = 0; i < this.docList?.length; i++) {
              docTdIdList.push(
                  this.docList[i]?.docItdtid || this.docList[i]?.docIRtid
              );
              this.chooseFileName[i] = [];
          }
          this.statusText = this.getDocketStatus();
          for (let i = 0; i < this.allworkspaceDoc?.length; i++) {
              if (
                  docTdIdList?.includes(this.allworkspaceDoc[i]?.docItdtid) &&
                  (this.allworkspaceDoc[i]?.docId !==
                      this.allworkspaceDoc[i - 1]?.docId ||
                      this.allworkspaceDoc[i]?.docItdtid !==
                      this.allworkspaceDoc[i - 1]?.docItdtid)
              ) {
                  let iIndex = docTdIdList.indexOf(this.allworkspaceDoc[i]?.docItdtid);
                  this.docListIndex = iIndex;
                  var myFile = new File(
                      [this.allworkspaceDoc[i]?.originalFileName],
                      this.allworkspaceDoc[i]?.originalFileName,
                      { type: this.allworkspaceDoc[i]?.documentFormat }
                  );
                  this.chooseFileName[iIndex].push(myFile);
                  let jIndex = this.chooseFileName[iIndex]?.length - 1;
                  /*
                  * code for mismatch of sharing
                  */
                 let currentDocumentSharingStatus= false;
                 if(this.allworkspaceDoc[i].isallshare ==="1"){
                  currentDocumentSharingStatus = true;
                 }
                 let groupCheck = this.allworkspaceDoc[i].groupcheck;
                 let userCheck = this.allworkspaceDoc[i].usercheck;
                 let groupAndUserCheckString = groupCheck+userCheck;
                  await this.sharingStatusCheck(this.docketShareStatus,currentDocumentSharingStatus,iIndex,jIndex,groupAndUserCheckString);
                  this.uploadedDocId[iIndex][jIndex] = this.allworkspaceDoc[i]?.docId;
                  this.uploadedDocGuid[iIndex][jIndex] =
                      this.allworkspaceDoc[i]?.docGuid;
                  this.uploadedDocOwnerName[iIndex][jIndex] =
                      this.allworkspaceDoc[i]?.docOwnerEmailId;
                  this.uploadedDocName[iIndex][jIndex] =
                      this.allworkspaceDoc[i]?.originalFileName;
                  this.uploadedFormat[iIndex][jIndex] =
                      this.allworkspaceDoc[i]?.documentFormat;
                  this.uploadedConvertedFile[iIndex][jIndex] =
                      this.allworkspaceDoc[i]?.convertedPath  != null ? this.allworkspaceDoc[i]?.convertedPath : this.allworkspaceDoc[i]?.uploadPath;

                  if (
                      localStorage.getItem("noteuserfullname") ===
                      this.allworkspaceDoc[i]?.docOwnerName
                  ) {
                      this.isUserDocOwner[iIndex][jIndex] = true;
                  } else {
                      this.isUserDocOwner[iIndex][jIndex] = false;
                  }
                  console.log("selectFileAccess", this.allworkspaceDoc[i]?.isReadable);
                  this.selectFileAccess[iIndex][jIndex] =
                      this.allworkspaceDoc[i]?.isReadable === true ? true : false;
                  this.fileProgress[iIndex][jIndex] = 100;
                  this.hideDashed[iIndex] = true;
                  this.isFileNotUploaded[iIndex][jIndex] = false;
                  this.isProgress[iIndex][jIndex] = false;
              }
          }
          this.statusText = this.getDocketStatus();
          if(this.docketSharingFlagString.toLowerCase().includes('true')){
            this.shareingMisMatchFound = true;
          }
          else{
            this.shareingMisMatchFound = false;
        }
      }
      this.showSpinnerDockets = false;
  }

  openDiscard() {
    if(this.isActive && this.uidInputValue !== this.uniqueId){
        this.discardOpen = true;
    this.dialogRef = this.dialogService.open(this.discardModal, {
        closeOnBackdropClick: false,
        autoFocus: true,
        closeOnEsc: false
      });
    }
    else {
        this.discardOpen = false;
        this.backClick('redirect');
    }
  }
  /**
   * This method is called when user presses back click from response page (top-left)
   */
  backClick(val?) {
    if(val != 'redirect'){
    this.discardOpen = true;
   }
      window.location.href = "/pages/documents/dockets";
      sessionStorage.clear();
  }

  /**
   * This method is called when user scrolls down in All Workspace docs list
   */
  onScrollDown(event: any) {
    if (!this.searchFilterOn) {
        if (
            event.target.offsetHeight + event.target.scrollTop >=
            (event.target.scrollHeight -10) &&
            this.Check == 0
        ) {
            if (this.numberofRow <= this.totalDocuments) {
              //   this.numberofRow = this.numberofRow + 50;
                this.pageCount = this.pageCount +1;
                this.Check++;
              //   this.allverifiedDocList(this.currentFlag, this.numberofRow);
              this.getOwnedByList(this.currentFlag, this.numberofRow,this.pageCount)
            }
        }
    } else {
        if (
            event.target.offsetHeight + event.target.scrollTop >=
            (event.target.scrollHeight -10) &&
            this.Check == 0
        ) {
            if (this.numberofRow <= this.totalDocuments) {
              //   this.numberofRow = this.numberofRow + 50;
                this.pageCountSO = this.pageCountSO + 50;
                this.Check++;
              //   this.allverifiedDocList(this.currentFlag, this.numberofRow);
              this.searchOwnedByDocs(this.searchValue, this.numberofRow,this.pageCount)
            }
        }
    }
      
  }
/**
 * function that run on scroll event on table list of shared with me list
 */
  onScrollDownShared(event: any) {
    if (!this.searchFilterSharedOn) {
        if (
            event.target.offsetHeight + event.target.scrollTop >=
            (event.target.scrollHeight -10) &&
            this.Check == 0
        ) {
            if (this.numberofRowShared <= this.totalDocumentsOwned) {
                // this.numberofRow = this.numberofRow + 50;
                this.pageCount = this.pageCount +1;
                this.Check++;
              //   this.allverifiedDocList(this.currentFlag, this.numberofRow);
              this.getSharedList(this.currentFlag, this.numberofRowShared,this.pageCount);
            }
        }
    } else {
        if (
            event.target.offsetHeight + event.target.scrollTop >=
            (event.target.scrollHeight -10) &&
            this.Check == 0
        ) {
            if (this.numberofRowShared <= this.totalDocumentsOwned) {
                // this.numberofRow = this.numberofRow + 50;
                this.pageCountSharedSO = this.pageCountSharedSO + 50;
                this.Check++;
              //   this.allverifiedDocList(this.currentFlag, this.numberofRow);
              this.searchSharedByDocs(this.searchValue, this.numberofRowShared,this.pageCountSharedSO);
            }
        }
    }
    
}

  /**
   * This method is called when user presses three dot button for any document
   */
  threeDotMenuOption(i, j, _event?) {
      setTimeout(() => {
          if ("value" + i + j == this.check) {
              this.threeDotDlistShow[i][j] = false;
              this.check = undefined;
          } else {
              for (let i = 0; i < this.threeDotDlistShow?.length; i++) {
                  for (let j = 0; j < this.threeDotDlistShow[i]?.length; j++) {
                      this.threeDotDlistShow[i][j] = false;
                  }
              }
              this.threeDotDlistShow[i][j] = true;
              this.check = "value" + i + j;
          }
      }, 100);
  }

  /**
   * This method is called when presses mouse left click in the response page
   */
  @HostListener("document:click", ["$event"])
  onDocumentClick(event: MouseEvent) {
    // Check if the click target is outside the application
    const targetElement = event.target as HTMLElement;
    const isOutsideApp = !this.isElementPartOfApp(targetElement);

    if (isOutsideApp) {
      return; // Exit the method if the click is outside the application
    }

    // Check if the click target is the input box or its descendant elements
    const inputBox = document.querySelector('input');
    const isInsideBackBtn =  this.backArrow?.nativeElement?.contains(targetElement);
    // const isInsideBackBtn = backArrow?.contains(targetElement);
    const isInsideInputBox = inputBox?.contains(targetElement);

    if (!isInsideInputBox && !isInsideBackBtn) {
    if(this.isActive && this.isnew){
        this.saveOrUpdate(); 
    }
    else if(this.isActive && this.uidInputValue != this.uniqueId){
      this.saveOrUpdate(); 
      }// Call the saveChanges method if the click is outside the input box
    }
  }



    isElementPartOfApp(element: HTMLElement): boolean {
    // This method checks if the given element is part of your application
    // You can customize this logic based on your application structure
    const appRoot = document.querySelector('ngx-response');
    return appRoot?.contains(element) ?? false;
  }

  /**
   * This method is called when any open three dot menu needs to be removed
   */
  removeThreeDot() {
      for (let i = 0; i < this.threeDotDlistShow?.length; i++) {
          for (let j = 0; j < this.threeDotDlistShow[i]?.length; j++) {
              this.threeDotDlistShow[i][j] = false;
          }
      }
  }

  /**
   * This method is called to define how a modal is to be opened using the dialogRef of type `NbDialogRef`
   */
  async openModal(dialogModal: TemplateRef<any>, _closeOnBackdropClick: boolean) {
    this.dialogRef = this.dialogService.open(dialogModal, {
          closeOnBackdropClick: false,
          closeOnEsc: false,
        });
  }
  openModal1(dialogModal: TemplateRef<any>, _closeOnBackdropClick: boolean) {
    this.dialogRef = this.dialogService.open(dialogModal, {
          closeOnBackdropClick: false,
          closeOnEsc: false,
        });
  }

  /**
   * This method is called when user reloads the window
   */
  reloadScreen() {
      window.location.href = "/pages/documents/dockets";
  }

  /**
   * This method is called to enable a document after it is uploaded and a signalR message is received (after extraction for the document is complete)
   */
  MyDocListEnableRow(data) {
    // console.log("data is", data);
    //   let getrealdocdata = data?.split("|");
    //   this.fileIsShown = true;
    //   for (let i = 0; i <= this.uploadedDocGuid?.length - 1; i++) {
    //       for (let j = 0; j <= this.uploadedDocGuid[i]?.length - 1; j++) {
    //           if (this.uploadedDocGuid[i][j] == getrealdocdata[0]) {
    //               this.selectFileAccess[i][j] = true;
    //           }
    //       }
    //   }
  }

  /**
   * This method is called to establish a hub connection and to receive signalR messages
   *
   * and to open real-time modals (if necessary)
   */
  async connectiongenrate() {
      try {
          this._hubConnecton = new mdl.HubConnectionBuilder()
              .withUrl(environment.baseUrl + "/notificationhub")
              .withAutomaticReconnect()
              .configureLogging(mdl.LogLevel.Information)
              .build();
          Object.defineProperty(WebSocket, "OPEN", { value: 1 });
          await this._hubConnecton.start();
          await this._hubConnecton.invoke("GetConnectionId");
          const connectionId = await this._hubConnecton.invoke("GetConnectionId");
          this._hubConnecton.onreconnected((connectionId) => {
              const reconnectionid = connectionId;
              sessionStorage.setItem("KeyId", reconnectionid);
              this.workSpaceId = localStorage.getItem("localwsId");
              this.createdBy = localStorage.getItem("localloginId");
              this.rolename = localStorage.getItem("rolevalue");
              this.groupname = localStorage.getItem("groupnamevalue");
              if (
                  this.workSpaceId != "0" &&
                  this.workSpaceId != null &&
                  this.workSpaceId != ""
              ) {
                  let notify = {
                      wsoid: Number(this.workSpaceId),
                      key: reconnectionid,
                      userEmailID: this.createdBy,
                      role: this.rolename,
                      groupName: this.groupname,
                      databaseName: "",
                      createdBy: this.createdBy,
                  };
                  this.signInService
                      .notifysignin(notify)
                      .pipe(
                          catchError((error) => {
                              this.logger.error(error);
                              return of(`Error: ${error}`);
                          })
                      )
                      .subscribe((response: any) => {
                          if (response.isSuccess) {
                              if (response.statusCode == "C200") {
                              }
                          }
                      });
              }
          });

          this._hubConnecton.on("modalpvtnotification", (m: string) => {
              if (
                  m ==
                  "Your WS Plan has been changed. Please refresh to access your updated WS.|WS plan changed"
              ) {
                  this.refreshToInviteUser = true;
                  let url = window.location.href?.split("/");
                  if (url[6] == undefined) {
                      this.saveurl = "/" + url[3] + "/" + url[4] + "/" + url[5];
                  } else {
                      this.saveurl =
                          "/" + url[3] + "/" + url[4] + "/" + url[5] + "/" + url[6];
                  }
              } else {
                  if (
                      m.includes("Your plan has been modified") ||
                      m.includes("Role modified") ||
                      m.includes("Role changed") ||
                      m.includes("role deleted") ||
                      m.includes("Transfer Ownership")
                  ) {
                      this.isPlanChanged = false;
                  } else {
                      this.isPlanChanged = true;
                  }
              }
              localStorage.setItem("hubMessage", m?.toString());
              let directLogOutSignalR:string[]=[];
              let tempHubMessageArray =m?.split("|");
              for(let i =0;i<tempHubMessageArray.length;i++){
                if (
                  tempHubMessageArray[i].toLowerCase() === "account disabled" ||
                  tempHubMessageArray[i].toLowerCase() === "disabled" ||
                  tempHubMessageArray[i].toLowerCase() === "account deleted" ||
                  tempHubMessageArray[i].toLowerCase() === "deleted" ||
                  tempHubMessageArray[i].toLowerCase() === "workspace deleted" ||
                  tempHubMessageArray[i].toLowerCase() === "workspace disabled" ||
                  tempHubMessageArray[i].toLowerCase() === "ws suspended"
                ) {
                  directLogOutSignalR.push(tempHubMessageArray[i]);
                }
                else{
                  this.result.push(tempHubMessageArray[i]);
                }
              }
              if (directLogOutSignalR.length>0){
                this.accoutClose(directLogOutSignalR[0]);
              }
              else{
                this.openModal(this.accountdisabled,false);
}
          });
          this._hubConnecton.on("documentupdate", (m) => {
              this.msg = m;
              this.MyDocListEnableRow(m);
          });
          sessionStorage.setItem("KeyId", connectionId);

          let notify = {
              wsoid: Number(this.workSpaceId),
              key: connectionId,
              userEmailID: this.createdBy,
              role: this.rolename,
              groupName: this.groupname,
              databaseName: "",
              createdBy: this.createdBy,
          };
          this.signInService
              .notifysignin(notify)
              .pipe(
                  catchError((error) => {
                      this.logger.error(error);
                      return of(`Error: ${error}`);
                  })
              )
              .subscribe((response: any) => {
                  if (response.isSuccess) {
                      if (response.statusCode == "C200") {
                      }
                  }
              });
      } catch (err) {
          console.warn(err);
      }
  }

  /**
   * This method is called when user performs an action on the real-time modal that the user may have encountered
   */
  public accoutClose(str?, newUrl?) {
      let docketRole;
      var st = window.location.toString();
      var arr = st?.split("/");
      let postData1: any;
      postData1 = {
          WSLink: arr[2],
          Wsoid: Number(this.workSpaceId),
      };
      let postData = {
          Wsoid: parseInt(localStorage.getItem("localwsId")),
          WsLink: arr[2],
          EmailId: localStorage.getItem("localloginId"),
      };
      this.dialogRef?.close();
      let logoinIdCookie = "localloginId" + localStorage.getItem("localwsId");
      let wsIdCookie = "localwsId" + localStorage.getItem("localwsId");
      str = str?.toString()?.toLowerCase();
      if (newUrl) {
          window.location.href = newUrl;
      }
      else{
      if (str == 'ws plan changed') {
        this.cookieService.delete(logoinIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
        this.cookieService.delete(wsIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
        localStorage.clear();
        sessionStorage.clear();
        this.router.navigate(["/onboarding/sign-in"]);
      }
      else if (str == "account disabled" || str == "disabled") {
        this.cookieService.delete(logoinIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
        this.cookieService.delete(wsIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
        localStorage.clear();
        sessionStorage.clear();
        localStorage.setItem("signalRMsg",'userDisabledSR');
        this.router.navigate(["/onboarding/account-disable"]);
      }
      else if (str == "account deleted" || str == "deleted") {
        this.cookieService.delete(logoinIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
        this.cookieService.delete(wsIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
        localStorage.clear();
        sessionStorage.clear();
        localStorage.setItem("signalRMsg",'userDeletedSR');
        this.router.navigate(["/onboarding/account-deleted"]);
      }
      else if (
          str == "transfer ownership" &&
          localStorage.getItem("workspace") == "disable"
      ) {
          this.cookieService.delete(
              logoinIdCookie,
              "/",
              environment.domain,
              environment.httpUrl === "http://" ? false : true,
              "Lax"
          );
          this.cookieService.delete(
              wsIdCookie,
              "/",
              environment.domain,
              environment.httpUrl === "http://" ? false : true,
              "Lax"
          );
          localStorage.clear();
          this.dialogRef.close();
          sessionStorage.clear();
          this.router.navigate(["/onboarding/disabled"]);
      } else if (this.refreshToInviteUser == true) {
          this.router.navigate([this.saveurl]);
          this.dialogRef.close();
          window.location.href = this.saveurl;
      }
       else if (str == "resetpassword" || str == "reset your password") {
          localStorage.clear();
          sessionStorage.clear();
          this.cookieService.delete(
              logoinIdCookie,
              "/",
              environment.domain,
              environment.httpUrl === "http://" ? false : true,
              "Lax"
          );
          this.cookieService.delete(
              wsIdCookie,
              "/",
              environment.domain,
              environment.httpUrl === "http://" ? false : true,
              "Lax"
          );
          this.router.navigate(["/onboarding/change-password"]);
      } else if (str == "workspace deleted") {
          this.cookieService.delete(
              logoinIdCookie,
              "/",
              environment.domain,
              environment.httpUrl === "http://" ? false : true,
              "Lax"
          );
          this.cookieService.delete(
              wsIdCookie,
              "/",
              environment.domain,
              environment.httpUrl === "http://" ? false : true,
              "Lax"
          );
          localStorage.clear();
          sessionStorage.clear();
          this.router.navigate(["/onboarding/deleted"]);
      } else if (str == "workspace disabled") {
          this.cookieService.delete(
              logoinIdCookie,
              "/",
              environment.domain,
              environment.httpUrl === "http://" ? false : true,
              "Lax"
          );
          this.cookieService.delete(
              wsIdCookie,
              "/",
              environment.domain,
              environment.httpUrl === "http://" ? false : true,
              "Lax"
          );
          localStorage.clear();
          sessionStorage.clear();
          localStorage.setItem("signalRMsg",'workSpaceDisabledSR');
          this.router.navigate(["/onboarding/disabled"]);
      } else if (str == "ws suspended") {
          this.cookieService.delete(
              logoinIdCookie,
              "/",
              environment.domain,
              environment.httpUrl === "http://" ? false : true,
              "Lax"
          );
          this.cookieService.delete(
              wsIdCookie,
              "/",
              environment.domain,
              environment.httpUrl === "http://" ? false : true,
              "Lax"
          );
          let loginUser = localStorage.getItem('localloginId')
          localStorage.clear();
          sessionStorage.clear();
          localStorage.setItem('localloginId',loginUser);
          localStorage.setItem("signalRMsg",'workSpaceSuspendedSR');
          this.router.navigate(["/onboarding/suspended"]);
      } else if (
          str == "modified" ||
          str == "role deleted" ||
          str == "transfer ownership" ||
          str == "role changed"
      ) {
          setTimeout(() => {
              if (
                  str == "transfer ownership" &&
                  localStorage.getItem("workspace") == "disable"
              ) {
                  this.cookieService.delete(
                      logoinIdCookie,
                      "/",
                      environment.domain,
                      environment.httpUrl === "http://" ? false : true,
                      "Lax"
                  );
                  this.cookieService.delete(
                      wsIdCookie,
                      "/",
                      environment.domain,
                      environment.httpUrl === "http://" ? false : true,
                      "Lax"
                  );
                  localStorage.clear();
                  this.dialogRef.close();
                  sessionStorage.clear();
                  this.router.navigate(["/onboarding/disabled"]);
              }
              if (window.location.href.includes("/documents/dockets") && docketRole) {
                  window.location.href = "/pages/documents/dockets";
              } else {
                  window.location.href = "/pages/dashboard";
              }
          }, 500);
          this.dialogRef.close();
      } else {
          window.location.href = "/pages/dashboard";
          this.dialogRef?.close();
      }
  }
}
  /**
   * This method is called to check and validate the uid and docket from the backend
   */
 async ValidateUIDAndDocket() {
      // localStorage.removeItem("celldata")
      // setTimeout(() => {
        let url = window.location.href;
      let Pass = false;
      let DocitType = url.split("/")[6];
      let UID = url.split("UID/")[1];
      if(UID?.includes('%7C%7C')){
        UID = UID.replace(/%7C%7C/g,"/");
      }
      if(UID?.includes("%20")){
        UID = UID.replace(/%20/g," ");
    }
    if(UID?.includes("%28")){
      UID = UID.replace(/%28/g,"(");
    }
    if(UID?.includes("%29")){
      UID = UID.replace(/%29/g,")");
    }
      if (url.includes("NEW")) {
        let newDocType = '';
        let mainDocType = '';
        if (DocitType.includes('_')) {
            newDocType = DocitType.replace("_", " ");
            if (newDocType.includes('%20')) {
                mainDocType = newDocType.replace(/%20/g," ");
            } else {
                mainDocType = newDocType;
            }
        } else {
            mainDocType = DocitType;
        }
          for (let i of this.docketType) {
              if (i.docItType === mainDocType) {
                  this.docItid = i.docItid;
                  Pass = true;
                  this.hideJobIdCard = false;
                  this.docketName = DocitType;
              }
          }
      }
      if (url?.includes("UID")) {
        let newDocType = '';
        let mainDocType = '';
        if (DocitType.includes('_')) {
            newDocType = DocitType.replace("_", " ");
            if (newDocType.includes('%20')) {
                mainDocType = newDocType.replace(/%20/g," ");
            } else {
                mainDocType = newDocType;
            }
        } else {
            mainDocType = DocitType;
        }
          for (let i of this.docketType) {
              if (i.docItType === mainDocType) {
                  this.docItid = i.docItid;
                  Pass = true;
              }
          }
          if (Pass === true) {
              Pass = false;
              for (let i of this.getList) {
                  if (i.uniqueID === UID) {
                      this.uniqueId = i.uniqueID;
                      if(i.isAllUserShare === 1){
                        this.docketShareStatus = true;
                      }
                      Pass = true;
                      this.hideJobIdCard = false;
                      this.docketRId = i.docItRid;
                      this.uidType = i.docIt;
                      this.docketOwnerName = i.createdby;
                  }
              }
          }
        //   let docRID = localStorage.getItem("cellDatas").split(",")[1];
        //   this.docketRId = Number(docRID);
      }

    //   if (!Pass && !sessionStorage.getItem('byPass')
    //   ) {
    // this.router.navigate(["/error/error-page"]);
    //   }
      // }, 200);
  }
  /**
   * Variable to store docket type from the backend
   */
  openedDocketType="";
  /**
  * Variable to store docket type from the backend
  */
  openedDocketTypeName = "";
  /**
   * This method is called on the ngoninit to manage the spaces and "/" of the docket name and create the params according to that docket name
   */
  async docitAndUniqueId() {
      this.localData = localStorage.getItem("celldata");
      if (sessionStorage.getItem("celldata")) {
          this.localData = sessionStorage.getItem("celldata");
          localStorage.setItem("docItid", String(this.localData?.split(",")[1]));
      } else if (localStorage.getItem("celldata")) {
          sessionStorage.setItem("celldata", this.localData);
          localStorage.setItem("docItid", String(this.localData?.split(",")[1]));
      }
      let url = window.location.href;
      let DocitType = url.split("/")[6];
      console.log("DOckitType",DocitType)
      let UID = url.split("UID/")[1];
      if(UID?.includes('%7C%7C')){
        UID = UID.replace(/%7C%7C/g,"/");
      }
      if(UID?.includes("%20")){
        UID = UID.replace(/%20/g," ");
    }
    if(UID?.includes("%28")){
      UID = UID.replace(/%28/g,"(");
    }
    if(UID?.includes("%29")){
      UID = UID.replace(/%29/g,")");
    }
    console.log("the wall",UID);
    this.openedDocketType = DocitType;
    let arr: string[] = DocitType.split("_");
    this.localData = localStorage.getItem('cellData');
    this.openedDocketTypeName = String(this.localData?.split(",")[0]);
    // this.openedDocketTypeName = arr.join(' ');
      let fetchTempelate = {
          wsoid: Number(this.workSpaceId),
          docitid: 0,
          docItUid: 0,
      };
      console.log("the wall 1", fetchTempelate);
       this.data2
          .getDocket(fetchTempelate)
          .toPromise()
          .then((response: any) => {
              this.docketType = response.data;
              if (url.includes("NEW") || url.includes("UID")) {
                let newDocType = '';
                let mainDocType = '';
                if (DocitType.includes('_')) {
                    newDocType = DocitType.replace("_", " ");
                    if (newDocType.includes('%20')) {
                        mainDocType = newDocType.replace(/%20/g," ");
                    } else {
                        mainDocType = newDocType;
                    }
                } else {
                    mainDocType = DocitType;
                }
                console.log("the wall 2", mainDocType);
                  for (let i of this.docketType) {
                    // let checkDocket = DocitType.replace("_", " ");
                    // let finalDocket = checkDocket.replace(/%20/g, " ")
                      if (i.docItType === mainDocType) {
                          this.docItid = i.docItid;
                          localStorage.setItem("docItid", String(this.docItid));
                      }
                  }
              }
              if (url.includes("UID")) {
                  let value = UID + "," + this.docItid;
                  this.uniqueId = UID;
                  this.docItid = Number(this.docItid);
                  sessionStorage.setItem("celldata", value);
                  this.localData = value;
              }
          });
      return 0;
  }

/**
* This method is used for scrolling the list of document cards
*/
  ScrollDiv(elementId){
    let e = document.getElementById(elementId);
    e.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
      inline: 'center'
    });
  }

  /**
  * Docket Share Modal code starts from here
  */
 /**
  * itemsList [] for group and user objects from api
  */
  itemsList: any;
  /**
  * shareOptions [] for group and user email list
  */
  ShareOptions: any;
  /**
  * noOneSelected docket sharing status boolean
  */
  noOneSelected= false;
  /**
  * radioButton of docket sharing
  */
  radioButton=3;
  /**
  * dropDownDisable variable
  */
  dropDownDisable = true;
  /**
  * shareToDiv variable for sharetoDiv
  */
  shareToDiv = false;
  /**
  * isAllShare docket sharing variable to post into the api
  */
  isAllShare= false;
  /**
  * docket sharing card spinner variable
  */
  showSpinner= false;
  /**
  * docket sharing share button spinner variable
  */
  sharebuttonSpinner = false;
  /**
  * to dynamically bind the checked of nb button from api response
  */
  isChecked:number;
  /**
  * This method is called when user changes the docket sharing radio button setting
  */
  radioClicked(value:number){
    this.radioButton = value;
    this.shareButtonStatus = false;
    if(value === 1){
      this.newShareing="Share with everyone"
      this.isChecked = 1;
      this.noOneSelected = false;
      this.dropDownDisable = true;
      this.shareToDiv = false;
      this.isAllShare = true;
    }
    else if (value === 2){
        this.newShareing="Share with no one"

      this.isChecked = 2;
      this.noOneSelected = true;
      this.dropDownDisable = true;
      this.shareToDiv = false;
      this.isAllShare = false;
    }
    else if (value === 3){
        this.newShareing="Users and Groups"

      this.isChecked = 3;
      this.noOneSelected = false;
      this.dropDownDisable = false;
      this.shareToDiv = true;
      this.isAllShare = false;
    }
  }
  /**
  *   This method is called when user clicks on the docket sharing icon button
  */
  async openDocketSharing(){
  this.showSpinner=true;
  await this.openModal(this.docketSharing,false);
  if (this.docketRId == undefined) {
    setTimeout(() => {
        this.fetchList();
    },1000);
  } else {
    await this.fetchList();
  }
}

  /**
  * arrays to store namechips sharing details from api getdocketuserandgroupdetails
  */
  namechips=[]
  /**
  * arrays to store idsList sharing details from api getdocketuserandgroupdetails
  */
  idsList = [];
  /**
  * arrays to store categroyList sharing details from api getdocketuserandgroupdetails
  */
  categroyList = [];
 /**
 * arrays to store userEmailIDsAndGroupList sharing details from api getdocketuserandgroupdetails
 */
  userEmailIDsAndGroupList = [];
 /**
 * variable to store the document sharing tooltip message
 */
  error1 = "Document's sharing settings are not the same as this Docket's";

  /**
  * This method is called to fetch details from api getdocketuserandgroupdetails
  *
  * Calls API `</api/DocumentVerification/GetUsersAndGroupsList>`
  *
  * Calls API `</api/GetUserDetailWithGroup/GetDocketUserDetailWithGroup>`
  */
  async fetchList(){
    var st = window.location.toString();
    var arr = st?.split("/");
   let postData = {
      "Wsoid": Number(this.workSpaceId),
      "WsLink": arr[2],
    }
    await this.shareAccess.shereTemplateListService(postData).pipe(
              catchError((error) => {
                  this.logger.error(error);
                  return of(`Error: ${error}`);
              })
          ).toPromise().then((response : any)=>{
      let OwnerEmail =localStorage.getItem('docOwnerEmailId')
      let shareDetailsNew = response.data.filter((loc)=>loc.emailID != OwnerEmail )
      this.itemsList = [...shareDetailsNew];
      this.itemsList.sort((one, two) => (one.emailID.toLowerCase() < two.emailID.toLowerCase() ? -1 : 1));
      this.ShareOptions = this.itemsList;
          });

    let value= {
      "wsoid": Number(this.workSpaceId),
      "docitRUID": this.docketRId.toString(),
      "database": "get2doxMaster",
      "uid": ""
    }
    await this.shareAccess.getDocketUserDetailWithGroup(value).pipe(
              catchError((error) => {
                  this.logger.error(error);
                  return of(`Error: ${error}`);
              })
          ).toPromise().then((res:any)=> {
      if(res.isSuccess){
        this.showSpinner= false;
        this.shareButtonStatus = true;
        this.list=[]
        this.namechips=res.data
        if(this.namechips.length>0){
          this.radioClicked(3);
          this.oldShareing ='Users And Groups'
          this.shareButtonStatus = true;
          this.list = this.namechips.map(t => t.nameAndGroup);
        }
      }
      else if (!res.isSuccess && res.statusCode ==="1"){
        this.showSpinner= false;
        this.radioClicked(1);
        this.oldShareing ="Share with everyone"

        this.shareButtonStatus = true;
      }
      else if (!res.isSuccess && res.statusCode ==="0"){
        this.showSpinner= false;
        this.radioClicked(2);
        this.oldShareing ="Share with no one"

        this.shareButtonStatus = true;
      }

    })
  }
  list;
  /**
  * This method is used to remove chips from docket sharing
  */
  onTagRemove(tagToRemove): void {
    this.list=[]
    this.namechips = this.namechips.filter(t => t.nameAndGroup !== tagToRemove.text);
    this.list = this.namechips.map(t => t.nameAndGroup)
  }
  /**
  * This method is called to when use clicks on save button of docket sharing
  *
  * Calls API `</api/DocumentVerification/SaveDocketUsersAndgroup>`
  */
 async saveTemplate(){
    this.idsList=[];
    this.categroyList=[];
    this.userEmailIDsAndGroupList=[];
    if(this.radioButton === 3){
      for (let item of this.namechips){
        this.idsList.push(item.id);
        this.categroyList.push(item.categroy);
        this.userEmailIDsAndGroupList.push(item.emailID);
      }
    }
    this.namechips=[];
    this.list =[];
    let docketIdList = this.docketRId?.toString();
    let postData= {
      "wsoid": Number(this.workSpaceId),
      "iDsList": this.idsList.join('|'),
      "docketID": this.docketRId,
      "docketIdList": docketIdList,
      "userEmailIDsAndGroupList": this.userEmailIDsAndGroupList.join('|'),
      "isAll": this.isAllShare,
      "separator": "|",
      "createdBy": this.createdBy,
      "categroyList": this.categroyList.join('|'),
      "guid": "",
      "docGUID": "",
      "flag": "0"
    }
    this.shareAccess.saveDocketUsersAndGroup(postData).pipe(
              catchError((error) => {
                  this.logger.error(error);
                  return of(`Error: ${error}`);
              })
          ).toPromise().then(async (res1 : any)=>{
      if(res1.isSuccess){
        this.sharebuttonSpinner= true;
        this.docketShareStatus = this.isAllShare;
        await this.allverifiedDocList("refresh");
        this.sharebuttonSpinner= false;
        this.updateDocAuditLogs(true,undefined);
        this.dialogRef.close();
      }
  });
}
/**
*   This method is used to check the share button color
*/
async sharingStatusCheck(docketShareStatus:boolean,currentDocumentSharingStatus:boolean,currentCardIndex:number,cardColumnIndex:number,res:string){
    if(docketShareStatus !== currentDocumentSharingStatus){
        console.log("output 1", docketShareStatus, currentDocumentSharingStatus);
    await this.updateStatusVariable(currentCardIndex,cardColumnIndex,true)
    }
    else if((docketShareStatus === true) && (currentDocumentSharingStatus === true)){
        console.log("output 2", docketShareStatus, currentDocumentSharingStatus);
      await this.updateStatusVariable(currentCardIndex,cardColumnIndex,false)
    }
    else if((docketShareStatus === false) && (currentDocumentSharingStatus === false) && (!res?.toLowerCase().includes("mismatch"))){
        console.log("output 3", docketShareStatus, currentDocumentSharingStatus);
        await this.updateStatusVariable(currentCardIndex,cardColumnIndex,false)
    }
    else if((docketShareStatus === false) && (currentDocumentSharingStatus === false) && (res?.toLowerCase().includes("mismatch"))){
        console.log("output 4", docketShareStatus, currentDocumentSharingStatus);
        await this.updateStatusVariable(currentCardIndex,cardColumnIndex,true)
      }

  }
/**
*   This method is used to check the share button color
*/
async updateStatusVariable(currentCardIndex:number,cardColumnIndex:number,flag:boolean){
    let sharingStatus:string = "shareingMisMatch";
    let sharingValue:boolean = flag;
    this.chooseFileName[currentCardIndex][cardColumnIndex][sharingStatus] = sharingValue;
      this.docketSharingFlagString += String(flag);
      console.log("dockets sharing are here", this.docketSharingFlagString);
      this.statusText = this.getDocketStatus();
}

/**
*  This method is called when user clicks on right tick icon to save the uid name changes and it open UID change confirmation modal and it does duplicate check
*
* Calls API `</api/docItTypeMaster/CreateResponse>`
*/
 openUidChangeModal = async () =>{
  let postData = {
    wsoid: Number(this.workSpaceId),
    docitid: this.docItid,
    docitdtid: 0,
    docItUid: 0,
    docitRID: this.docketRId,
    flag: "check",
    uniqueID: this.uidInputValue.trim(),
    createdby: this.createdBy,
    isactive: 1,
};
await this.data.CreateResponse(postData).pipe(
    catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
    })
).subscribe((response: any) => {
  if(!response.isSuccess && response.statusCode === "C202"){
    this.saveUidChange();
  }
  else{
    this.showDuplicateUidAlert = true;
    this.showUidSaveBtndisable = false;
    this.showInfo = false
  }

});

}
/**
*  This method is called when user clicks on CONFIRM button inside the change confirmation modal
*
* Calls API `</api/docItTypeMaster/CreateResponse>`
*/

updatedResponse = true;
  saveUidChange = async (): Promise<void>  =>{
  let postData = {
    wsoid: Number(this.workSpaceId),
    docitid: this.docItid,
    docitRID: this.docketRId,
    docitdtid: 0,
    docItUid: 0,
    flag: "update",
    uniqueID: this.uidInputValue.trim(),
    createdby: this.createdBy,
    isactive: 1,
};
  this.data.CreateResponse(postData).pipe(
    catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
    })
).subscribe(async (response: any) => {
  if(response.isSuccess){
   await this.updateDocAuditLogs(undefined,'uidchange')
   let newUidValue = this.uidInputValue.trim();
   this.uniqueId = this.uidInputValue
    const url = this.router.serializeUrl(
        this.router.createUrlTree([
          "pages/documents/dockets/" +
          this.openedDocketType +
            "/UID/" + newUidValue.replace(/\//g,"||"),

        ])
      );
      this.location.replaceState("pages/documents/dockets/" +
      this.openedDocketType +
        "/UID/" + newUidValue.replace(/\//g,"||"));
    //   this.ngOnInit();
  }
});
}
/**
*  This method is used for UID name input box validation
*/

async saveChanges(){
 let val = "done";
this.getList?.filter((check) => {
    if (check.uniqueID === this.uniqueId) {
        val = "match";
    }
});
if (val !== "match") {
    this.updateDocAuditLogs(undefined,undefined);
}
let postData = {
    wsoid: Number(this.workSpaceId),
    docitid: this.docItid,
    docitdtid: 0,
    docItUid: 0,
    flag: "new",
    uniqueID: String(this.uidInputValue),
    createdby: this.createdBy,
    isactive: 1,
};
await this.data.CreateResponse(postData).pipe(
    catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
    })
).toPromise().then((response: any) => {

    if (response.isSuccess) {
        this.docketRId = Number(response.returnMessage);
        setTimeout(() => {
            let jobRequest = {
                wsoid: Number(this.workSpaceId),
                docItid: this.docItid,
                docItdtid: 0,
                uniqueID: "",
                pagecount: 1,
                numberofRow: 1000,
                loginIDEmailID: this.createdBy
            };
            this.data.GetResponseList(jobRequest).pipe(
                catchError((error) => {
                    this.logger.error(error);
                    return of(`Error: ${error}`);
                })
            ).subscribe((res: any) => {
                this.getList = res.data;
                this.allverifiedDocList("refresh");
            });
        }, 200);
        this.isnew = false;
   let newUidValue = this.uidInputValue.trim();
    const url = this.router.serializeUrl(
        this.router.createUrlTree([
          "pages/documents/dockets/" +
          this.openedDocketType +
            "/UID/" + newUidValue.replace(/\//g,"||"),

        ])
      );
      this.location.replaceState("pages/documents/dockets/" +
      this.openedDocketType +
        "/UID/" + newUidValue.replace(/\//g,"||"));
      this.newDocket();
        this.cd.detectChanges();
    }
});
}

async saveOrUpdate(event?: Event) {
    // if(this.clickedOutside){
    //     return;
    // }
    // if((this.uidInputValue === this.uniqueId) && !this.router.url.includes('UID') ){
    //     return;
    // }
    this.docketNameError = '';
    const docketNameRegex = /^(?=.{3,100}$)[-_\w\s]+$/;
    const forbiddenCharsRegex = /[<>\/\\;="%]/;

    if (this.uidInputValue === '') {
        this.showInfo = false;
        this.docketNameError = 'Docket Name cannot be empty.';
    } else if (/^\s+$/.test(this.uidInputValue)) {
        this.showInfo = false;
        this.docketNameError = 'Docket Name cannot consist of only spaces.';
    }
    else if (forbiddenCharsRegex.test(this.uidInputValue)) {
        this.showInfo = false;
      this.docketNameError = `The Docket Name cannot contain the following characters: < > / \ ; = " ' %`;
    } 
    else if (!docketNameRegex.test(this.uidInputValue)) {
        this.showInfo = false;
      this.docketNameError = 'Must contain at least 3 characters';
    } else {
        this.showInfo = true;
      this.docketNameError = '';
      if(this.isnew){
        let postData = {
            wsoid: Number(this.workSpaceId),
            docitid: this.docItid,
            docitdtid: 0,
            docItUid: 0,
            docitRID: this.docketRId,
            flag: "check",
            uniqueID: this.uidInputValue.trim(),
            createdby: this.createdBy,
            isactive: 1,
        };
        await this.data.CreateResponse(postData).pipe(
            catchError((error) => {
                this.logger.error(error);
                return of(`Error: ${error}`);
            })
        ).subscribe(async (response: any) => {
          if(!response.isSuccess && response.statusCode === "C202"){
           await this.saveChanges();
            this.isActive = false;
          }
          else{
            this.showDuplicateUidAlert = true;
            this.showUidSaveBtndisable = false;
            this.showInfo = false
          }
        });
    }
    else {
        await this.openUidChangeModal();
        this.isActive = false;
    }
    }
   
}

onInput = (value: string): void => {
    this.showInfo = true;
  this.showDuplicateUidAlert = false;
  this.showUidSaveBtn = true;
  this.showUidSaveBtndisable = (this.uniqueId !== this.uidInputValue.trim()) && (value.length > 0) && (value.trim() !=="");
};

openModaldocketCopy() {
    this.openModal(this.duplicateDocket, false);
}

/**
*  This method is called when user clicks on the copy icon button
*
* Calls API `</api/docItTypeMaster/copydocket>`
*/
docketCopy =():void=>{
this.openModal1(this.copyLoadingScreen,false);
let request=
{
  "createdby": this.createdBy,
  "docitRID": this.docketRId,
  "wsoid": Number(this.workSpaceId),
}
setTimeout(() => {
  this.data.copydocket(request).pipe(
    catchError((error) => {
                  this.logger.error(error);
                  return of(`Error: ${error}`);
              })
          )
          .subscribe((response:any)=>{
if(response.isSuccess && response.statusCode ==='C200'){
  this.dialogRef.close();
  let uniqueID = response.returnMessage;
  const url = this.router.serializeUrl(
    this.router.createUrlTree([
      "pages/documents/dockets/" +
      this.openedDocketType+
      "/UID/"+uniqueID.replace(/\//g,"||"),

    ])
    );
    uniqueID = encodeURIComponent(uniqueID).replace(/\(/g, '%28').replace(/\)/g, '%29');
    window.open( "pages/documents/dockets/" +
    this.openedDocketType+
    "/UID/"+uniqueID.replace(/\//g,"||"), "_blank");
  }
  else{
    this.dialogRef.close();
    this.toastrService.danger('A copy for this Docket could not be created right now. Please try again later updated');
  }
})
}, 1000);
}


  deleteDocHandling = true;
  deleteDocExternalLink = false
//   deleteResponse(data){
//     console.log(data);
//   }

  selectedAction = 'delete-ws'

  openDeleteModal(){
    let postData = {
        Wsoid: Number(this.workSpaceId),
        OriginalFileName: "",
        StartDate: "",
        EndDate: "",
        documentFormat: "",
        uploadedBy: "",
        verifiedBy: "",
        createdBy: this.createdBy,
        OwnedBy: "",
        docItId: Number(localStorage.getItem("docItid")),
        uniqueValue: this.uidInputValue || this.inputFormControl?.value || this.inputFormControl,
        numberofRow: Number(this.numberofRow),
        pagecount: 1,
    };
    this.allworkspace
        .allWsDocListService(postData)
        .pipe(
            catchError((error) => {
                this.logger.error(error);
                return of(`Error: ${error}`);
            })
        )
        .subscribe((response: any) => {
            if (response.isSuccess) {
                this.checkDocumentCount = response?.data?.length;
                this.openModal(this.confirmDeleteResponse, false);
                this.deleteDocHandling = true;
                this.deleteDocExternalLink = false; 
            }
        })
    }
  externalLinkedDocuments : any ;

  deleteResponse(checkdoc?) {

    let requiredObject = this.getList.find(data => data.uniqueID.trim() === this.uniqueId.trim());
    console.log(requiredObject);
    let postData = {
      wsoid: Number(this.workSpaceId),
      docitid: this.docItid,
      docitRID: requiredObject.docItRid,  
      docitdtid: 0,
      docItUid: 0,
      uniqueID: String(this.uniqueId),
      createdby: this.createdBy,
      isactive: 0,
      flag: this.selectedAction,
    };
    let docLinkPostData ={
      wsoid: Number(this.workSpaceId),
      docitRID:requiredObject.docItRid
    }
    if(checkdoc === 'externaldoc' || this.selectedAction === 'delete-ws'){
      this.deleteDocket(postData);
      return;
    }
    this.data2.checkDocketDoclink(docLinkPostData).subscribe((response: any) =>{
      if(response.statusCode === "C201"){
        this.deleteDocket(postData);
      }else{
        this.deleteDocHandling = false;
        this.deleteDocExternalLink = true;
        this.externalLinkedDocuments = response.data;
      }
    })
  }


  deleteDocket(postData){
    this.data
    .CreateResponse(postData)
    .pipe(
      catchError((error) => {
        this.logger.error(error);
        return of(`Error: ${error}`);
      })
    )
    .subscribe((_res) => {
      this.dialogRef.close();
      this.router.navigate(['/pages/documents/dockets']);
     });
  }

  openExternalIdResponse(i, j) {
    let st = window.location.toString();
    let arr = st?.split("/");
    console.log("uploadDocId", this.uploadedDocGuid[i][j], this.uploadedDocId[i][j]);
    let postdata = {
    "DocGuid": this.uploadedDocGuid[i][j],
    "DocID": Number(this.uploadedDocId[i][j]),
    "Wsoid": Number(localStorage.getItem('localwsId')),
    "currentUser": localStorage.getItem('localloginId'),
    }
this.data1.verifierDocDetailsService(postdata).subscribe((customTags: any) => {
  if (customTags.isSuccess) {
    console.log("external Solution Linked", customTags);
    let resistRes = customTags.data.docverificationdetailslists[0];
    if (resistRes.externalSol != '' && resistRes.externalSol != undefined) {
        this.externalIdAttached = true;
    } else {
        this.externalIdAttached = false;
    }
  }
})
}

errorReset(){
    this.docketNameError = '';
    this.showInfo =true;
    this.showDuplicateUidAlert = false;
    this.isActive = true;

}

// @HostListener('document:mousedown', ['$event'])
// handlerFunction(e: MouseEvent) {
//     this.clickedOutside = false;
//     if(this.hassavedChanges && !this.router.url.includes('UID')){
//         this.saveOrUpdate();
//         this.hassavedChanges = false;
//     }
// }

@HostListener('window:beforeunload', ['$event'])

  unloadNotification($event: any) {
    if(this.uidInputValue !== this.uniqueId && !this.discardOpen){
    $event.returnValue = true;
    }
  }

//   closeDeleteModal(){
//     this.selectedAction = 'delete-ws';
//   }

}
